import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useKlaus } from './KlausContext';
import { KlausProps } from './types';
import WolfIcon from './WolfIcon';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  width: '400px',
  padding: theme.spacing(3),
  zIndex: 9999,
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(145deg, #ffffff 0%, #f5f8ff 100%)',
  border: '1px solid rgba(25, 118, 210, 0.1)',
}));

const StyledHighlight = styled(Box)(({ theme }) => ({
  position: 'absolute',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  backgroundColor: 'rgba(25, 118, 210, 0.08)',
  zIndex: 9998,
  pointerEvents: 'none',
  boxShadow: '0 0 0 4px rgba(25, 118, 210, 0.1)',
}));

const WolfHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1, 3),
  textTransform: 'none',
  fontWeight: 600,
  '&.MuiButton-contained': {
    background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
    boxShadow: '0 3px 12px rgba(25, 118, 210, 0.3)',
    '&:hover': {
      background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
    },
  },
}));

const Klaus: React.FC<KlausProps> = ({ onClose, onComplete }) => {
  const {
    isOpen,
    currentStep,
    steps,
    closeKlaus,
    nextStep,
    previousStep,
  } = useKlaus();

  useEffect(() => {
    if (!isOpen) return;

    const currentStepData = steps[currentStep];
    if (currentStepData.element) {
      const element = document.querySelector(currentStepData.element);
      if (element) {
        const rect = element.getBoundingClientRect();
        const highlight = document.createElement('div');
        highlight.id = 'klaus-highlight';
        highlight.style.position = 'fixed';
        highlight.style.top = `${rect.top}px`;
        highlight.style.left = `${rect.left}px`;
        highlight.style.width = `${rect.width}px`;
        highlight.style.height = `${rect.height}px`;
        document.body.appendChild(highlight);

        return () => {
          document.body.removeChild(highlight);
        };
      }
    }
  }, [isOpen, currentStep, steps]);

  if (!isOpen) return null;

  const currentStepData = steps[currentStep];
  const progress = ((currentStep + 1) / steps.length) * 100;

  const handleClose = () => {
    closeKlaus();
    onClose?.();
  };

  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      handleClose();
      onComplete?.();
    } else {
      nextStep();
    }
  };

  return (
    <>
      <StyledPaper elevation={3}>
        <WolfHeader>
          <WolfIcon />
          <Typography variant="h6" component="h2" sx={{ flexGrow: 1 }}>
            {currentStepData.title}
          </Typography>
          <IconButton onClick={handleClose} size="small" sx={{ marginLeft: 1 }}>
            <CloseIcon />
          </IconButton>
        </WolfHeader>

        <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
          {currentStepData.description}
        </Typography>

        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            mb: 3,
            height: 6,
            borderRadius: 3,
            backgroundColor: 'rgba(25, 118, 210, 0.1)',
            '& .MuiLinearProgress-bar': {
              background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
            },
          }}
        />

        <Box display="flex" justifyContent="space-between">
          <StyledButton
            onClick={previousStep}
            disabled={currentStep === 0}
            variant="outlined"
          >
            Previous
          </StyledButton>
          <StyledButton
            onClick={handleNext}
            variant="contained"
          >
            {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
          </StyledButton>
        </Box>
      </StyledPaper>

      {currentStepData.element && <StyledHighlight id="klaus-highlight" />}
    </>
  );
};

export default Klaus;
