import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {Paper, Typography, Box} from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import Papa from 'papaparse';
import { useSnackbar } from "notistack";
import { CSVData } from "./types";

interface Props {
    onCsvLoaded: (headers: string[], data: CSVData[]) => void;
}

const CSVUploader = ({onCsvLoaded}: Props) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        Papa.parse(file, {
            header: true,
            complete: (results) => {
              const headers = Object.keys(results.data[0]);
              const parsedData = {
                headers,
                data: results.data as CSVData[],
              };
              onCsvLoaded(headers, parsedData.data);
            },
          });
          
    }, [onCsvLoaded]);

    


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
        accept: {
            'text/csv': ['.csv']
        },
        multiple: false,
    });

    return(
        <Paper
        {...getRootProps()}
      sx={{
        p: 4,
        border: '2px dashed',
        borderColor: isDragActive ? 'primary.main' : 'grey.300',
        bgcolor: isDragActive ? 'action.hover' : 'background.paper',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          borderColor: 'primary.main',
          bgcolor: 'action.hover',
        },
      }}
        >
            <input {...getInputProps()} />
            <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
                <FileUpload sx={{color: isDragActive ? '#1976d2' : '#757575' }} fontSize='large' />
                <Typography variant='h6' color='textPrimary'>
                    {isDragActive ? 'Drop the file here' : 'Drag & drop a CSV file here'}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                    or click to select a file
                </Typography>
            </Box>
        </Paper>
    )
}

export default CSVUploader;