import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import SupplierList from './supplierList';
import ChannelList from './channelList';
import ChannelReports from './ChannelReports';

import { useEffect, useState } from 'react';
import InventoryApi from '@oneAppCore/services/apis/Inventory';
import useRuntimeStatus from '../Management/hooks/useRuntimeStatus';
import useSupplierStatus from '../Management/hooks/useSupplierStatus';

import {
  SupplierHook,
  ChannelHook,
  TrackNumberHook,
  LastInventoryHook,
  SupplierStatusType,
  SupplierCountHook,
  TrackNumberSupplierHook,
} from './types';
import { COMPLETED } from '@oneAppCore/constants/listings';

const useStyles = makeStyles({
  header: {
    marginBottom: 10,
  },
  card: {
    width: '100%',
    padding: 10,
    marginBotton: 30,
  },
  cardAction: {
    justifyContent: 'center',
  },
  textArea: {
    marginTop: 15,
  },
});

function InventoryStatButtons() {
  const classes = useStyles();
  const [supplier, setSupplier] = useState<SupplierHook>([]);
  const [channels, setChannels] = useState<ChannelHook>([]);
  const [channelFeeds, setChannelFeeds] = useState<any>([]);
  const [trackNumbers, setTrackNumbers] = useState<TrackNumberHook>({});
  const [inventoryClicked, setInventoryClicked] = useState(true);
  const [lastInventory, setLastInventory] = useState<LastInventoryHook>({});
  const [
    supplierDownload,
    setSupplierDownload,
  ] = useState<SupplierStatusType>();
  const [supplierCounts, setSupplierCounts] = useState<SupplierCountHook>([]);
  const [channelCounts, setChannelCounts] = useState([]);
  const [channelHistCounts, setChannelHistCounts] = useState([]);
  const [
    supplierTrackCount,
    setSupplierTrackCount,
  ] = useState<TrackNumberSupplierHook>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { data: runtimeData, mutate: runtimeMutation } = useRuntimeStatus();
  const { data: supplierData, mutate: supplierMutation } = useSupplierStatus(
    runtimeData.id,
  );

  useEffect(() => {
    setSupplierDownload(supplierData.data);
  }, [supplierData]);

  useEffect(() => {
    setLastInventory(runtimeData);
    if (runtimeData.status === 'RUNNING') {
      setInventoryClicked(true);
      enqueueSnackbar(`Inventory is currently running, please wait...`, {
        variant: 'warning',
      });
    } else if (runtimeData.status === 'FAILED') {
      setInventoryClicked(false);
      enqueueSnackbar(`Inventory process failed, please try again.`, {
        variant: 'error',
      });
    } else {
      setInventoryClicked(false);
    }
  }, [runtimeData]);

  useEffect(() => {
    const getSuppliers = async () => {
      const supplierList = await InventoryApi.getSuppliers();
      setSupplier(
        supplierList
          .filter((x) => Object.entries(x.metaData).length != 0)
          .filter((x) => x.isThirdParty === true),
      );
      const {
        channels: channelList,
        feedRecords,
      } = await InventoryApi.getAllChannels();
      setChannels(channelList);
      setChannelFeeds(feedRecords);
      const trackCounts = await InventoryApi.getMiniReports();
      setTrackNumbers(trackCounts);
      const supplierCountCall = await InventoryApi.getSuppInvCounts();
      setSupplierCounts(supplierCountCall);
      const { current, hist } = await InventoryApi.getChannelInvCounts();
      setChannelCounts(current);
      setChannelHistCounts(hist);
      // const supplierTrackCounts = await InventoryApi.getSuppInvCounts();
      // setSupplierTrackCount(supplierTrackCounts);
    };
    getSuppliers();
  }, []);

  const runInventory = async () => {
    setInventoryClicked(true);
    if (!inventoryClicked) {
      // const runWorker: any = await output();
      runtimeMutation();
      supplierMutation();
      // if (runWorker?.working) {
      //   enqueueSnackbar(`Inventory is currently running, Please wait...`, {
      //     variant: 'warning',
      //   });
      // } else {
      enqueueSnackbar(`Error Running Inventory...`, { variant: 'error' });
      // }
    }
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'RUNNING':
        return '#FFA500';
      case 'FAILED':
        return '#FF0000';
      case COMPLETED:
        return '#00FF00';
      case 'FTP COMPLETED':
        return '#00FF00';
    }
  };

  // Refreshing state every 5 seconds.

  useEffect(() => {
    runtimeMutation();
    supplierMutation();
    // const interval = setInterval(() => {
    //   runtimeMutation();
    //   supplierMutation();
    // }, 5000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{ pl: 1 }}
    >
      <Grid style={{ marginBottom: 30, width: '100%' }}>
        <ChannelReports trackNumbers={trackNumbers} />
      </Grid>

      <Card className={classes.card}>
        <CardContent>
          <Grid container xs={12}>
            <Grid item style={{ margin: 5, marginLeft: 0 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={runInventory}
                disabled={inventoryClicked}
              >
                Run Inventory
              </Button>
            </Grid>
            <Grid item style={{ margin: 5 }}>
              <Button color="primary" variant="contained" key="download-button">
                Download
              </Button>
            </Grid>
          </Grid>
          {/* <Grid
            className={classes.header}
            alignItems="center"
            justify="space-between"
            container
          >
            <Typography variant="h5" color="primary">
              <Box fontWeight="fontWeightBold">Inventory Statistics</Box>
            </Typography>
          </Grid> */}

          <Grid
            className={classes.header}
            style={{ marginBottom: 30, marginTop: 30 }}
          >
            <Typography variant="h6">
              <div
                style={{
                  color: getStatusColor(lastInventory.status),
                  fontWeight: 'bold',
                }}
              >
                {lastInventory?.status ? (
                  lastInventory.status
                ) : (
                  <CircularProgress />
                )}
              </div>
            </Typography>
            <Typography>
              {lastInventory?.createdAt
                ? `Last Started: ${new Date(
                    lastInventory?.createdAt,
                  ).toDateString()} at ${new Date(
                    lastInventory?.createdAt,
                  ).toLocaleTimeString()}`
                : null}
            </Typography>
            <Typography>
              {lastInventory?.updatedAt
                ? `Last Finished: ${new Date(
                    lastInventory?.updatedAt,
                  ).toDateString()} at ${new Date(
                    lastInventory?.updatedAt,
                  ).toLocaleTimeString()}`
                : null}
            </Typography>
          </Grid>

          <Grid className={classes.header}>
            <Typography variant="h6" style={{ marginBottom: 5 }}>
              Suppliers
            </Typography>
          </Grid>
          <Grid container spacing={1}>
            {supplier.map((sup) => {
              return (
                <SupplierList
                  supplier={sup}
                  key={sup.id}
                  supplierStatus={supplierDownload}
                  supplierCounts={supplierCounts}
                  // supplierTrackCounts={supplierTrackCount}
                />
              );
            })}
          </Grid>

          <Grid className={classes.header} style={{ marginTop: 30 }}>
            <Typography variant="h6" style={{ marginBottom: 5 }}>
              Channels
            </Typography>
          </Grid>
          <Grid container spacing={1}>
            {channels
              .filter((ch) => ch)
              .map((channel) => {
                return (
                  <ChannelList
                    channel={channel}
                    channelCounts={channelCounts}
                    channelFeed={channelFeeds[channel.scid - 1 || 0]}
                    channelHistCounts={channelHistCounts}
                    key={channel.id + channel.storeName}
                  />
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default InventoryStatButtons;
