import { useMemo, useEffect, useState } from 'react';
import SearchGrid from '@src/Components/common/containers/SearchGrid';
import StoreListingApi from '@oneAppCore/services/apis/StoreListings/StoreListingApi';
import type { Filters, FilterType, FilterProperties } from '@src/Components/common/containers/SearchGrid/types';
import { columns } from './constants';
import { ACTIVE, DRAFT, INACTIVE, PENDING, REJECTED } from '@oneAppCore/constants/listings';
import { REPRICE } from '@oneAppCore/constants/laws';
import useMeStores from '@src/hooks/swr/useMeStores';
import useUsers from '@src/hooks/swr/useUsers';

const url = '/api/v1/store-listings/list';

function BulkEdit() {
  const storeListingApi = new StoreListingApi();
  const { data: storesData } = useMeStores();
  const { data: users = [] } = useUsers();
  const [stores, setStores] = useState<FilterProperties[]>([]);
  const [userOptions, setUserOptions] = useState<FilterProperties[]>([]);

  useEffect(() => {
    if (storesData) {
      setStores(
        storesData.map((store) => ({
          label: store.name,
          value: store.id,
        }))
      );
    }
  }, [storesData]);

  useEffect(() => {
    if (users.length) {
      const sortedUsers = [...users].sort((a, b) => {
        const nameA = a.firstName.toUpperCase();
        const nameB = b.firstName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      setUserOptions(
        sortedUsers.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
        }))
      );
    }
  }, [users]);

  const initialFilters: Filters[] = [
    {
      type: 'string' as FilterType,
      name: 'Listing SKU',
      keyName: 'listingSku',
    },
    {
      type: 'string' as FilterType,
      name: 'Channel Product ID',
      keyName: 'channelProductId',
    },
    {
      type: 'selector' as FilterType,
      name: 'Status',
      keyName: 'status',
      properties: [
        { label: 'ACTIVE', value: ACTIVE },
        { label: 'INACTIVE', value: INACTIVE },
      ] as FilterProperties[],
      options: {
        hideDefaultNoneOption: true,
        placeholder: 'Select...'
      },
    },
    {
      type: 'selector' as FilterType,
      name: 'Store',
      keyName: 'store',
      properties: stores,
      options: {
        hideDefaultNoneOption: true,
        placeholder: 'Select...'
      },
    },
    {
      type: 'string' as FilterType,
      name: 'Brand',
      keyName: 'brand',
    },
    {
      type: 'equality' as FilterType,
      name: 'Sales Count',
      keyName: 'salesCount',
    },
    {
      type: 'equality' as FilterType,
      name: 'Profit %',
      keyName: 'profitPercentage',
    },
    {
      type: 'selector' as FilterType,
      name: 'Created By',
      keyName: 'createdBy',
      properties: userOptions,
      options: {
        hideDefaultNoneOption: true,
        placeholder: 'Select...'
      },
    },
    {
      type: 'date range' as FilterType,
      name: 'Created At',
      keyName: 'createdAt',
    },
  ];

  return (
    <SearchGrid
      url={url}
      columns={columns()}
      filters={initialFilters}
      editableColumns={['price', 'minPrice', 'maxPrice']}
      supportsDownload
      select={false}
      showChannelOptions
      api={{
        bulkUpdate: async ({ updateObj }) => {
          try {
            const changes = Object.entries(updateObj).map(([id, values]) => ({
              storeListingId: Number(id),
              type: REPRICE,
              newValues: values
            }));

            await storeListingApi.bulkChangesCreate({ changes });
            return { success: true };
          } catch (error) {
            console.error('Error creating bulk changes:', error);
            return { success: false };
          }
        },
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            createdByName: false,
            msrp: false,
            createdAt: false,
            salesCount: false,
            storeName: false
          }
        }
      }}
      swrOptions={{
        revalidateOnFocus: true,
        focusThrottleInterval: 3500,
      }}
    />
  );
}

export default BulkEdit;