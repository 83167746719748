import { useMemo, useEffect, useState, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import {
  Grid,
  CardHeader,
  CardContent,
  Modal,
  Typography,
  Button,
  Divider,
  Paper,
  FormControl,
  Box,
  CardMedia,
  // Select,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField, Select } from '@src/Components/common';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import BrandSelect from '@src/Components/common/Selects/BrandSelect';
import ProductApi from '@oneAppCore/services/apis/Products';
import { KeywordOptionType } from '@oneAppCore/types/productTypes';
import { primary } from '@src/Components/Theme/themes/constants';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { useVarData } from '@src/hooks/swr/Inventory/useVariationData';
import CreationDrawer from '@src/Components/common/containers/SearchView/CreationDrawer';
import ChannelCategorySelect from '@src/Components/common/Selects/ChannelCategorySelect';
import useMeStores from '@src/hooks/swr/useMeStores';
import type {
  VariationsForm,
  ManufacturerSupplierForm,
  FitmentForm,
  Makes,
  Models,
} from '../../../NewProductForm/types';
import { Params } from '../../types';
import useProduct from '../../hooks/useProduct';
import { columns } from './constants';
import Avatar from '../ProductImages/Avatar';
import type { ParentTextFieldsProps } from './types';
import type { ProductForm } from '../types';
import NewCategoryDrawer from './NewCategoryDrawer';
import BrandDrawer from '../../../Brands/BrandDrawer';

const filter = createFilterOptions<string | KeywordOptionType>();

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const PrevArrow = ({ className, style, onClick }: ArrowProps) => (
  <IconButton
    className={className}
    style={{
      ...style,
      display: 'block',
      position: 'absolute',
      left: '-50px',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
    onClick={onClick}
  >
    <ArrowCircleLeftIcon fontSize="medium" />
  </IconButton>
);

const NextArrow = ({ className, style, onClick }: ArrowProps) => (
  <IconButton
    className={className}
    style={{
      ...style,
      display: 'block',
      position: 'absolute',
      right: '-50px',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
    onClick={onClick}
  >
    <ArrowCircleRightIcon fontSize="medium" />
  </IconButton>
);

function ParentTextFields({
  form,
  updateForm,
  isViewMode,
  mutate,
  keywordList,
  nameError,
  storeError,
  brandError,
}: ParentTextFieldsProps) {
  const classes = useStyles();
  const { data: product } = useProduct();
  const { data: stores = [] } = useMeStores();
  const { id: paramId } = useParams<Params>();
  const { data: variations, mutate: mutateVariations } = useVarData({
    id: Number(paramId),
  });

  const initialImage = form?.image?.[0];
  const [store, setStore] = useState<{ label: string; value: number | string }>(
    {
      label: '',
      value: '',
    },
  );
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [categoryForm, setCategoryForm] = useState<ProductForm>({});
  const [value, setValue] = useState<KeywordOptionType | null>(null);
  const [open, setOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [brand, setBrand] = useState<string>(null);
  const [newForm, setNewForm] = useState<VariationsForm[]>([]);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[]>([]);
  const [savedImages, setSavedImages] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [selected, setSelected] = useState(initialImage);
  const [dialogValue, setDialogValue] = useState({
    label: '',
  });
  const [mutateBrands, setMutateBrands] = useState<boolean>(false);
  const [mutateCat, setMutateCat] = useState<boolean>(false);
  const [variationImages, setVariationImages] = useState([]);
  const [keywords, updateKeywords] = useState<string[]>([]);

  useEffect(() => {
    const initialImage = form?.image?.[0] || 'defaultImageURL'; // Fallback URL
    setSelected(initialImage);
  }, [form]);

  useEffect(() => {
    if (form.keywords) {
      const newKeywords = form.keywords.split(',');
      updateKeywords(newKeywords);
    }
  }, [isViewMode]);

  const productImages = form?.productImages || [];

  useEffect(() => {
    if (productImages.length > 0) {
      const newInitialImage = productImages[0].imageUrl[0];
      setSelected(newInitialImage);
    }
  }, [form]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('productImages');
    };
  }, []);

  const storeOptions = useMemo(
    () =>
      stores.map((store) => ({
        label: store.name,
        value: store.id,
      })),
    [stores],
  );

  const options: KeywordOptionType[] = [{ label: '' }];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const close = () => {
    setOpen(false);
  };

  const brandClose = () => {
    setBrandOpen(false);
  };

  const updateBrand = (selectedBrand: string) => {
    setBrand(selectedBrand);
    const updatedVariations = (newForm?.slice() || []).map((variation) => ({
      ...variation,
      brand: selectedBrand,
    }));
    const updatedSuppliers = suppliers.map((supplier) => ({
      ...supplier,
      brand: selectedBrand,
    }));
    setSuppliers(updatedSuppliers);
    setNewForm(updatedVariations);
  };

  const updateBrandList = async (brandId) => {
    await updateForm('brandId', brandId);
    await setMutateBrands(!mutateBrands);
  };
  const updateCategories = async (channelCategoryId) => {
    await updateForm('channelCategoryId', channelCategoryId);
    await setMutateCat(!mutateCat);
  };
  useEffect(() => {
    setMutateBrands(!mutateBrands);
  }, [form?.brandId]);
  useEffect(() => {
    setMutateCat(!mutateCat);
  }, [form?.channelCategoryId]);

  const handleMoreImageUpload = async (url: string[]) => {
    await ProductApi.createImage({ imageUrl: url[0] }, form.id)
      .then(() => {
        mutate();
        setSlideIndex(0);
        enqueueSnackbar('Added Image!', { variant: 'success' });
      })
      .catch((e) => enqueueSnackbar(e, { variant: 'error' }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const imagesFromLocalStorage = JSON.parse(
      localStorage.getItem('productImages') || '[]',
    );

    if (imagesFromLocalStorage.length > 0) {
      try {
        await ProductApi.createImage(
          { imageUrl: imagesFromLocalStorage },
          form.id,
        );

        localStorage.removeItem('productImages');

        enqueueSnackbar('Images saved successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error uploading images:', error);
        enqueueSnackbar('Error uploading images', { variant: 'error' });
      }
      localStorage.removeItem('productImages');
    }

    setValue({
      label: dialogValue.label,
    });
  };

  const onChange = <K extends keyof ProductForm>(
    key: K,
    value: ProductForm[K],
  ) => {
    setCategoryForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  useEffect(() => {
    setStore(
      storeOptions.filter((option) => option.value === form?.storeId)[0],
    );
  }, [form]);

  const handleImageClick = () => {
    setModal(true);
    const clickedImage = form?.productImages?.[slideIndex];
    if (clickedImage) {
      const clickedImageId = clickedImage.id;
      setUpdateId(clickedImageId);
    }
  };

console.log('form?.image:', form?.image);
console.log('form?.imageId:', form?.imageId);
const handleDeletePhoto = async (slideIndex) => {
  if (!Array.isArray(form?.image) || form?.image.length === 0) {
    console.error('form?.image is not a valid array or is empty');
    return;
  }

  const url = form?.image[slideIndex];
  const id = form?.imageId;

  if (slideIndex < 0 || slideIndex >= form?.image.length) {
    console.error('slideIndex is out of bounds');
    return;
  }

  const payload = {
    images: [url],
    deletedAt: new Date(),
  };

  ProductApi.updateImage(payload, id)
    .then(() => {
      mutate(); 
      setModal(false);
      setSlideIndex(0);
      enqueueSnackbar('Updated images', { variant: 'success' });
    })
    .catch((e) => {
      setModal(false);
      enqueueSnackbar(e.message || 'Error updating images', { variant: 'error' });
    });
};

  const updateUrl = async (url: (string | object)[]) => {
    const imageUrl = typeof url[0] === 'string' ? url[0] : null;

    const imageUrls = form.productImages.map((obj) => {
      if (Array.isArray(obj.imageUrl)) {
        return obj.imageUrl[0];
      }
      return null;
    });
    const newUrlArray = imageUrls.map((u, i) =>
      i === slideIndex ? imageUrl || u : u,
    );
    await ProductApi.updateImage({ images: [imageUrl] }, updateId)
      .then(() => {
        mutate();
        setSlideIndex(0);
        setModal(false);
        enqueueSnackbar('Updated images', { variant: 'success' });
      })
      .catch((e) => {
        setModal(false);
        const errorMessage = e.message || 'An error occurred';
      });
  };

  const handleKeywords = (newKeywords: any) => {
    const isSame = JSON.stringify(keywords) === JSON.stringify(newKeywords);
    if (!isSame) {
      updateKeywords(newKeywords);
      updateForm('keywords', newKeywords.join(','));
      // mutate();
    }
  };

  const saveImagesToLocalStorage = (images) => {
    const existingImages = localStorage.getItem('productImages') || '[]';
    const updatedImages = JSON.stringify([
      ...JSON.parse(existingImages),
      ...images,
    ]);
    localStorage.setItem('productImages', updatedImages);
    setSavedImages(JSON.parse(updatedImages));
  };

  useEffect(() => {
    const existingImages = localStorage.getItem('productImages');
    if (existingImages) {
      setSavedImages(JSON.parse(existingImages));
    }
  }, []);

  const unique = Array.from(new Set(keywordList));

  useEffect(() => {
    if (!form?.productImages && variations && variations?.length > 0) {
      const variationImageIndex = variations?.findIndex(
        (element) => element.imageUrl,
      );
      if (variationImageIndex > -1) {
        setVariationImages([variations?.[variationImageIndex]?.imageUrl]);
      }
    }
  }, [variations, form]);

  const allImages = [...(form?.image || []), ...savedImages];


  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const lgNo = downLG ? 4 : 3;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: allImages.length > 3 ? lgNo : allImages.length,
    slidesToScroll: 1,
    centerMode: false,
    rows: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container xs={12} style={{ justifyContent: 'space-between' }}>
          <Grid
            item
            container
            className={classes.imgContainer}
            alignItems="center"
            justifyContent="center"
            sm={12}
            md={6}
            lg={5}
          >
            <Grid xs={12} item container sx={{ justifyContent: 'center' }}>
              <Grid className={classes.imageContainer} item xs={12}>
                {form?.image && form?.image?.length > 0 ? (
                  <CardMedia
                    component="img"
                    sx={{
                      borderRadius: 10,
                      overflow: 'hidden',
                      cursor: 'zoom-in',
                      width: 400,
                      height: 400,
                      '& .css-14hp3kz-MuiCardMedia-root': {
                        objectFit: 'initial',
                      },
                    }}
                    alt="Product Images"
                    image={
                      selected === undefined
                        ? `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                        : selected
                    }
                    onClick={handleImageClick}
                  />
                ) : variationImages.length > 0 ? (
                  <CardMedia
                    component="img"
                    onClick={handleImageClick}
                    sx={{
                      display: 'flex',
                      borderRadius: 10,
                      overflow: 'hidden',
                      cursor: 'zoom-in',
                      width: 400,
                      height: 400,
                      '& .css-14hp3kz-MuiCardMedia-root': {
                        objectFit: 'initial',
                      },
                    }}
                    alt="Product Images"
                    image={
                      selected === undefined
                        ? `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                        : selected
                    }
                  />
                ) : savedImages.length > 0 ? (
                  <CardMedia
                    component="img"
                    onClick={handleImageClick}
                    sx={{
                      borderRadius: 10,
                      overflow: 'hidden',
                      cursor: 'zoom-in',
                      width: 400,
                      height: 400,
                      '& .css-14hp3kz-MuiCardMedia-root': {
                        objectFit: 'initial',
                      },
                    }}
                    alt="Product Images"
                    image={
                      savedImages?.[0]
                        ? savedImages?.[0]
                        : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                    }
                  />
                ) : (
                  <CardMedia
                    component="img"
                    onClick={handleImageClick}
                    sx={{
                      borderRadius: 10,
                      overflow: 'hidden',
                      cursor: 'zoom-in',
                      width: 400,
                      height: 400,
                      '& .css-14hp3kz-MuiCardMedia-root': {
                        objectFit: 'initial',
                      },
                    }}
                    alt="Product Images"
                    image={
                      savedImages?.[0]
                        ? form?.image?.[0]
                        : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                    }
                  />
                )}
              </Grid>
              {!paramId && savedImages.length < 1 ? (
                <></>
              ) : allImages.length < 1 ? (
                <></>
              ) : (
                <Grid item xs={11} sm={7} md={9} lg={10} xl={8}>
                  <Box sx={{ position: 'relative' }}>
                    {!paramId ? (
                    <Slider {...settings}>
                      {savedImages.map((item, index) => (
                        <Box
                          key={index}
                          sx={{ p: 1 }}
                          onClick={() => setSelected(item)}
                        >
                          <Avatar
                            outline={selected === item}
                            size={downLG ? 'lg' : 'md'}
                            src={
                              item === undefined
                                ? `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                                : item
                            }
                            variant="rounded"
                            sx={{
                              m: '0 auto',
                              cursor: 'pointer',
                              '& .css-14hp3kz-MuiCardMedia-root': {
                                objectFit: 'initial',
                              },
                            }}
                            alt="product images"
                          />
                        </Box>
                      ))}
                    </Slider>
                    ) : (
                      <Slider {...settings}>
                      {allImages?.map((item, index) => (
                        <Box
                          key={index}
                          sx={{ p: 1 }}
                          onClick={() => setSelected(item)}
                        >
                          <Avatar
                            outline={selected === item}
                            size={downLG ? 'lg' : 'md'}
                            src={
                              item === undefined
                                ? `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                                : item
                            }
                            variant="rounded"
                            sx={{
                              m: '0 auto',
                              cursor: 'pointer',
                              '& .css-14hp3kz-MuiCardMedia-root': {
                                objectFit: 'initial',
                              },
                            }}
                            alt="product images"
                          />
                        </Box>
                      ))}
                    </Slider>
                    )}
                  </Box>
                </Grid>
              )}
              {isViewMode ? null : (
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  style={{ marginBottom: 20 }}
                >
                  <Grid item>
                    <FileUpload
                      id="addNewImage"
                      onUpload={(url) => {
                        if (!paramId) {
                          updateForm('image', url);
                          saveImagesToLocalStorage(url);
                        } else {
                          handleMoreImageUpload(url);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: 500,
                backgroundColor: 'white',
                padding: 20,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                {form.image && (
                  <img
                    src={form.image[slideIndex]}
                    alt=""
                    width={400}
                    height={400}
                    // onClick={showModal}
                  />
                )}
              </div>
              <Grid
                item
                container
                xs={12}
                direction="row"
                style={{ justifyContent: 'space-between', marginTop: 20 }}
              >
                <Grid item xs={12} style={{ marginBottom: 15 }}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  {isViewMode ? null : (
                    <FileUpload
                      id="productUpload"
                      onUpload={(url: string[]) => updateUrl(url)}
                      buttonText="Upload New Photo"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  style={{ justifyContent: 'flex-end' }}
                >
                  <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => handleDeletePhoto(slideIndex)}
                  >
                    Delete Image
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {isViewMode ? (
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={6}
              lg={5}
              spacing={2}
              style={{
                marginTop: 1,
              }}
            >
              {columns.map((column) => {
                if (column.keyName === 'storeId') {
                  return (
                    <Grid
                      item
                      key={column.keyName}
                      className={classes.productInfoColor}
                    >
                      <Grid item container xs={12}>
                        <Grid xs={5}>
                          <Typography style={{ fontSize: '20px' }}>
                            {column.title}:
                          </Typography>
                        </Grid>
                        <Grid xs={7}>
                          <Typography style={{ fontSize: '20px' }}>
                            {store?.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid
                    item
                    key={column.keyName}
                    className={classes.productInfoColor}
                    // style={{ maxHeight: '65px' }}
                  >
                    <Grid item container xs={12}>
                      {column.customComponent ? (
                        <column.customComponent
                          keyValue={form[column.keyName]}
                          form={form}
                          variations={variations}
                        />
                      ) : (
                        <> </>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {/* </Paper> */}
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              sm={12}
              md={6}
              lg={6}
              spacing={2}
            >
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  fullWidth
                  error={nameError}
                  label="Product Name"
                  name="name"
                  value={form?.name || ''}
                  onChange={(e) => updateForm('name', e.target.value)}
                  required
                  style={{
                    backgroundColor: primary.transparent.main,
                  }}
                />
              </Grid>
              <Grid item container xs={12} style={{ alignItems: 'center' }}>
                <Grid item xs={10} style={{ marginBottom: 10 }}>
                  <BrandSelect
                    brandError={brandError}
                    value={form?.brandId || ''}
                    onChange={(option) => updateForm('brandId', option.value)}
                    required
                    mutateVar={mutateBrands}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  style={{ justifyContent: 'flex-end', maxHeight: 30 }}
                >
                  <CreationDrawer
                    Form={BrandDrawer}
                    customSubmit={async (props) => {
                      await updateBrandList(props.changeBrand[0].id);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 5 }}>
                <Select
                  label="Store"
                  storeError={storeError}
                  containerClass="store-id-select"
                  value={store === undefined ? storeOptions?.[0] : store}
                  onChange={(option) => updateForm('storeId', option.value)}
                  required
                  options={storeOptions}
                  style={{ backgroundColor: primary.transparent.main }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 5 }}>
                <ChannelCategorySelect
                  onChange={(id) => updateForm('channelCategoryId', id)}
                  value={(form?.channelCategoryId as number) ?? null}
                  backgroundColor={primary.transparent.main}
                  mutateVar={mutateCat}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ marginBottom: 5, justifyContent: 'flex-end' }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: 10, marginBottom: 5 }}
                  onClick={() => setOpen(true)}
                >
                  Add New Category
                </Button>
                <NewCategoryDrawer
                  isDrawerOpen={open}
                  setDrawerOpen={close}
                  customSubmit={async (props) => {
                    await updateCategories(props.id);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  freeSolo
                  value={keywords}
                  filterOptions={(options, params) => {
                    const filtered = filter(
                      options,
                      params,
                    ) as KeywordOptionType[];

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  id="tags-standard"
                  options={unique}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  onChange={(event, value) => {
                    handleKeywords(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Keywords"
                      placeholder="Keywords"
                      style={{
                        backgroundColor: primary.transparent.main,
                        minWidth: '450px',
                      }}
                      fullWidth
                      // onKeyDown={(e) => {
                      //   handleKeyPress(e);
                      // }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
}

export default ParentTextFields;

const useStyles = makeStyles({
  underlineBorder: {
    paddingBottom: 30,
    paddingTop: 50,
    borderBottom: '1px solid lightgrey',
  },
  imgContainer: {
    padding: 15,
    paddingBottom: 0,
    marginRight: 20,
  },
  largeImage: {
    height: '100%',
  },
  imgInput: {
    display: 'none',
  },
  addImageContainer: {
    fontSize: 30,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px dashed grey',
    padding: '20px',
    borderRadius: '4px',
    color: 'grey',
    '&:hover': {
      color: 'darkgrey',
      cursor: 'pointer',
      border: '1px dashed darkgrey',
    },
  },

  addImageIcon: {
    color: '#3A9ADD',
    '&:hover': {
      color: '#2C7BB1',
    },
  },

  image: {
    height: 100,
    padding: '0px',
    margin: 12,
  },
  imageContainer: {
    fontSize: 70,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  productInfoColor: {
    fontWeight: 200,
    color: 'grey',
  },
});
