export const formFields: any[] = [
  {
    label: 'Type',
    key: 'type',
    type: 'string',
  },
  {
    label: 'URL',
    key: 'url',
    type: 'string',
  },
  {
    label: 'Client ID',
    key: 'client_id',
    type: 'string',
  },
  {
    label: 'Client Secret',
    key: 'client_secret',
    type: 'string',
  },
  {
    label: 'Email',
    key: 'email',
    type: 'string',
  },
  {
    label: 'Token',
    key: 'token',
    type: 'string',
  },
];
