import React, { createContext, useContext, useState, useEffect } from 'react';

interface SplashScreenContextType {
  showSplashScreen: boolean;
  setShowSplashScreen: (show: boolean) => void;
  onboardingComplete: boolean;
  setOnboardingComplete: (complete: boolean) => void;
}

const SplashScreenContext = createContext<SplashScreenContextType | undefined>(undefined);

export const SplashScreenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [onboardingComplete, setOnboardingComplete] = useState(() => {
    return localStorage.getItem('onboardingComplete') === 'true';
  });

  // Initialize splash screen visibility based on onboarding status
  useEffect(() => {
    const isComplete = localStorage.getItem('onboardingComplete') === 'true';
    setShowSplashScreen(!isComplete);
    setOnboardingComplete(isComplete);
  }, []);

  useEffect(() => {
    localStorage.setItem('onboardingComplete', onboardingComplete.toString());
  }, [onboardingComplete]);

  return (
    <SplashScreenContext.Provider
      value={{
        showSplashScreen,
        setShowSplashScreen,
        onboardingComplete,
        setOnboardingComplete,
      }}
    >
      {children}
    </SplashScreenContext.Provider>
  );
};

export const useSplashScreen = () => {
  const context = useContext(SplashScreenContext);
  if (context === undefined) {
    throw new Error('useSplashScreen must be used within a SplashScreenProvider');
  }
  return context;
};
