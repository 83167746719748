import BaseApi from '../BaseApi';
import { TransactionPayload, Transaction } from './types';

interface LabelInfo {
  labelcount: number;
  labelcost: number;
  labelcharges: number;
}


class ClientBillingAPI extends BaseApi {
  constructor() {
    super();
  }

  post(payload: TransactionPayload) {
    return this.postBase<Transaction>('/api/v1/transactions', payload);
  }

  async getRows() {
    const response = await this.getBase<{ transactions: Transaction[], availableBalance: number, labelInfo: LabelInfo }>('/api/v1/transactions');
    return response;
  }

  createPaymentIntent(data: { amount: number }) {
    return this.postBase<{ clientSecret: string }>('/api/v1/billing/payment-intent', data);
  }
}

export default ClientBillingAPI;
