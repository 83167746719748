import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  useTheme,
  CircularProgress,
  Chip,
  IconButton
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useSWR from 'swr';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';

interface Props {
  storeId: string;
}

const fetcher = async (url: string) => {
  try {
    const response = await Api.get(url);
    return response;
  } catch (error) {
    console.error('Failed to fetch price monitoring data:', error);
    throw error;
  }
};

const PriceMonitoringTable: React.FC<Props> = ({ storeId }) => {
  const theme = useTheme();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data: response, error } = useSWR<{
    data: any[];
    pagination: {
      page: number;
      limit: number;
      total: number
    }
  }>(
    `/api/v1/price-monitoring/${storeId}?page=${page}&limit=${rowsPerPage}`,
    fetcher,
    {
      refreshInterval: 30000, // Refresh every 30 seconds
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 5000,
    }
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Error loading data: {error.message || 'Something went wrong'}
        </Typography>
      </Box>
    );
  }

  if (!response) {
    return (
      <Box sx={{ p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  const products = response.data || [];
  const total = response.pagination?.total || 0;

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>ASIN</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell align="right">Our Price</TableCell>
              <TableCell align="right">Buybox Price</TableCell>
              <TableCell align="right">Lowest Price</TableCell>
              <TableCell>Buybox Status</TableCell>
              <TableCell>Competitor</TableCell>
              <TableCell align="right">Total Offers</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow hover key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.asin}</TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell align="right">${(product.ourPrice / 100).toFixed(2)}</TableCell>
                <TableCell align="right">${(product.buyboxPrice / 100).toFixed(2)}</TableCell>
                <TableCell align="right">${(product.lowestPrice / 100).toFixed(2)}</TableCell>
                <TableCell>
                  <Chip 
                    label={product.isBuyboxWinner ? "Won" : "Lost"}
                    color={product.isBuyboxWinner ? "success" : "error"}
                    size="small"
                  />
                </TableCell>
                <TableCell>{product.competitorName || 'N/A'}</TableCell>
                <TableCell align="right">{product.totalOffers}</TableCell>
                <TableCell>{new Date(product.lastUpdated).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      // Handle view history
                    }}
                  >
                    <TimelineIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      // Handle view competitors
                    }}
                  >
                    <GroupIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      // Handle configure alerts
                    }}
                  >
                    <NotificationsIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PriceMonitoringTable;