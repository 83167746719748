import { options } from './constants';

import { Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import recoilSearch from '../hooks/useSearchOptions';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundImage: 'linear-gradient(147deg, #4f46b0 0%, #7970dc 90%)',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundImage: 'linear-gradient(147deg, #4ea3de 0%, #1a8cdb 74%)',
      color: theme.palette.common.white,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 10,
      marginTop: 10,
      minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {},
    minWidth: 'max-content',
    overflow: 'wrap',
    paddingLeft: '16px',
  },
  toggleGroup: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
}));

function ChannelOptions() {
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const classes = useStyles();

  const channelChange = (channelId?: number) => {
    setSearchOptions((previous) => {
      const newOptions = { ...previous };
      if (channelId) {
        newOptions.channelId = channelId;
      } else {
        delete newOptions.channelId;  // Remove channelId entirely instead of setting to null
      }
      return newOptions;
    });
  };

  return (
    <Grid item container alignItems="center" className={classes.responsiveUi}>
      <ToggleButtonGroup 
        size="small" 
        className={classes.toggleGroup}
        value={searchOptions.channelId || null}
        exclusive
      >
        {options.map((option) => (
          <ToggleButton
            key={option.name}
            style={{
              fontSize: '10px',
              fontWeight: 600,
            }}
            value={option.value}
            selected={(!searchOptions.channelId && option.value === null) || searchOptions.channelId === option.value}
            className={classes.nonSelectedButton}
            classes={{ selected: classes.selectedButton }}
            onClick={() => channelChange(option.value)}
          >
            {option.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
}

export default ChannelOptions;
