import { useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Typography,
    SelectChangeEvent,
    Menu
} from "@mui/material";
import { Options } from "../types";

interface ColumnMapping {
    csvColumn: string;
    dbColumn: string;
  }

interface Props {
    csvColumns: string[];
    mappings: any[];
    onMappingChange: (csvColumn: string, dbColumn: string | null) => void;
    options?: Options;
    selectedTable?: string | null;
    tableCols?: string[];
    fileColumns: string[];
}

const ColumnMapper = ({csvColumns, mappings, fileColumns, onMappingChange, options, selectedTable, tableCols}: Props) => {
      const [mapperColumns, setMapperColumns] = useState([]);
      const [parsedData, setParsedData] = useState([]);


    const handleSelectChange = (value: string, index: number) => {
        const updatedMappings = mappings.map((item, i) =>
            i === index ? { ...item, mappedColumn: value } : item
        )
    }

    const tableSelectMapper = (value, index) => {
        let data = mappings;
        data[index].mappings = value;
        setMapperColumns([...data]);
    }
    
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                Database Column
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                CSV Column
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mappings.map((item, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Typography variant='body1'>
                                    {item.databaseColumn}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                
                                <Select
                                sx={{width: '100%'}}
                                    value={item.mappedColumn || item.mappings}
                                    onChange={(e: any) => tableSelectMapper(e.target.value, i)}
                                >
                                    {fileColumns.map((column, j) => (
                                        <MenuItem key={j} value={column}>
                                            {column}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={'skip'}>Skip</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ColumnMapper;