import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useKlaus } from './KlausContext';
import WolfIcon from './WolfIcon';
import { styled } from '@mui/material/styles';

const WolfButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: 'rgb(227, 242, 253)',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    '& .MuiSvgIcon-root': {
      color: '#fff',
      transform: 'scale(1.1)',
    }
  },
  '& .MuiSvgIcon-root': {
    width: 28,
    height: 28,
    color: theme.palette.primary.main,
    transition: 'all 0.3s ease-in-out',
  }
}));

const KlausButton: React.FC = () => {
  const { openKlaus } = useKlaus();

  return (
    <Tooltip title="Let Klaus guide you through OneApp" placement="bottom">
      <WolfButton
        onClick={openKlaus}
        aria-label="Start OneApp Tour"
      >
        <WolfIcon />
      </WolfButton>
    </Tooltip>
  );
};

export default KlausButton;
