import { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import LoginApi from '@oneAppCore/services/apis/Login';
import { AuroraBackground } from '@src/Components/common/AuroraBackground';
import { useSplashScreen } from '@src/context/SplashScreenContext';
import useMe from '@src/hooks/swr/useMe';
import { setJwtToken } from '@oneAppCore/services/Jwt';
import { JWT_TOKEN } from '@oneAppCore/constants/jwt';

import {
  Grid,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Paper,
  Box,
  Container,
  IconButton,
  Backdrop,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface IFormInput {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    overflow: 'auto',
    userSelect: 'none',
  },
  paper: {
    padding: theme.spacing(5),
    width: '100%',
    maxWidth: 480,
    borderRadius: theme.spacing(3),
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    boxShadow: '0 8px 32px rgba(124, 77, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.8)',
    position: 'relative',
    zIndex: 1,
    margin: '0 auto',  
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: 'auto',
    height: 120,
    objectFit: 'contain',
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    userSelect: 'none',
  },
  form: {
    width: '100%',
  },
  submitButton: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1.1rem',
    background: 'linear-gradient(135deg, #7C4DFF 0%, #448AFF 100%)',
    boxShadow: '0 4px 15px rgba(124, 77, 255, 0.2)',
    '&:hover': {
      background: 'linear-gradient(135deg, #673AB7 0%, #2979FF 100%)',
      boxShadow: '0 6px 20px rgba(124, 77, 255, 0.3)',
    },
  },
}));

function Register() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { setShowSplashScreen, setOnboardingComplete } = useSplashScreen();
  const { mutate } = useMe();
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState<IFormInput>({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<Partial<Record<keyof IFormInput, string>>>({});

  const validateForm = () => {
    const newErrors: Partial<Record<keyof IFormInput, string>> = {};
    
    if (!form.companyName) newErrors.companyName = 'Company name is required';
    if (!form.firstName) newErrors.firstName = 'First name is required';
    if (!form.lastName) newErrors.lastName = 'Last name is required';
    if (!form.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(form.email)) newErrors.email = 'Must be a valid email';
    
    if (!form.password) newErrors.password = 'Password is required';
    else if (form.password.length < 8) newErrors.password = 'Password must be at least 8 characters';
    
    if (!form.confirmPassword) newErrors.confirmPassword = 'Please confirm your password';
    else if (form.password !== form.confirmPassword) newErrors.confirmPassword = 'Passwords must match';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const update = (field: keyof IFormInput, value: string) => {
    setForm(prev => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors(prev => ({ ...prev, [field]: undefined }));
    }
  };

  const toggleShowPassword = () => setShowPassword(prev => !prev);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setSaving(true);
    try {
      // Create the user account
      const registeredUser = await Api.post('/api/v1/public/users', {
        email: form.email,
        password: form.password,
        firstName: form.firstName,
        lastName: form.lastName,
        companyName: form.companyName,
        username: form.email
      });

      // Send welcome email
      try {
        await Api.post('/api/v1/mailer/send-welcome-email', {
          email: form.email,
          firstName: form.firstName,
          companyName: form.companyName
        });
      } catch (emailError) {
        console.error('Failed to send welcome email:', emailError);
      }

      // Log the user in using LoginApi
      const loginResponse = await LoginApi.logIn({
        username: form.email,
        password: form.password
      });

      // The JWT token is set in the response headers and handled by BaseApi
      // We don't need to manually set it here

      // Update the user data in the app
      await mutate();

      enqueueSnackbar(`Welcome ${loginResponse.firstName}! Let's set up your workspace.`, { variant: 'success' });
      
      // Make sure onboarding is not marked as complete
      localStorage.removeItem('onboardingComplete');
      
      // Reset session storage to ensure splash screen shows
      sessionStorage.removeItem('hasSeenForm');
      
      // Show splash screen and reset onboarding state
      setOnboardingComplete(false);
      setShowSplashScreen(true);
      
      // Stay on the current page - SplashScreenWrapper will handle showing the onboarding
      
    } catch (error: any) {
      console.error('Registration error:', error);
      enqueueSnackbar(error?.response?.data?.message || 'Failed to create account', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <AuroraBackground>
      <Box className={classes.root}>
        <Container maxWidth="sm" style={{ margin: '0 auto', padding: 0 }}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.header}>
              <Box className={classes.logoContainer}>
                <img 
                  src="https://oneappprodcentral.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG"
                  alt="OneApp Logo"
                  className={classes.logo}
                  draggable={false}
                />
              </Box>
              <Typography variant="h3" gutterBottom component="div">
                Create Account
              </Typography>
              <Typography variant="h6" color="textSecondary" component="div">
                Join us to streamline your business operations
              </Typography>
            </Box>

            <form onSubmit={onSubmit} className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name*"
                    error={!!errors.companyName}
                    helperText={errors.companyName}
                    value={form.companyName}
                    onChange={(e) => update('companyName', e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name*"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    value={form.firstName}
                    onChange={(e) => update('firstName', e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name*"
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    value={form.lastName}
                    onChange={(e) => update('lastName', e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email*"
                    error={!!errors.email}
                    helperText={errors.email}
                    type="email"
                    value={form.email}
                    onChange={(e) => update('email', e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Password*"
                    error={!!errors.password}
                    helperText={errors.password}
                    value={form.password}
                    onChange={(e) => update('password', e.target.value)}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm Password*"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    value={form.confirmPassword}
                    onChange={(e) => update('confirmPassword', e.target.value)}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    disabled={saving}
                  >
                    Create Account
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
        <Backdrop open={saving} />
      </Box>
    </AuroraBackground>
  );
}

export default Register;
