import {Card, Typography, Stack, Box} from '@mui/material';
import { AccountBalanceWalletOutlined, LocalOfferOutlined } from '@mui/icons-material';
import React from 'react';
import ClientBillingAPI from '@oneAppCore/services/apis/ClientBilling/ClientBillingAPI';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

function WalletBalance() {
    const [balance, setBalance] = React.useState<number>(0);
    const [labelInfo, setLabelInfo] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchBalance = async () => {
            try {
                const api = new ClientBillingAPI();
                const response = await api.getRows();
                setBalance(response.availableBalance);
                setLabelInfo(response.labelInfo);
                setError(null);
            } catch (err) {
                setError('Failed to load balance');
                console.error('Error fetching balance:', err);
            } finally {
                setLoading(false);
            }
        };
 
        fetchBalance();
    }, []);


    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    return (
        <Stack direction={{xs: 'column', sm: 'row'}} spacing={3}>
            <Card sx={{
                flex: 1,
                p: 3,
                backgroundColor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 2
                }
            }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Box
                        sx={{
                            backgroundColor: 'primary.main',
                            borderRadius: '50%',
                            p: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <AccountBalanceWalletOutlined fontSize='medium' sx={{color: 'white'}}/>
                    </Box>
                    <Box>
                        <Typography variant="body2" sx={{ mb: 0.5, color: 'text.secondary' }}>
                            Available Balance
                        </Typography>
                        <Typography variant='h4' sx={{
                            color: 'primary.main',
                            fontWeight: 600,
                            letterSpacing: '-0.5px'
                        }}>
                            {loading ? '...' : error ? 'Error' : formatCurrency(balance)}
                        </Typography>
                    </Box>
                </Stack>
            </Card>
            <Card sx={{
                flex: 1,
                p: 3,
                backgroundColor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 2
                }
            }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Box
                        sx={{
                            backgroundColor: 'secondary.main',
                            borderRadius: '50%',
                            p: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <LocalOfferOutlined fontSize='medium' sx={{color: 'white'}}/>
                    </Box>
                    <Stack direction='row' alignItems='flex-end' spacing={2}>
                    <Box>
                        <Typography variant="body2" sx={{ mb: 0.5, color: 'text.secondary' }}>
                            Labels Purchased 30 days
                        </Typography>
                        <Typography variant='h4' sx={{
                            color: 'secondary.main',
                            fontWeight: 600,
                            letterSpacing: '-0.5px'
                        }}>
                           {/* {loading ? '...' : error ? 'Error' : formatCentsToDollarsIntl(labelInfo?.labelcost || 0, { removeDollarSign: false })} */}
                           {loading ? '...' : error ? 'Error' : new Intl.NumberFormat('en-US').format(labelInfo?.labelcount) || 0}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" sx={{ mb: 0.5, color: 'text.secondary' }}>
                            Label Cost 30 Days
                        </Typography>
                        <Typography variant='h4' sx={{
                            color: 'secondary.main',
                            fontWeight: 600,
                            letterSpacing: '-0.5px'
                        }}>
                           {loading ? '...' : error ? 'Error' : formatCentsToDollarsIntl(labelInfo?.labelcost || 0, { removeDollarSign: false })}
                        </Typography>
                    </Box>
                    
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    );
}

export default WalletBalance;