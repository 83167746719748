import BaseApi from '../../BaseApi';
import { ShopifyCredentials } from 'types/channelTypes';
import { OrderWithItems } from 'types/orderTypes';

class ExportedOrdersApi extends BaseApi {
  constructor() {
    super();
  }
  readProducts() {
    return this.getBase<any>(`/api/v1/exported-orders`);
  }
  createExportedOrder(body: OrderWithItems) {
    return this.postBase<any>(`/api/v1/exported-orders`, body);
  }
  fulfillOrderOnShopify(id: string, body: ShopifyCredentials) {
    return this.putBase<any>(`/api/v1/exported-orders/${id}`, body);
  }
  pushOrderToShopify(body: OrderWithItems) {
    return this.postBase<any>(`/api/v1/exported-orders/push`, body);
  }
}

export default ExportedOrdersApi;
