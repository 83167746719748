import {
  getColor,
  getBootstrapColor,
  getHexColorDupe,
} from '@src/utils/colors';
import {
  calculateProfit,
  convertDollarsToCents,
  suggestProfitMultiplier,
  formatCentsToDollars,
  formatCentsToDollarsIntl,
  toFixedTwo,
} from '@oneAppCore/utils/currency';
import dateUtil from '@src/utils/dates';

import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import {
  ACTIVE,
  APPROVED,
  COMPLETED,
  CREATED,
  INACTIVE,
  PENDING,
  REJECTED,
  REVIEW,
  DRAFT,
} from '@oneAppCore/constants/listings';
import { omit, pick, set } from 'lodash';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { Color } from '@oneAppCore/types/commonTypes';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  SHOPIFY_PRODUCT_LINK,
  SHOPIFY,
  channelIds,
} from '@oneAppCore/constants/channels';
import StyledTooltip from '@src/Components/common/TooltipTable/StyledTooltip';

export const columns = (classes): Column[] => [
  {
    title: '',
    keyName: 'listingImages',
    sortBy: false,
    align: 'left',
    customComponent: function ImageRender(row) {
      const { listingImages } = row;
      return (
        <img
          src={
            row.listingImages != null
              ? row.listingImages
              : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
          }}
          alt="product image"
          width={50}
          height={50}
        />
      );
    },
  },
  {
    title: 'Listing',
    keyName: 'name',
    sortBy: true,
    customComponent: function NameRender(row) {
      const {
        id: [id],
        channelName,
        storeName,
        listingSku: [listingSku],
        storeProductId,
        variation,
        name: [name],
      } = row;
      return (
        <Grid
          container
          style={{
            flexDirection: 'column',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '18px',
            minWidth: '155px',
            maxWidth: '200px',
          }}
        >
          <Grid item>
            {name}
            {variation ? ` - (${variation})` : ''}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
              Item ID:
            </span>
            {storeProductId}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>SKU:</span>
            {listingSku}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
              Channel:
            </span>
            {channelName} {storeName}
          </Grid>

          <Grid style={{ display: 'none' }}>{id}</Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Updated At',
    // title: 'Created At',
    keyName: 'updatedAt',
    sortBy: true,
    align: 'center',
    customComponent: function DateRender(row) {
      let updatedAt = new Date(
        row.priceChangedAt[row.priceChangedAt.length - 1],
      );
      if (!updatedAt || updatedAt < new Date(row.updatedAt)) {
        updatedAt = new Date(row.updatedAt);
      }
      return (
        <p style={{ margin: 0 }}>
          {updatedAt && updatedAt !== null
            ? dateUtil.getFormattedDate({ date: updatedAt.toString() })
            : 'N/A'}
        </p>
      );
    },
  },
  {
    title: 'Channel',
    keyName: 'channelName',
    align: 'center',
    sortBy: false,
    customComponent: function Channel(row) {
      const channelProductId = row.channelProductIds.filter(
        (e) => e !== null,
      )[0];
      let href = '';
      if (channelProductId) {
        if (row?.channelName === AMAZON) {
          href = AMAZON_ASIN_LINK(channelProductId);
        } else if (row?.channelName === WALMART) {
          href = WALMART_PRODUCT_LINK(channelProductId);
        } else if (row?.channelName === EBAY) {
          href = EBAY_PRODUCT_LINK(channelProductId);
        } else if (row?.channelName === SHOPIFY) {
          href = SHOPIFY_PRODUCT_LINK(row?.store, channelProductId);
        }
      }
      return (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <img
            width={35}
            height={35}
            style={{ borderRadius: 10 }}
            src={
              row.icon != null
                ? row.icon
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
          {href !== '' && (
            <Link href={href} target="_blank">
              <FontAwesome name="external-link-alt" type="fa" form="fa" />
            </Link>
          )}
        </Grid>
      );
    },
  },
  {
    title: 'MSRP',
    keyName: 'msrp',
    sortBy: true,
    align: 'center',
    customComponent: function price(row) {
      const msrp = row?.msrp > 0 ? row.msrp : null;
      return msrp ? (
        <p style={{ margin: 0 }}>{formatCentsToDollars(msrp)}</p>
      ) : null;
    },
  },
  {
    title: 'MAP',
    keyName: 'map',
    sortBy: true,
    align: 'center',
    customComponent: function price(row) {
      const map = row?.map > 0 ? row.map : null;
      return map ? (
        <p style={{ margin: 0 }}>{formatCentsToDollars(map)}</p>
      ) : null;
    },
  },
  {
    title: 'Price',
    keyName: 'price',
    sortBy: true,
    align: 'center',
    customComponent: function price(row, functions) {
      const {
        id: [id],
        price: oldPrice,
        listingIds: oldListingIds,
      }: { id: number[]; price: number; listingIds: number[] } = row;
      const { changedRows } = functions;
      const options = { removeMoneySign: true };
      let displayPrice: string = formatCentsToDollars(oldPrice, options);
      let newPrice: number = oldPrice;
      let currentListOfIds = oldListingIds;
      const change = changedRows.find((item) => item.id === id);
      if (change) {
        const { price: xPrice, newDisplayPrice, listingIds } = change;
        if (xPrice) {
          newPrice = Number.parseFloat(formatCentsToDollars(xPrice));
        }
        if (newDisplayPrice || newDisplayPrice === '') {
          displayPrice = newDisplayPrice;
        }
        if (listingIds) {
          currentListOfIds = listingIds;
        }
      }

      const currentListingNotifications = functions.notifications.find(
        (notification) =>
          notification.listingIds.some((id) => currentListOfIds.includes(id)),
      );
      const listingPriceAmount = currentListingNotifications?.lowestPrice
        ? currentListingNotifications.lowestPrice
        : null;
      let notificationData = null;
      if (currentListingNotifications?.notificationData) {
        notificationData = currentListingNotifications.notificationData.reduce(
          (lowest, item) => {
            if (!lowest) {
              return item;
            }
            return item.notifications.buyBoxWinner.listingPriceAmount <
              lowest.notifications.buyBoxWinner.listingPriceAmount
              ? item
              : lowest;
          },
          null,
        );
      }

      let buyBoxWinnerName = '';
      if (notificationData?.sellerName) {
        buyBoxWinnerName = notificationData.sellerName;
      }
      const buttonText = !listingPriceAmount
        ? `${formatCentsToDollars(oldPrice)}`
        : `${buyBoxWinnerName}: ${formatCentsToDollars(listingPriceAmount)}`;

      const buttonColor = !notificationData?.notifications
        ? 'primary'
        : getColor('red', 'dark');

      return (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ alignContent: 'center', minWidth: '120px' }}
        >
          <Grid item xs={12}>
            <TextField
              title={`Current Price: ${formatCentsToDollars(oldPrice)}`}
              variant="outlined"
              value={displayPrice}
              inputProps={{ type: 'string', step: 0.01 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => {
                newPrice = convertDollarsToCents(e.target.value);
                const isUpdate = displayPrice !== e.target.value;
                const newDisplayPrice = e.target.value;
                functions.alterChangedRows(
                  id,
                  {
                    price: newPrice,
                    newDisplayPrice,
                    listingIds: currentListOfIds || [],
                  },
                  isUpdate,
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            
              <Button type="button" color="primary" disabled fullWidth>
                {formatCentsToDollars(oldPrice)}
              </Button>
            
          </Grid>
        </Grid>
      );
    },
  },

  {
    title: 'Min Price',
    keyName: 'minPrice',
    sortBy: true,
    align: 'center',
    customComponent: function minPrice(row, functions) {
      const {
        id: [id],
        minPrice: oldMin,
        cost,
        shipping,
        totalCost,
        suggestedPrice,
        listingIds: oldListingIds,
      } = row;
      /* const totalCost = 
        mscost && msship
          ? mscost + msship
          : oldMin && msship
            ? oldMin + msship
            : oldMin; */

      const { changedRows } = functions;
      let minPrice = oldMin;
      const suggMinPrice = Math.ceil(totalCost * suggestProfitMultiplier(5));
      const options = { removeMoneySign: true };
      let displayMinPrice = formatCentsToDollars(suggMinPrice, options);
      const change = changedRows.find((item) => item.id === id);
      let currentListOfIds = oldListingIds;

      if (change) {
        const { minPrice: xMin, newDisplayMinPrice: nMin, listingIds } = change;
        if (xMin) {
          minPrice = xMin;
        } else if (minPrice !== suggMinPrice) {
          minPrice = suggMinPrice;
        }
        if (nMin || nMin === '') {
          displayMinPrice = nMin;
        }
        if (listingIds) {
          currentListOfIds = listingIds;
        }
      } else {
        if (suggMinPrice !== oldMin) minPrice = suggMinPrice;
      }

      return (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ alignContent: 'center', minWidth: '120px' }}
        >
          <Grid item xs={12}>
            <TextField
              title={`Current Min Price: ${formatCentsToDollars(oldMin)}`}
              style={{ alignContent: 'center' }}
              variant="outlined"
              value={displayMinPrice}
              // type="number"
              inputProps={{ type: 'string', step: 0.01 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => {
                minPrice = convertDollarsToCents(e.target.value);
                const isUpdate = displayMinPrice !== e.target.value;
                displayMinPrice = e.target.value;
                functions.alterChangedRows(
                  id,
                  {
                    minPrice: minPrice,
                    newDisplayMinPrice: displayMinPrice,
                    listingIds: currentListOfIds || [],
                  },
                  isUpdate,
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              color="primary"
              onClick={() => {
                functions.alterChangedRows(
                  id,
                  {
                    price: minPrice,
                    minPrice: minPrice,
                    newDisplayPrice: displayMinPrice,
                    listingIds: currentListOfIds || [],
                  },
                  true,
                );
              }}
            >
              Set to Min
            </Button>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Max Price',
    keyName: 'maxPrice',
    sortBy: true,
    align: 'center',
    customComponent: function maxprice(row, functions) {
      const {
        id: [id],
        minPrice,
        maxPrice: oldMax,
        cost,
        shipping,
        totalCost,
        listingIds: oldListingIds,
      } = row;
      /* const totalCost =
        mscost && msship
          ? mscost + msship
          : oldMax && msship
            ? oldMax + msship
            : oldMax; */
      const { changedRows } = functions;
      let currentListOfIds = oldListingIds;
      const change = changedRows.find((item) => item.id === id);
      let maxPrice = oldMax;
      const suggMaxPrice = Math.ceil(totalCost * suggestProfitMultiplier(15));
      const options = { removeMoneySign: true };
      let displayMaxPrice = formatCentsToDollars(suggMaxPrice, options);

      if (change) {
        const { maxPrice: xMax, newDisplayMaxPrice: nMax, listingIds } = change;
        if (xMax) {
          maxPrice = xMax;
        } else if (maxPrice !== suggMaxPrice) {
          maxPrice = suggMaxPrice;
        }
        if (nMax || nMax === '') {
          displayMaxPrice = nMax;
        }
        if (listingIds) {
          currentListOfIds = listingIds;
        }
      } else {
        if (maxPrice !== suggMaxPrice) maxPrice = suggMaxPrice;
      }
      let currentMaxPrice = maxPrice;

      return (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ alignContent: 'center', minWidth: '120px' }}
        >
          <Grid item xs={12}>
            <TextField
              title={`Current Max Price: ${formatCentsToDollars(oldMax)}`}
              variant="outlined"
              value={displayMaxPrice}
              // value={currentMaxPrice.toFixed(2) || 0}
              // defaultValue={currentMaxPrice.toFixed(2) || 0}
              // type="number"
              inputProps={{ type: 'string', step: 0.01 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => {
                maxPrice = convertDollarsToCents(e.target.value);
                const isUpdate = displayMaxPrice !== e.target.value;
                displayMaxPrice = e.target.value;
                functions.alterChangedRows(
                  id,
                  {
                    maxPrice: maxPrice,
                    newDisplayMaxPrice: displayMaxPrice,
                    listingIds: currentListOfIds || [],
                  },
                  isUpdate,
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              color="primary"
              onClick={() => {
                functions.alterChangedRows(
                  id,
                  {
                    price: currentMaxPrice,
                    maxPrice: currentMaxPrice,
                    newDisplayPrice: displayMaxPrice,
                    listingIds: currentListOfIds || [],
                  },
                  true,
                );
              }}
            >
              Set to Max
            </Button>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Cost Breakdown',
    keyName: 'mscost',
    sortBy: false,
    align: 'center',
    customComponent: function CostBreakdown(row) {
      const {
        cost,
        shipping,
        discountAmount,
        rowSuppliers,
        msrp,
      } = row;
      const discount =
        msrp && msrp > 0
          ? Math.ceil(100 - (cost / msrp) * 100)
          : discountAmount
            ? discountAmount
            : 0;
      return (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={
            <TableContainer className={classes.tablePaper} style={{ overflowX: 'visible', overflowY: 'visible' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Supplier</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Ship</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowSuppliers &&
                    rowSuppliers?.length >= 1 &&
                    rowSuppliers.map((supplier, index) => (
                      <TableRow key={index}>
                        <TableCell classes={{ root: classes.tableCell }}>
                          {supplier?.metaData?.shortName ||
                            supplier?.name
                              .match(/\b(\w)/g)
                              .join('')
                              .toUpperCase()}
                        </TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(supplier.cost)}
                        </TableCell>
                        <TableCell>
                          {formatCentsToDollarsIntl(supplier.estimatedShipping > 0 ? supplier.estimatedShipping : supplier.shippingCost)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        >
          <Grid
            container
            direction="row"
            spacing={1}
            style={{
              alignContent: 'center',
              minWidth: '125px',
              fontSize: '0.75rem',
              textAlign: 'center',
            }}
          >
            <Grid
              item
              container
              xs={12}
              direction="row"
              style={{
                border: `1.5px solid ${getColor('gold', 'darkest')}`,
                backgroundColor: getColor('gold', 'lightest'),
                borderRadius: 5,
                padding: 5,
                marginBottom: 5,
                // width: 95,
              }}
            >
              <Grid item xs={12} sm={5} md={4}>
                <strong>Cost: </strong>
              </Grid>
              <Grid title={`${rowSuppliers[0]}`} item xs={12} sm={7} md={8}>
                {formatCentsToDollars(cost)}
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                <strong>Ship:</strong>
              </Grid>
              <Grid title={`${rowSuppliers[0]}`} item xs={12} sm={7} md={8}>
                {formatCentsToDollars(shipping)}
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                <strong>Total:</strong>
              </Grid>
              <Grid title={`${rowSuppliers[0]}`} item xs={12} sm={7} md={8}>
                {formatCentsToDollars(cost + shipping)}
              </Grid>

              {discount ? (
                <>
                  <Grid item xs={12} sm={6} md={8}>
                    <strong>Discount:</strong>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {discount} %
                  </Grid>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Tooltip>
      );
    },
  },
  {
    title: 'Profit',
    keyName: 'profit',
    align: 'center',
    sortBy: false,
    customComponent: function ProfitChip(row, functions) {
      const {
        id: [id],
        price: oldPrice,
        cost,
        shipping,
        totalCost,
        revenue: rowRev,
      } = row;
      const { changedRows } = functions;
      const { profit, percent, revenue: oldRev } = calculateProfit(
        oldPrice,
        totalCost,
      );
      let newProfit = profit;
      let revenue = oldRev ? oldRev : rowRev;
      const change = changedRows.find((item) => item.id === id);
      let newProfitPerc = percent;

      if (change) {
        const { price } = change;
        if (price) {
          const { profit, percent, revenue: calcRev } = calculateProfit(
            price,
            totalCost,
          );
          newProfit = profit;
          newProfitPerc = percent;
          revenue = calcRev;
        }
      }

      const borderColor = (() => {
        if (!newProfitPerc) {
          return getColor('grey', 'darkest');
        }
        if (newProfitPerc < 0) {
          return getColor('red', 'darkest');
        } else if (newProfitPerc >= 0 && newProfitPerc <= 5) {
          return getColor('orange', 'darkest');
        } else {
          return getColor('green', 'darkest');
        }
      })();

      const bgColor = (() => {
        if (!newProfit) {
          return getColor('grey', 'lightest');
        }
        if (newProfit < 0) {
          return getColor('red', 'lightest');
        } else if (newProfitPerc >= 0 && newProfitPerc <= 5) {
          return getColor('orange', 'lightest');
        } else {
          return getColor('green', 'lightest');
        }
      })();

      const styleObj = {
        padding: 5,
        borderRadius: 10,
        height: 10,
        marginTop: 5,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.75rem',
      };

      return (
        <Grid
          container
          alignItems="center"
          style={{ minWidth: '125px' }}
          direction="row"
        >
          <Grid
            item
            xs={12}
            alignContent="flex-start"
            style={{
              border: `1.5px solid ${borderColor}`,
              backgroundColor: bgColor,
              borderRadius: 5,
              padding: 5,
              marginBottom: 5,

              textAlign: 'center',
            }}
          >
            <Grid
              title="Revenue"
              item
              style={styleObj}
              xs={12}
              sm={7} /* md={4} */
            >
              <strong>Revenue: </strong>
            </Grid>
            <Grid
              title="Revenue Amount"
              item
              style={styleObj}
              xs={12}
              sm={5} /* md={8} */
            >
              {toFixedTwo(revenue)}
            </Grid>
            <Grid title="Profit" item style={styleObj} xs={12} sm={6}>
              <strong>Profit: </strong>
            </Grid>
            <Grid title="Profit Amount" item style={styleObj} xs={12} sm={6}>
              {toFixedTwo(newProfit)}
            </Grid>
            <Grid
              title="Profit Percentage"
              item
              style={styleObj}
              xs={12}
              sm={6} /* md={4} */
            >
              <strong>Percent:</strong>
            </Grid>
            <Grid
              title="Percentage"
              item
              style={styleObj}
              xs={12}
              sm={6} /* md={8} */
            >
              {newProfitPerc.toFixed(2)}%
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={12}
            alignContent="center"
            style={{
              border: `1.5px solid ${color}`,
              backgroundColor: bgColor,
              borderRadius: 5,
              padding: 5,
              width: 95,
            }}
          >
            <div
              title="existing profit margin"
              style={{
                // backgroundColor,
                padding: 10,
                borderRadius: 5,
                height: 10,
                marginTop: 5,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
                // color: 'black',
              }}
            >
              <strong>{newProfitPerc.toFixed(2)}%</strong>
            </div>
          </Grid> */}
        </Grid>
      );
    },
  },
  {
    title: 'Sales Total',
    keyName: 'salesTotal',
    sortBy: true,
    align: 'center',
    customComponent: function RenderSalesTotal(row) {
      const { salesTotal, salesPerPeriod } = row;
      // const salesAgg = salesTotal.reduce((acc, e) => acc + (e || 0), 0);
      const salesPeriodAgg = salesPerPeriod.reduce(
        (acc, e) => parseInt(acc) + (parseInt(e) || 0),
        0,
      );
      return (
        <>
          <p
            title="Total sales volume"
            style={{
              textAlign: 'center',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '15px',
              margin: '0',
            }}
          >
            {formatCentsToDollars(salesTotal || 0)}
          </p>
          <br />
          <p
            title="Number of sales"
            style={{
              textAlign: 'center',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '15px',
              margin: '0',
            }}
          >
            {salesPeriodAgg || 0}
          </p>
        </>
      );
    },
  },
  {
    title: 'Actions / Status',
    keyName: 'approve',
    sortBy: false,
    align: 'center',
    customComponent: function EditRender(row, functions) {
      const {
        id: [id],
        mscost,
        cost,
        price,
        msship,
        status: rowStatus,
        listingIds,
      } = row;
      const totalCost =
        mscost && msship
          ? mscost + msship
          : cost && msship
            ? cost + msship
            : cost;
      const suggMax = Math.ceil(totalCost * suggestProfitMultiplier(15));
      const suggMin = Math.ceil(totalCost * suggestProfitMultiplier(2));
      const hasSugg = suggMax !== price;

      const okList = [PENDING, COMPLETED];

      let change = functions.changedRows.find((item) => {
        return item.id === id;
      });
      let disableButton = true;
      if (change || hasSugg) {
        disableButton = false;
      }

      let status: string = change || suggMax !== price ? CREATED : '';
      if (change?.status) status = change?.status;
      if (rowStatus) status = rowStatus;
      const bgColor =
        status === REVIEW || status === DRAFT
          ? getBootstrapColor('warning', 0)
          : status === INACTIVE || status === COMPLETED
            ? getBootstrapColor('dark', 0)
            : status === CREATED
              ? getBootstrapColor('light', 0)
              : status === ACTIVE || status === PENDING
                ? getBootstrapColor('success', 0)
                : status === REJECTED
                  ? getBootstrapColor('danger', 0)
                  : getBootstrapColor('light', 0);

      const chip = (
        <Grid item justifyContent="center" xs={8}>
          <p
            style={{
              backgroundColor: bgColor,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              padding: '2px 2px',
              color: 'white',
              borderRadius: '17px',
              textAlign: 'center',
            }}
          >
            <strong>
              {status?.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
            </strong>
          </p>
        </Grid>
      );

      const submitButtonChange = (
        <>
          <Button
            title="Submit changes"
            variant="outlined"
            disabled={disableButton || functions.loadingRows?.[id]}
            style={{ borderRadius: 5, minWidth: '40px', minHeight: '40px' }}
            onClick={() => {
              functions.saveChanges({
                minPrice: suggMin,
                maxPrice: suggMax,
                price: suggMax,
                ...change,
              });
            }}
          >
            {functions.loadingRows?.[id] ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <FontAwesome
                name="check"
                type="fal"
                form="fa"
                style={{
                  cursor: disableButton ? 'not-allowed' : 'pointer',
                  color: disableButton
                    ? getHexColorDupe('9')
                    : getColor('dark_blue', 'main'),
                }}
              />
            )}
          </Button>
        </>
      );

      const submitButtonSuggestion = (
        <>
          <Button
            title="Submit suggested change"
            variant="outlined"
            disabled={disableButton || functions.loadingRows?.[id]}
            style={{ borderRadius: 5, minWidth: '40px', minHeight: '40px' }}
            onClick={() => {
              functions.saveChanges({
                id,
                price: suggMax,
                maxPrice: suggMax,
                minPrice: suggMin,
                listingIds,
              });
            }}
          >
            {functions.loadingRows?.[id] ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <FontAwesome
                name="check"
                type="fal"
                form="fa"
                style={{
                  cursor: disableButton ? 'not-allowed' : 'pointer',
                  color: disableButton
                    ? getHexColorDupe('9')
                    : getColor('dark_blue', 'main'),
                }}
              />
            )}
          </Button>
        </>
      );

      const adminButtons = (
        <Grid item container xs={6} style={{ justifyContent: 'center' }}>
          <Button
            title="Approve Visible Changes"
            style={{
              backgroundColor: getColor('dark_green', 'main'),
              borderRadius: 5,
              marginBottom: 5,
            }}
            size="small"
            variant="contained"
            onClick={() => {
              functions.approveChanges({ ...change, listingIds });
            }}
          >
            <FontAwesome
              name="check"
              type="fal"
              form="fa"
              style={{ color: getColor('black', 'contrastText') }}
            />
          </Button>
          <Button
            title="Reject Visible Changes"
            style={{
              backgroundColor: getColor('dark_red', 'main'),
              borderRadius: 5,
            }}
            variant="outlined"
            size="small"
            onClick={() => {
              functions.rejectChanges({ ...change, listingIds });
            }}
          >
            <FontAwesome
              name="times"
              type="fa"
              form="fas"
              style={{ color: getColor('black', 'contrastText') }}
            />
          </Button>
        </Grid>
      );

      return (
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          {(() => {
            if (change) {
              if (change.status) {
                return adminButtons;
              } else {
                return submitButtonChange;
              }
            } else if (hasSugg) {
              return submitButtonSuggestion;
            } else {
              return (
                <Grid xs={12}>
                  <Button
                    variant="outlined"
                    disabled={disableButton}
                    style={{ borderRadius: 5 }}
                    onClick={() => {
                      functions.saveChanges(change);
                    }}
                  >
                    <FontAwesome
                      name="check"
                      type="fal"
                      form="fa"
                      style={{
                        cursor: 'pointer',
                        color: disableButton
                          ? getHexColorDupe('a')
                          : getColor('dark_green', 'main'),
                      }}
                    />
                  </Button>
                </Grid>
              );
            }
          })()}
          {change || okList.includes(rowStatus) ? chip : <></>}
        </Grid>
      );
    },
  },
];

export const filters = (stores: any[] = []): any[] => [
  {
    type: 'string',
    name: 'Product ID',
    keyName: 'storeProductId',
  },
  /*  {
    type: 'string',
    name: 'Channel Product ID',
    keyName: 'channelProductId',
  },
  {
    type: 'selector',
    name: 'Status',
    keyName: 'status',
    properties: [
      { label: 'Pending', value: 'pending' },
      { label: 'Draft', value: 'draft' },
      { label: 'Complete', value: 'complete' },
    ],
  }, */
  {
    type: 'selector',
    name: 'Store',
    keyName: 'storeId',
    properties: [
      // { label: 'all', value: null },
      ...stores.map((store) => ({
        label: store.name,
        value: store.id,
      })),
    ],
  },
];
