import {useState} from 'react';
import {Box, Typography, Button, Stack} from '@mui/material';
import {Add} from '@mui/icons-material';
import WalletBalance from './WalletBalance';
import AddFunds from './WalletBalance/AddFunds';
import Transactions from './Transactions';

function ClientBilling() {
    const [isAddFundsOpen, setIsAddFundsOpen] = useState(false);
    const [balance] = useState({available: 0, pending: 0});
    const [refreshKey, setRefreshKey] = useState(0);

    const handleTransactionSuccess = () => {
        setRefreshKey(prev => prev + 1);
    };

    return (
        <Box sx={{
            p: { xs: 2, md: 3 },
            backgroundColor: '#f5f5f5',
            minHeight: '100vh',
            maxWidth: '100%',
            overflow: 'hidden'
        }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{mb: 4}}>
                {/* <Typography variant='h4' sx={{ 
                    fontWeight: 600,
                    color: 'text.primary'
                }}>
                    Billing
                </Typography> */}
                <Button
                    variant='contained'
                    startIcon={<Add/>}
                    onClick={() => setIsAddFundsOpen(true)}
                    sx={{
                        textTransform: 'none',
                        px: 3,
                        py: 1,
                        borderRadius: 2
                    }}
                >
                    Add Funds
                </Button>
            </Stack>
            <Box sx={{ mb: 4 }}>
                <WalletBalance />
            </Box>
            <Box sx={{ 
                backgroundColor: 'background.paper',
                borderRadius: 2,
                boxShadow: 1,
                overflow: 'hidden'
            }}>
                <Transactions key={refreshKey} />
            </Box>

            <AddFunds 
                open={isAddFundsOpen} 
                onClose={() => setIsAddFundsOpen(false)}
                onSuccess={handleTransactionSuccess}
            />
        </Box>
    )
}

export default ClientBilling;
