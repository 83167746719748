import {useEffect, useState} from "react";
import {
    Paper,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table
} from '@mui/material';
import ImportApi from '@oneAppCore/services/apis/Imports';
import { tableNames } from "@oneAppCore/constants/db";
import Papa from 'papaparse';
import { useSnackbar } from "notistack";
import { tableList, customImports } from "@oneAppCore/constants/import";
import { Props } from "../types";
import { options } from "joi";
import { FontAwesome } from "@src/Components/common";


// const TableSelect = ({
//     title='Import',
//     options,
//     selectedTable,
// }: Props) => {
//     const [selectedTable, setSelectedTable] = useState<string>('');
//     const [uploadType, setUploadType] = useState<string>('table')
//     const [tableSelOpts, setTableSelOpts] = useState<any[]>([]);

//     console.log(selectedTable)

//     useEffect(() => {
//         const hasTableName = options?.tableName;
//         const hasTableList = options?.tableList;
//         if (hasTableName) {
//           setSelectedTable(hasTableName);
//           handleCustom(hasTableName);
//         } else if (hasTableList) {
//           const isString = (typeof hasTableList[0] === 'string');
//           if (isString) {
//             setTableSelOpts(hasTableList.map((table) => formatOptions(table)));
//           } else {
//             setTableSelOpts(hasTableList);
//           }
//         } else {
//           const func = async () => {
//             const tables = await ImportApi.getTables();
//             setTableSelOpts(tables.map((table) => formatOptions(table)));
//           };
//           func();
//         }
//       }, []);

//       const formatOptions = (option) => {
//         const textArray = option?.split('_') || [];
//         const label = textArray.map((text: string) => {
//             const res = `${text[0].toUpperCase()}${text.slice(1)}`
//             return res;
//         })
//         .join(' ')
//         return {
//             label, value: option
//         }
//       };

//     const handleCustom = (tableName) => {
//         if (Object.keys(customImports).includes(tableName)) setUploadType('custom');
//     }

//     const handleTableChange = (tableName) => {
//         setSelectedTable(tableName);
//         handleCustom(tableName)
//     }
//     return (
//         <Paper sx={{p: 4}}>
//             <Box
//                 display='flex' flexDirection='column' gap={3}
//             >
//                 <Box display='flex' alignItems='center' gap={2}>
//                     <FontAwesome name='database' type='fa' form='fas' />
//                     <Typography variant='h6'>
//                         Select Database Table
//                     </Typography>
//                 </Box>

//                 <FormControl fullWidth>
//                     <InputLabel id='table-select'>Table</InputLabel>
//                     <Select
//                         value={selectedTable}
//                         labelId='table-select-label'
//                         label='Table'
//                         onChange={(e: any) => handleTableChange(e?.target?.value)}
//                     >
//                         {tableSelOpts.map((opt) => (
//                             <MenuItem
//                                 value={opt.value}
//                                 key={`${opt.value}-${opt.label}`}
//                             >
//                                 {opt.label}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>
//             </Box>
//         </Paper>
//     )
// }

// export default TableSelect;

const TableSelect = ({
    title = 'Import',
    options,
    selectedTable: parentSelectedTable, 
    onTableSelect, 
}: Props) => {
    const [selectedTable, setSelectedTable] = useState<string>(parentSelectedTable || '');
    const [uploadType, setUploadType] = useState<string>('table');
    const [tableSelOpts, setTableSelOpts] = useState<any[]>([]);
    const [localSelectedTable, setLocalSelectedTable] = useState<string>(selectedTable || '');
    const [mapperColumns, setMapperColumns] = useState([]);



    // const formatOptions = (option: string) => {
    //     const textArray = option?.split('_') || [];
    //     const label = option
    //         ?.split('_')
    //         ?.map((text: string) => `${text[0].toUpperCase()}${text.slice(1)}`)
    //         .join(' ');
    //     return { label, value: option };
    // };

    const formatOptions = (option) => {
        const textArray = option?.split('_') || [];
        const label = textArray
          .map((text: string) => {
            const res = `${text[0].toUpperCase()}${text.slice(1)}`;
            return res;
          })
          .join(' ');
        return { label, value: option };
      };

    const handleTableChangeTest = (tableName: string) => {
        setLocalSelectedTable(tableName);
        onTableSelect?.(tableName); 
    };

    // useEffect(() => {
    //     const hasTableName = options?.tableName;
    //     const hasTableList = options?.tableList;

    //     if (hasTableName) {
    //         setSelectedTable(hasTableName);
    //         handleCustom(hasTableName);
    //     } else if (hasTableList) {
    //         const isString = typeof hasTableList[0] === 'string';
    //         setTableSelOpts(isString ? hasTableList.map(formatOptions) : hasTableList);
    //     } else {
    //         const fetchTables = async () => {
    //             const tables = await ImportApi.getTables();
    //             setTableSelOpts(tables.map(formatOptions));
    //         };
    //         fetchTables();
    //     }
    // }, [options]);
        useEffect(() => {
        const hasTableName = options?.tableName;
        const hasTableList = options?.tableList;
        if (hasTableName) {
          setSelectedTable(hasTableName);
          handleCustom(hasTableName);
        } else if (hasTableList) {
          const isString = (typeof hasTableList[0] === 'string');
          if (isString) {
            setTableSelOpts(hasTableList.map((table) => formatOptions(table)));
          } else {
            setTableSelOpts(hasTableList);
          }
        } else {
          const func = async () => {
            const tables = await ImportApi.getTables();
            setTableSelOpts(tables.map((table) => formatOptions(table)));
          };
          func();
        }
      }, []);

    

    const handleCustom = (tableName: string) => {
        if (Object.keys(customImports).includes(tableName)) setUploadType('custom');
    };

    const handleTableChange = (tableName: string) => {
        setSelectedTable(tableName);
        handleCustom(tableName);
        onTableSelect?.(tableName);
    };

    const tableSelectMapper = (value, index) => {
        let data = mapperColumns;
        data[index].mappedColumn = value;
        setMapperColumns([...data]);
    }



    return (
        <Paper sx={{ p: 4 }}>
            <Box display="flex" flexDirection="column" gap={3}>
                <Box display="flex" alignItems="center" gap={2}>
                    <FontAwesome name="database" type="fa" form="fas" />
                    <Typography variant="h6">Select Database Table</Typography>
                </Box>

                <FormControl fullWidth>
                    <InputLabel id="table-select">Table</InputLabel>
                    <Select
                        value={selectedTable}
                        labelId="table-select-label"
                        label="Table"
                        onChange={(e: any) => handleTableChange(e.target.value)}
                    >
                        {tableSelOpts.map((opt) => (
                            <MenuItem value={opt.value} key={`${opt.value}-${opt.label}`}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Paper>
    );
};

export default TableSelect;