import { ReactElement } from 'react';
import { ISSUE, TRACKED, PLACED, COMPLETED, CANCELLED, UNASSIGNED } from '@oneAppCore/constants/orders';
import { startCase, lowerCase } from 'lodash';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { getColor } from '@src/utils/colors';
import { orderProfitColors, orderStatusColors } from '@src/utils/orders';
import dayjs from 'dayjs';
import { convertDollarsToCents, formatCentsToDollars } from '@oneAppCore/utils/currency';
import { getShipping } from '../../utils';

const formatNumber = (inputCost: string | number | null) => {
  let returnedValue = 'N/A';
  if (inputCost) {
    returnedValue = formatCentsToDollars(inputCost, { removeMoneySign: true })
  }
  return returnedValue;
};

export const firstColumns = [
  {
    id: 1,
    title: 'Order #',
    key: 'channelOrderId', //TODO make this clickable later?
  },
  {
    id: 2,
    title: 'Bought At',
    key: 'purchaseDate',
    customComponent: function purchaseDate({ item }) {
      const time = dayjs(item.purchaseDate).format('MM-DD-YY hh:mm A');
      return <p>
        {time}
      </p>;
    },
  },
  {
    id: 3,
    title: 'Ship By',
    key: 'expectedShipDate',
    customComponent: function shipDate({ item }) {
      const time = dayjs(item.expectedShipDate).format('MM-DD-YY hh:mm A');
      return <p>
        {time}
      </p>;
    },
  },
  {
    id: 4,
    title: 'Shipping',
    key: 'supplierShippingCost',
    editable: true,
    type: 'currency',
    customComponent: function shipping({ item }) {
      return <p>
        {formatCentsToDollars(item.supplierShippingCost)}
      </p>;
    },
  },
];

export const secondColumns = [
  {
    id: 1,
    title: 'Status',
    key: 'orderStatus',
    editable: true,
    type: 'dropdown',
    options: [
      { label: 'Unassigned', value: UNASSIGNED },
      { label: 'Placed', value: PLACED },
      { label: 'Tracked', value: TRACKED },
      // { label: 'Completed', value: COMPLETED },
      // { label: 'Cancelled', value: CANCELLED },
    ],
    customComponent: function statusChip({ item, itemIndex }) {
      const backgroundColor = orderStatusColors(item.orderStatus);

      return (
        <>
          <Grid
            style={{
              backgroundColor,
              padding: 10,
              borderRadius: 10,
              width: 100,
              height: 10,
              marginTop: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            {startCase(lowerCase(item.orderStatus))}
          </Grid>
          {item.issueType && (
            <Grid
              style={{
                backgroundColor: getColor('red', 'main'),
                padding: 10,
                borderRadius: 10,
                minWidth: 100,
                height: 10,
                marginTop: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
              }}
            >
              {startCase(lowerCase(item.issueType))}
            </Grid>
          )}
        </>
      );
    },
  },
  {
    id: 2,
    title: 'Cost',
    key: 'cost',
    customComponent: function CostOfItem({ item: order, itemIndex }): ReactElement {
      const { orderItems, orderTax } = order;

      const totalCost =
        orderItems.every((item) => item.supplierSubTotal)
          ? orderItems.reduce((acc, curr) => acc + curr.supplierSubTotal, 0)
          : 0;
      /**/
      const totalTax = orderTax ?
        orderTax :
        orderItems.every((item) => item.supplierTax)
          ? orderItems.reduce((acc, curr) => acc + curr.supplierTax, 0)
          : 0;

      let shippingCost = order.supplierShippingCost || 0;

      let totalShipping = shippingCost;

      const cost = totalCost + totalShipping + totalTax;

      return (
        <>
          <Grid item container xs={4}>
            <Typography>${formatNumber(cost)}</Typography>
          </Grid>
        </>
      );
    },
  },
  {
    id: 3,
    title: 'Profit',
    key: 'profit',
    customComponent: function Profit({ item: order, itemIndex }) {
      const { orderItems, channelId, orderTax } = order;

      const totalCost =
        orderItems.every((item) => item.supplierSubTotal)
          ? orderItems.reduce((acc, curr) => acc + curr.supplierSubTotal, 0)
          : 0;
      /**/
      const totalTax = orderTax ?
        orderTax :
        orderItems.every((item) => item.supplierTax)
          ? orderItems.reduce((acc, curr) => acc + curr.supplierTax, 0)
          : 0;
      /**/

      let shippingCost = order.supplierShippingCost || 0;

      let totalShipping = shippingCost;

      let totalShipPrice = 0;
      let totalPrice = order.orderItems?.reduce((acc, item) => {
        if (!totalShipPrice) {
          let addedShip = 0;

          addedShip = item?.shippingPrice ? item?.shippingPrice : 0;
          /**/
          if (addedShip) {
            totalShipPrice += addedShip;
          }
        }
        return acc + item.itemPrice;
      }, 0);
      const feeFactor =
        channelId === 1
          ? 0.12
          : channelId === 2
            ? 0.11
            : channelId === 3
              ? 0.13
              : 0.12;
      /**/
      const totalFees = order.channelFee
        ? order.channelFee
        : order?.orderItems?.every((e) => e.itemFee)
          ? order.orderItems.reduce((acc, item) => acc + item.itemFee, 0)
          : Math.floor(totalPrice * feeFactor);
      /**/

      const profit =
        totalCost + totalShipping
          ? totalPrice - totalFees - (totalCost + totalShipping + totalTax)
          : 0;
      /**/
      const profitMargin = (profit / totalPrice) * 100;
      const backgroundColor = orderProfitColors(profitMargin);
      return (
        <>
          <Grid item container xs={4}>
            <Typography>${formatNumber(profit)} </Typography>
            <Grid item xs={12}>
              <Chip style={{ backgroundColor: backgroundColor }} label={`${formatNumber(profitMargin)}%`} />
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    id: 4,
    title: 'Tax',
    key: 'orderTax',
    type: 'currency',
    editable: true,
    customComponent: function tax({ item }) {
      let content = 'N/A';
      if (item.orderTax) content = formatCentsToDollars(item.orderTax);
      return <p>
        {content}
      </p>;
    },
  },
];

export const textColumns = [
  {
    id: 1,
    require: true,
    title: 'Address Line 1',
    key: 'addressLine1',
    list: 'left',
    editable: true,
  },
  {
    id: 2,
    title: 'Address Line 2',
    key: 'addressLine2',
    list: 'left',
    editable: true,
  },
  {
    id: 3,
    require: true,
    title: 'City',
    key: 'city',
    list: 'left',
    editable: true,
  },
  {
    id: 4,
    require: true,
    title: 'State',
    key: 'state',
    list: 'right',
    editable: true,
  },
  {
    id: 5,
    require: true,
    title: 'Zip Code',
    key: 'zipCode',
    list: 'right',
    editable: true,
  },
  {
    id: 6,
    require: true,
    title: 'Country',
    key: 'country',
    list: 'right',
    editable: true,
  },
];

export const buyerInfoColumns = [
  {
    id: 1,
    title: 'Buyer Name:',
    key: 'shippingName',
  },
];

export const orderItemsColumns = [
  {
    id: 1,
    title: 'Title',
    // title: 'Product Name',
    key: 'channelData',
    customComponent: function ProductName(row) {
      return (
        <Grid item container xs={6}>
          <Grid item>{row?.item?.channeldata?.[0].title}</Grid>
        </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'Listing Sku',
    key: 'listingSku',
  },
  {
    id: 3,
    title: 'Tracking Id',
    key: 'supplierTrackingNo',
    editable: true,
    customComponent: function Tracking({ item, order }) {
      let content: any = 'Not Yet Placed';
      if (order?.orderStatus === PLACED) {
        content = 'Not yet Tracked'
      } else if (order?.orderStatus === TRACKED) {
        content = (<>{item?.supplierTrackingNo}</>)
      }
      return <>
        <p>{content}</p>
      </>
    },
  },
  {
    id: 4,
    title: 'Carrier',
    key: 'supplierTrackingCarrier',
    editable: true,
    customComponent: function Tracking({ item, order }) {
      let content: any = 'Not Yet Placed';
      if (order?.orderStatus === PLACED) {
        content = 'Not yet Tracked'
      } else if (order?.orderStatus === TRACKED) {
        content = (<>{item?.supplierTrackingCarrier}</>)
      }
      return <>
        <p>{content}</p>
      </>
    },
  },
  {
    id: 5,
    title: 'Supplier No',
    key: 'supplierOrderNo',
    editable: true,
    customComponent: function SuppNo({ item, order }) {
      let content: any = 'Not Yet Placed';
      if ([PLACED, TRACKED, COMPLETED].includes(order?.orderStatus)) {
        content = (<>{item?.supplierOrderNo} ({item?.name})</>)
      }
      return <>
        <p>{content}</p>
      </>
    },
  },
  {
    id: 6,
    title: 'Subtotal',
    key: 'supplierSubTotal',
    type: 'currency',
    editable: true,
    customComponent: function SuppNo({ item, order }) {
      return <>
        <p>{formatCentsToDollars(item.supplierSubTotal)}</p>
      </>
    },
  },
];
