import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const WolfIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      opacity="0.3"
      fill="currentColor"
    />
    <path
      d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"
      fill="currentColor"
    />
    <path
      d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
      fill="currentColor"
    />
    {/* Wolf ears */}
    <path
      d="M9 6.5L12 4l3 2.5M15 6.5L12 4l-3 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
    />
    {/* Wolf snout */}
    <path
      d="M12 10v2m-2-1h4"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="none"
    />
  </SvgIcon>
);

export default WolfIcon;
