export const formFields = [
  'brand',
  'brandAliases',
  // 'metaData',
];

export const complexFormFields: any[] = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'internalSupplier',
    label: 'Internal',
    type: 'boolean',
  },
  {
    name: 'isThirdParty',
    label: '3rd Party Inventory',
    type: 'boolean',
    // logic: {
    //   col: 'internalSupplier',
    //   func: (value) => value === true
    // },
  },
  {
    name: 'usedSupplier',
    label: 'Used Supplier',
    type: 'boolean',
  },
  {
    name: 'metaData',
    label: 'Meta Data',
    type: 'object',
  },
];
export const disabledKeys = ['url', 'token'];
