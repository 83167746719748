import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';


interface Props {
    mappings: any[];
    data: any[];
}

const PreviewTable = ({mappings, data}: Props) => {
    
    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {mappings.map((item, index) => (
                            <TableCell key={index}>
                                <Typography variant='subtitle1' fontWeight='bold'>
                                {item.databaseColumn}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(0, 5).map((row, i) => (
                        <TableRow key={i}>
                            {mappings.map((item) => (
                                <TableCell key={item.mappedColumn}>
                                    {row[item.mappedColumn || item.mappings]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PreviewTable;