import React from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  useTheme,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PriceMonitoringTable from './PriceMonitoringTable';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';

interface PricingSummary {
  totalProducts: number;
  buyboxWinCount: number;
  averageWinRate: number;
  priceVolatilityScore: number;
}

interface Props {
  user?: any;
}

const fetcher = async (url: string) => {
  try {
    const response = await Api.get(url);
    return response;
  } catch (error) {
    console.error('Failed to fetch pricing summary:', error);
    throw error;
  }
};

const downloadData = async (storeId: string) => {
  try {
    const response = await Api.get(`/api/v1/price-monitoring/${storeId}/download`, { 
      responseType: 'blob',
      headers: {
        'Accept': 'text/csv, application/json'
      }
    });

    // Check if the response is a string (CSV content)
    if (typeof response === 'string') {
      // Create a blob from the CSV string
      const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `price-monitoring-${storeId}-${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      console.log('Download started successfully');
      return;
    }

    // If it's not a string, it might be an error response
    if (response?.error) {
      console.error('Download error:', response.error);
      alert(response.error);
      return;
    }

    // Unexpected response type
    console.error('Unexpected response type:', response);
    alert('Failed to download data. Please try again later.');
  } catch (error) {
    console.error('Failed to download data:', error);
    alert('Failed to download price monitoring data. Please try again later.');
  }
};

const PriceMonitoring = ({ user }: Props): JSX.Element => {
  const theme = useTheme();
  const { storeId = '1' } = useParams<{ storeId?: string }>();

  const { data: summaryResponse, error } = useSWR<{ data: PricingSummary }>(
    `/api/v1/price-monitoring/${storeId}/summary`,
    fetcher,
    {
      refreshInterval: 30000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 5000,
    }
  );

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Error loading data: {error.message || 'Something went wrong'}
        </Typography>
      </Box>
    );
  }

  if (!summaryResponse?.data) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Loading price monitoring data...</Typography>
      </Box>
    );
  }

  const summary = summaryResponse.data;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">
          Price Monitoring Dashboard
        </Typography>
        <Tooltip title="Download Price Data">
          <IconButton 
            onClick={() => downloadData(storeId)}
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Products
              </Typography>
              <Typography variant="h4">
                {summary.totalProducts}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Buybox Win Count
              </Typography>
              <Typography variant="h4">
                {summary.buyboxWinCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Win Rate
              </Typography>
              <Typography variant="h4">
                {summary.averageWinRate}%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Price Volatility Score
              </Typography>
              <Typography variant="h4">
                {summary.priceVolatilityScore}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <PriceMonitoringTable storeId={storeId} />
    </Box>
  );
};

export default PriceMonitoring;
