import { useState, useEffect, useMemo, FormEvent } from 'react';
import { omit, isEmpty } from 'lodash';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { set } from 'lodash';

import useCompanies from '@src/hooks/swr/useCompanies';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { TextField, Select } from '@src/Components/common';
import { Supplier } from '@oneAppCore/types/supplierTypes';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import { formFields } from './constants';
// import { validate } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  botmarg: {
    marginBottom: '10px',
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

const omitFields = [
  'id',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'confirmPassword',
  'roles',
];

function ApiInfo({
  supplier,
  edit,
  setSupplier = (...params) => { console.log('sets', params); },
  resetSupplier = (...params) => { console.log('resets', params); },
}: {
  supplier?: Supplier;
  edit?: Boolean;
  setSupplier?: any;
  resetSupplier?: any;
}) {
  const classes = useStyles();
  const initialFormState = {
    name: '',
    internalSupplier: false,
    usedSupplier: false,
    isThirdParty: true,
    image: null,
  };

  const [form, setForm] = useState<Supplier>(supplier || initialFormState);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (supplier) {
  //     setForm(supplier);
  //   }
  // }, [supplier]);

  const apiType = supplier?.metaData?.api?.type;
  const url = supplier?.metaData?.api?.url;
  const clientId = edit
    ? supplier?.metaData?.api?.auth?.['client_id']
    : supplier?.metaData?.api?.auth?.['client_id']
      .substring(0, 50)
      .replace(/.(?=.{4,}$)/g, '*');
  const clientSecret = edit
    ? supplier?.metaData?.api?.auth?.['client_secret']
    : supplier?.metaData?.api?.auth?.['client_secret']
      .substring(0, 50)
      .replace(/.(?=.{4,}$)/g, '*');
  const email = supplier?.metaData?.api?.auth?.email;
  const token = edit
    ? supplier?.metaData?.api?.auth?.token
    : supplier?.metaData?.api?.auth?.token
      .substring(0, 50)
      .replace(/.(?=.{4,}$)/g, '*');

  const update = (key: string, value: any) => {
    const updatedForm = { ...form };
    const newForm = { ...form };
    if (!newForm.metaData) newForm.metaData = {};
    if (!newForm.metaData.api) newForm.metaData.api = {};
    if (!newForm.metaData.api.auth) newForm.metaData.api.auth = {};
    newForm.metaData.api.auth[key] = value;
    // set(updatedForm, key, value);

    setForm(newForm);
    setSupplier(newForm)
  };

  return (
    <form>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <img
              height={80}
              width={80}
              src={
                form.image
                  ? form.image
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            padding: 10,
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
          xs={12}
        >
          {formFields.map((field, fieldIndex) => {
            if (field.type === 'string') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    defaultValue={form?.metaData?.api?.auth?.[field.key]}
                    style={{ margin: 5 }}
                    onChange={(e) =>
                      update('type', e.currentTarget.value)
                    }
                  />
                </Grid>
              );
            }/*  else if (field.key === 'type') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={apiType}
                    style={{ margin: 5 }}
                  // onChange={(e) =>
                  //   update('metaData.api.type', e.currentTarget.value)
                  // }
                  />
                </Grid>
              );
            } else if (field.key === 'url') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={url}
                    style={{ margin: 5 }}
                  />
                </Grid>
              );
            } else if (field.key === 'client_id') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={clientId}
                    style={{ margin: 5 }}
                  />
                </Grid>
              );
            } else if (field.key === 'client_secret') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={clientSecret}
                    style={{ margin: 5 }}
                  />
                </Grid>
              );
            } else if (field.key === 'email') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={email}
                    style={{ margin: 5 }}
                  />
                </Grid>
              );
            } else if (field.key === 'token') {
              return (
                <Grid key={field.key} xs={6} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={token}
                    style={{ margin: 5 }}
                  />
                </Grid>
              );
            } */
          })}
        </Grid>
        {/* {edit && (
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        )} */}
      </Grid>
    </form>
  );
}

export default ApiInfo;
