import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Tab,
  Tabs,
  Card,
  CardContent,
  Avatar,
  Divider,
  Stack,
  Button,
  TextField,
  IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import CompanyApi from '@oneAppCore/services/apis/Companies';
import { Backdrop } from '@src/Components/common';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import CompanyTransactions from './CompanyTransactions';
import type { Company } from '@oneAppCore/types/companyTypes';
import { useSnackbar } from 'notistack';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  headerCard: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  value: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

function CompanyView() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    street1: '',
    city: '',
    state: '',
    postalCode: ''
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await CompanyApi.getById(parseInt(id, 10));
        setCompany(response);
      } catch (error) {
        console.error('Error fetching company:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  useEffect(() => {
    if (company?.integrationData?.shipFrom) {
      const shipFrom = company.integrationData.shipFrom;
      setFormData({
        email: shipFrom.email || '',
        phone: shipFrom.phone || '',
        name: shipFrom.name || '',
        street1: shipFrom.street1 || '',
        city: shipFrom.city || '',
        state: shipFrom.state || '',
        postalCode: shipFrom.postalCode || ''
      });
    }
  }, [company]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleInputChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSave = async () => {
    if (!company) return;
    
    setIsSaving(true);
    try {
      await CompanyApi.update(company.id, {
        integrationData: {
          ...company.integrationData,
          shipFrom: {
            ...company.integrationData?.shipFrom,
            ...formData
          }
        }
      });

      // Update local state
      setCompany(prev => prev ? {
        ...prev,
        integrationData: {
          ...prev.integrationData,
          shipFrom: {
            ...prev.integrationData?.shipFrom,
            ...formData
          }
        }
      } : null);
      
      setIsEditing(false);
      enqueueSnackbar('Company information updated successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error saving company:', error);
      enqueueSnackbar('Failed to update company information', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return <Backdrop open={loading} />;
  }

  if (!company) {
    return <Typography>Company not found</Typography>;
  }

  return (
    <div className={classes.root}>
      <Card className={classes.headerCard}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar className={classes.avatar}>
                <BusinessTwoToneIcon fontSize="large" />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h3" component="h1">
                {company.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                ID: {company.id}
              </Typography>
            </Grid>
            {!isEditing && 
            
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditTwoToneIcon />}
                onClick={() => setIsEditing(true)}
              >
                Edit Profile
              </Button>
            </Grid>
            }
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Profile" />
          <Tab label="Billing & Transactions" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Company Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Stack spacing={2}>
                  <Box>
                    <Typography className={classes.label}>Company Name</Typography>
                    <Typography className={classes.value}>{company.name}</Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.label}>Email</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.email}
                        onChange={handleInputChange('email')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.email || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography className={classes.label}>Phone</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.phone}
                        onChange={handleInputChange('phone')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.phone || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography className={classes.label}>Contact Person</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.name}
                        onChange={handleInputChange('name')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.name || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Address Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Stack spacing={2}>
                  <Box>
                    <Typography className={classes.label}>Street Address</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.street1}
                        onChange={handleInputChange('street1')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.street1 || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography className={classes.label}>City</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.city}
                        onChange={handleInputChange('city')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.city || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography className={classes.label}>State</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.state}
                        onChange={handleInputChange('state')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.state || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography className={classes.label}>Zip Code</Typography>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={formData.postalCode}
                        onChange={handleInputChange('postalCode')}
                      />
                    ) : (
                      <Typography className={classes.value}>
                        {company?.integrationData?.shipFrom?.postalCode || 'Not provided'}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Card>
          <CardContent>
            <CompanyTransactions companyId={id} />
          </CardContent>
        </Card>
      </TabPanel>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
          {isEditing ? (
            <>
              <Button 
                variant="outlined" 
                onClick={() => setIsEditing(false)} 
                sx={{ mr: 1 }}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </>
          ): (
            <></>
          )}
        </Box>
    </div>
  );
}

export default CompanyView;
