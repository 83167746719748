import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery } from '@mui/system';
import {
  SwipeableDrawer,
  Grid,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Stack,
  Box,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Props } from './types';
import { columns } from './constants';
import useInventoryScanHist from '@src/hooks/swr/Inventory/useInventoryScanHistory';
import dayjs from 'dayjs';
import { numberGrouper } from '@oneAppCore/utils/formatters';
import Pagination from '@mui/material/Pagination';
import { EllipseLoader } from '@src/Components/common/Loaders';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: '600px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: 20,
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

function ViewInventoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
}: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [scanList, setScanList] = useState<any[]>([]);
  const [scanFullList, setScanFullList] = useState<any[]>([]);
  const [fitmentList, setFitmentList] = useState<any[]>([]);
  const [fitmentFullList, setFitmentFullList] = useState<any[]>([]);
  const [logList, setLogList] = useState<any[]>([]);
  const [scanPage, setScanPage] = useState(1);
  const [scanLimit, setScanLimit] = useState(10);
  const [fitmentPage, setFitmentPage] = useState(1);
  const [fitmentLimit, setFitmentLimit] = useState(5);
  const theme = useTheme();

  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles({ mobile });

  const {
    data: { scanned, purchased, fitted, ordered, logged },
  } = useInventoryScanHist(row.id);

  const noTypes = ['Inbound'];
  const noBeforeTypes = ['Override'];
  const qtyMinus = ['Order'];

  useEffect(() => {
    let scanArray: any[] = [];

    scanArray = scanArray.concat(scanned);
    scanArray = scanArray.concat(purchased);
    scanArray = scanArray.concat(ordered);

    setLogList(logged);

    const sortedScans = scanArray
      .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
      .reverse();
    const filteredScans = sortedScans.filter(
      (_, index) =>
        index < scanLimit * scanPage && index >= scanLimit * (scanPage - 1),
    );

    setScanFullList(sortedScans);
    setScanList(filteredScans);

    const sortedFitment = fitted.sort(
      (a, b) =>
        (a.make as string)?.localeCompare(b.make) ||
        (a.model as string)?.localeCompare(b.model),
    );
    const filterFitment = sortedFitment.filter(
      (_, index) =>
        index < fitmentLimit * fitmentPage &&
        index >= fitmentLimit * (fitmentPage - 1),
    );

    setFitmentFullList(sortedFitment);
    setFitmentList(filterFitment);
  }, [scanned, purchased, ordered, fitted, fitmentLimit, scanLimit]);

  useEffect(() => {
    setIsLoading(false);
  }, [scanList, fitmentList]);

  useEffect(() => {
    const filtered = scanFullList.filter(
      (_, index) =>
        index < scanLimit * scanPage && index >= scanLimit * (scanPage - 1),
    );
    setScanList(filtered);
  }, [scanPage]);
  useEffect(() => {
    const filtered = fitmentFullList.filter(
      (_, index) =>
        index < fitmentLimit * fitmentPage &&
        index >= fitmentLimit * (fitmentPage - 1),
    );
    setFitmentList(filtered);
  }, [fitmentPage]);

  const handleClose = () => {
    setIsLoading(true);
    return setDrawerOpen();
  };

  console.log(row, 'row');

  return (
    <>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => handleClose()}
        onOpen={() => null}
        classes={{ paper: classes.drawerPaper }}
        anchor="right"
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 2.5, pt: 1.5 }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <IconButton
                  // onClick={() => setDrawerOpen()}
                  sx={{ mr: '10px', padding: matchesXs ? '0px' : '' }}
                >
                  {theme.direction === 'rtl' ? (
                    <ChevronRight />
                  ) : (
                    <ChevronLeft />
                  )}
                </IconButton>
                <h3> Item Details</h3>
              </Stack>
              {/* <Stack
                direction="row"
                jsutifyContent="flex-end"
                spacing={{ xs: 1, sm: 2.5 }}
              ></Stack> */}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ px: 2.5 }}>
              {columns.map((item, i) => {
                if (item.customComponent) {
                  const divider = (
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  );
                  return (
                    <>
                      <item.customComponent row={row} />
                      {i < columns.length - 1 ? divider : null}
                    </>
                  );
                }
                return (
                  <Grid key={item.key} container style={{ marginTop: 5 }}>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: '0.85rem',
                          color: 'grey',
                          textAlign: 'left',
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: '0.85rem',
                          fontWeight: 'bold',
                          textAlign: 'left',
                        }}
                      >
                        {row[item.key]}
                      </Typography>
                    </Grid>
                    {/* {item.key === 'supplierName' ? (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    ) : null} */}
                  </Grid>
                );
              })}
            </Box>
          </Grid>
          {!isLoading ? (
            <>
              <Grid item xs={12} container>
                {scanList.length && scanFullList.length > scanLimit ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <Pagination
                      shape={'rounded'}
                      variant={'text'}
                      size="small"
                      count={Math.ceil(scanFullList.length / scanLimit)}
                      color="primary"
                      page={scanPage}
                      onChange={(_, page) => setScanPage(page)}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sx={{ px: 2.5 }}>
                  <Divider />
                </Grid>
                <Box sx={{ px: 2.5 }}>
                  <Grid container spacing={2.5} sx={{ display: 'flex' }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fonSize: '0.5rem',
                          fontWeight: 'bold',
                        }}
                      >
                        Item History
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    padding: 2.5,
                    maxHeight: '275px',
                    overflowY: 'scroll',
                    marginTop: 15,
                  }}
                >
                  {scanList.length ? (
                    isLoading ? (
                      <Grid item container xs={12} justifyContent="center">
                        <EllipseLoader dark style={{ display: 'flex' }} />
                      </Grid>
                    ) : (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date (by)</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>From (to)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scanList.map((scanItem, scanIndex) => {
                            let before: any = null;
                            let after: any = scanItem.total;
                            const itemDate = dayjs(scanItem.date);
                            const prevItems = scanFullList.filter(
                              (sc) =>
                                dayjs(sc.date).valueOf() > itemDate.valueOf(),
                            );
                            let prevTotal = prevItems
                              .filter((sc) => qtyMinus.includes(sc.scanType))
                              .map((pi) => pi.quantity)
                              .reduce((prev, curr) => {
                                return prev + Math.abs(curr);
                              }, 0);
                            prevTotal += prevItems
                              .filter((sc) => sc.scanType === 'Scan')
                              .map((pi) => pi.quantity)
                              .reduce((prev, curr) => prev + curr, 0);
                            if (noTypes.includes(scanItem.scanType)) {
                              after = null;
                            } else if (
                              noBeforeTypes.includes(scanItem.scanType)
                            ) {
                              after = scanItem.total;
                            } else if (qtyMinus.includes(scanItem.scanType)) {
                              before = prevTotal;
                              after = prevTotal + scanItem.quantity;
                            } else {
                              before = prevTotal;
                              after = prevTotal + scanItem.quantity;
                            }
                            const beforeAfter =
                              !before && !after ? null : `${before} (${after})`;
                            return (
                              <TableRow
                                key={`scanlist-${scanPage}-${scanIndex}`}
                                style={{
                                  paddingBottom: '4px',
                                  paddingTop: '4px',
                                }}
                              >
                                <TableCell
                                  style={{
                                    paddingBottom: '4px',
                                    paddingTop: '4px',
                                  }}
                                >
                                  {itemDate.format('MMM DD, YY')}
                                  <br />({scanItem.username})
                                </TableCell>
                                <TableCell
                                  style={{
                                    paddingBottom: '4px',
                                    paddingTop: '4px',
                                  }}
                                >
                                  {scanItem.scanType}
                                </TableCell>
                                <TableCell
                                  style={{
                                    paddingBottom: '4px',
                                    paddingTop: '4px',
                                  }}
                                >
                                  {scanItem.quantity}
                                </TableCell>
                                <TableCell
                                  style={{
                                    paddingBottom: '4px',
                                    paddingTop: '4px',
                                  }}
                                >
                                  {beforeAfter}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )
                  ) : (
                    <Typography sx={{ fontSize: '0.85rem', px: 2.5 }}>
                      No History for Item
                    </Typography>
                  )}
                </Grid>
                {/* </Box> */}
              </Grid>
              <Grid item xs={12} container>
                {fitmentList.length && fitmentFullList.length > fitmentLimit ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <Pagination
                      shape={'rounded'}
                      variant={'text'}
                      size="small"
                      count={Math.ceil(fitmentFullList.length / fitmentLimit)}
                      color="primary"
                      page={fitmentPage}
                      onChange={(_, page) => setFitmentPage(page)}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} sx={{ px: 2.5 }}>
                  <Divider />
                </Grid>
                <Box sx={{ px: 2.5 }}>
                  <Grid container spacing={2.5} sx={{ display: 'flex' }}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fonSize: '0.5rem',
                          fontWeight: 'bold',
                        }}
                      >
                        Item Fitment
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    padding: 2.5,
                    maxHeight: '275px',
                    overflowY: 'scroll',
                    marginTop: 15,
                  }}
                >
                  {fitmentList.length ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell>Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fitmentList.map(({ make, model, years }) => {
                          return (
                            <TableRow
                              key={`fitment-${row.id}-${make}-${model}`}
                            >
                              <TableCell>
                                {numberGrouper({ years, formatResult: true })}
                              </TableCell>
                              <TableCell>{make}</TableCell>
                              <TableCell>{model}</TableCell>
                              <TableCell title="This is currently hard coded pending data validation">
                                ATV
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography sx={{ fontSize: '0.85rem', px: 2.5 }}>
                      No Fitment for Item
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </SwipeableDrawer>
    </>
  );
}

export default ViewInventoryDrawer;
