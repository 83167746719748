import { useState, FormEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
  Theme,
  Paper,
  Divider,
  Button,
} from '@mui/material';

import CreateSuppInfo from './CreateSuppInfo';
import ApiInfo from '../AdditionalInfo/AdditionalInfo';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Supplier } from '@oneAppCore/types/supplierTypes';
import { enqueueSnackbar } from 'notistack';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';

function tabProps(index) {
  return {
    id: `tab-${index}`,
  };
}

const initialFormState = {
  name: '',
  internalSupplier: false,
  usedSupplier: false,
  isThirdParty: false,
  image: null,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    // backgroundColor: '#ffffff',
    borderRadius: 5,
    // padding: 15,
  },
  rootPadH: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  rootPadTabs: {
    // paddingTop: 15,
    paddingLeft: 15,
  },
  rootPadTabsInner: {
    paddingTop: 15,
    // paddingLeft: 15,
  },
  rootPadContent: {
    paddingTop: 15,
    paddingRight: 15,
  },
  rootPadFooter: {
    paddingBottom: 15,
    // paddingLeft: 15,
    // paddingRight: 15,
  },
  rootPadT: {
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
  },
  rootPadB: {
    paddingBottom: 15,
    paddingRight: 15,
    paddingLeft: 15,
  },
  rootPadA: {
    padding: 15,
  },
}));

function SupplierCreate({ supplier }) {
  const { pathname: location } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [edit, setEdit] = useState<boolean>(location.includes('create') || location.includes('edit'));
  const [form, setForm] = useState<Supplier>(supplier || initialFormState);

  const resetForm = () => {
    if (supplier) {
      setForm(supplier);
    } else {
      setForm(initialFormState);
    }
  }

  const handleChange = (e, tabValue) => {
    setTab(tabValue);
  };

  const onSubmit = (id: number | string) => {
    setForm({});
    resetForm();
    history.push(`/suppliers/list`);
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const { id } = await Api.post(`/api/v1/suppliers/create`, form);
      enqueueSnackbar(`Created Supplier!`, {
        variant: 'success',
      });
      await onSubmit(id);
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Paper style={{ width: '100%' }}>
        <form onSubmit={submit}>
          <Grid container xs={12} className={classes.root}>
            <Grid item container xs={3} justifyContent={'flex-start'} className={classes.rootPadTabs}>
              <Tabs
                value={tab}
                onChange={handleChange}
                orientation="vertical"
                style={{ width: '90%', justifyContent: 'flex-start' }}
                className={classes.rootPadTabsInner}
              >
                <Tab
                  style={{ color: '#000000' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <InfoIcon color="secondary" fontSize="small" />
                      <span style={{ marginLeft: 10 }}>Information</span>
                    </div>
                  }
                  {...tabProps(0)}
                />
                <Tab
                  style={{ color: '#000000' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <CheckCircleIcon color="secondary" fontSize="small" />
                      <span style={{ marginLeft: 10 }}>API/FTP Information</span>
                    </div>
                  }
                  {...tabProps(1)}
                />
              </Tabs>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item container xs={9} className={classes.rootPadContent}>
              {tab === 0 ? (
                <CreateSuppInfo edit={edit} supplier={form} setSupplier={setForm} resetSupplier={resetForm} />
              ) : (
                <ApiInfo edit={edit} supplier={form} setSupplier={setForm} resetSupplier={resetForm} />
              )}
            </Grid>
            <Grid item xs={12} justifyContent={'center'} className={classes.rootPadFooter}>
              <Divider orientation='horizontal' style={{ marginBottom: 8 }} />
              {edit && (
                <Grid item container xs={12} justifyContent={'center'} className={classes.rootPadH}>
                  <Grid item container xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default SupplierCreate;
