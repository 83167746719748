import React, { ReactNode } from "react";
import styles from './AuroraBackground.module.css';
import { cn } from "@src/utils/utils";

interface AuroraBackgroundProps {
  children: ReactNode;
  className?: string;
  showRadialGradient?: boolean;
}

export const AuroraBackground = ({ 
  children, 
  className,
  showRadialGradient = true 
}: AuroraBackgroundProps) => {
  return (
    <main>
      <div className={cn(styles.auroraBackground, className)}>
        <div className={styles.auroraContainer}>
          <div 
            className={styles.auroraEffect}
            style={{
              maskImage: showRadialGradient ? 'radial-gradient(ellipse at 100% 0%, black 10%, transparent 70%)' : 'none'
            }}
          />
        </div>
        {children}
      </div>
    </main>
  );
};
