import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const columns: any[] = [
  {
    title: 'Product',
    key: 'name',
    customComponent: function Name({ row }): ReactElement {
      console.log(row, 'inv row');
      const nameElements = (
        <>
          {row.name}
          {row.variation !== null ? ` ${row.variation}` : null}
        </>
      );
      return (
        <Grid container xs={12}>
          <Grid container spacing={2.5} sx={{ display: 'flex' }}>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {nameElements}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Product
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {row.daysSinceLastSold}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Days Since Last Sold
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        // <Grid container xs={12}>
        //   <Grid item xs={6}>
        //     <Typography
        //       style={{ color: 'grey', textAlign: 'left', fontSize: '0.85' }}
        //     >
        //       Product
        //     </Typography>
        //   </Grid>
        //   <Grid item xs={6}>
        //     <Typography
        //       style={{
        //         fontWeight: 'bold',
        //         textAlign: 'left',
        //         fontSize: '0.85rem',
        //       }}
        //     >
        //       {nameElements}
        //     </Typography>
        //   </Grid>
        // </Grid>
      );
    },
  },
  // {
  //   title: 'Supplier',
  //   key: 'supplierName',
  // },
  {
    title: 'Cost',
    key: 'cost',
    customComponent: function Cost({ row }) {
      return (
        <Grid container xs={12}>
          <Grid container spacing={2.5} sx={{ display: 'flex' }}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  textAlign: 'left',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Supplier Information
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {row.supplierName}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Supplier
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >{`${formatCentsToDollarsIntl(row.cost)}`}</Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Cost
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >{`${formatCentsToDollarsIntl(row.shipping)}`}</Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Shipping
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >{`${formatCentsToDollarsIntl(
                row.estimatedShipping,
              )}`}</Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Estimated
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        // <Grid container xs={12}>
        //   <Grid item xs={6}>
        //     <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
        //       Cost
        //     </Typography>
        //   </Grid>
        //   <Grid item xs={6}>
        //     <Typography style={{ fontSize: '1.15rem' }}>
        //       {formatCentsToDollarsIntl(row.cost)}
        //     </Typography>
        //   </Grid>
        // </Grid>
      );
    },
  },
  // {
  //   title: 'Shipping',
  //   key: 'shippng',
  //   customComponent: function Shipping({ row }) {
  //     return (
  //       <Grid container xs={12}>
  //         <Grid item xs={6} sm={6}>
  //           <Typography
  //             variant="subtitle1"
  //             style={{
  //               fontWeight: 'bold',
  //               textAlign: 'left',
  //               fontSize: '0.85rem',
  //             }}
  //           >{`${formatCentsToDollarsIntl(row.shipping)}`}</Typography>
  //           <Typography
  //             variant="subtitle2"
  //             style={{
  //               color: 'grey',
  //               textAlign: 'left',
  //               fontSize: '0.85rem',
  //             }}
  //           >
  //             Shipping
  //           </Typography>
  //         </Grid>
  //         <Grid item xs={6} sm={6}>
  //           <Typography
  //             variant="subtitle1"
  //             style={{
  //               fontWeight: 'bold',
  //               textAlign: 'left',
  //               fontSize: '0.85rem',
  //             }}
  //           >{`${formatCentsToDollarsIntl(row.estimatedShipping)}`}</Typography>
  //           <Typography
  //             variant="subtitle2"
  //             style={{
  //               color: 'grey',
  //               textAlign: 'left',
  //               fontSize: '0.85rem',
  //             }}
  //           >
  //             Estimated
  //           </Typography>
  //         </Grid>
  //         {/* // <Grid container xs={12}>
  //       //   <Grid item xs={6}>
  //       //     <Typography style={{ fontWeight: 600, fontSize: '1.15rem' }}>
  //       //       Shipping (Estimated)
  //       //     </Typography>
  //       //   </Grid>
  //       //   <Grid item xs={6}>
  //       //     <Typography style={{ fontSize: '1.15rem' }}>
  //       //       {formatCentsToDollarsIntl(row.shipping)}
  //       //       <br />({formatCentsToDollarsIntl(row.estimatedShipping)})
  //       //     </Typography>
  //       //   </Grid>
  //       // </Grid> */}
  //       </Grid>
  //     );
  //   },
  // },
];
