import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import useSWR from 'swr';
import { removeJwtToken } from '@oneAppCore/services/Jwt';
import mainRoutes from '@src/routes/mainRoutes';

import type { LoggedInUser } from '@oneAppCore/types/userTypes';

const isPublicRoute = (pathname: string) => {
  return mainRoutes.some(route => route.path === pathname && route.isPublic);
};

export default function useMe() {
  return useSWR<LoggedInUser>(`/api/v1/me`, Api.get, {
    onError: () => {
      removeJwtToken();
      const currentPath = window.location.pathname;
      if (!isPublicRoute(currentPath) && currentPath !== '/login') {
        window.location.pathname = '/login';
      }
    },
  });
}
