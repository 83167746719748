import React, { createContext, useContext, useState, useCallback } from 'react';
import { KlausContextType, KlausProviderProps, WalkthroughStep } from './types';

const KlausContext = createContext<KlausContextType | undefined>(undefined);

export const KlausProvider: React.FC<KlausProviderProps> = ({ children, initialSteps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState<WalkthroughStep[]>(initialSteps);

  const openKlaus = useCallback(() => setIsOpen(true), []);
  const closeKlaus = useCallback(() => setIsOpen(false), []);

  const nextStep = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  }, [currentStep, steps.length]);

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  }, [currentStep]);

  const goToStep = useCallback((step: number) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step);
    }
  }, [steps.length]);

  const markStepComplete = useCallback((stepId: string) => {
    setSteps(prevSteps =>
      prevSteps.map(step =>
        step.id === stepId ? { ...step, isCompleted: true } : step
      )
    );
  }, []);

  const value = {
    isOpen,
    currentStep,
    steps,
    openKlaus,
    closeKlaus,
    nextStep,
    previousStep,
    goToStep,
    markStepComplete,
  };

  return (
    <KlausContext.Provider value={value}>
      {children}
    </KlausContext.Provider>
  );
};

export const useKlaus = () => {
  const context = useContext(KlausContext);
  if (context === undefined) {
    throw new Error('useKlaus must be used within a KlausProvider');
  }
  return context;
};
