import { startCase, lowerCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Grid, Tooltip, IconButton, Chip, Typography } from '@mui/material';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { internalSuppliers } from '@oneAppCore/constants/suppliers';
import numberUtils from '@oneAppCore/utils/numbers';
import supplierLinkUtils from '@oneAppCore/utils/links/suppliers';
import type { Column } from '@src/Components/common/containers/SearchView/types';
import { dateFormat } from '@oneAppCore/constants/dates';
import ViewOrderDrawerButton from '../ViewOrderDrawerButton';
import InternalButton from '../InternalOrderButton';
import formatters from '@oneAppCore/utils/formatters';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { getItemImageUrl } from '../utils';
import { orderProfitColors, orderStatusColors } from '@src/utils/orders';
import { convertDollarsToCents, formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { COMPLETED, PENDING } from '@oneAppCore/constants/orders';

export const columns = (suppliers, classes): Column[] => [
  {
    title: 'Placed At',
    keyName: 'purchaseDate',
    sortBy: true,
    align: 'center',
    customComponent: function purchaseDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.purchaseDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    keyName: 'expectedShipDate',
    title: 'Ship By',
    sortBy: true,
    align: 'center',
    customComponent: function expectedShipDate(row) {
      return (
        <div style={{ minWidth: '120px' }}>
          {dayjs(row.expectedShipDate).format(dateFormat)}
        </div>
      );
    },
  },
  {
    title: 'Order Number',
    keyName: 'orderNumber',
    sortBy: true,
    customComponent: function OrderNumberDisplay(row) {
      const backgroundColor = orderStatusColors(row.orderStatus);
      return (
        <Grid
          container
          item
          xs={12}
          key={row.id}
          style={{
            minWidth: '160px',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            style={{ justifyContent: 'center', textAlign: 'center' }}
          >
            # {row.orderNumber}
          </Grid>
          <Grid item xs={6}>
            <Chip
              style={{
                backgroundColor: backgroundColor,
                color: '#ffffff',
                width: 'fit-content',
                height: '100%',
              }}
              label={startCase(lowerCase(row.orderStatus))}
            />
          </Grid>
          <br />
          {row.issueType && (
            <Grid item xs={12}>
              <Chip
                style={{
                  backgroundColor: 'crimson',
                  color: '#ffffff',
                  width: 'fit-content',
                  height: '100%',
                }}
                label={startCase(lowerCase(row.issueType))}
              />
            </Grid>
          )}
        </Grid>
      );
    },
  },
  {
    title: 'Supplier Order #',
    keyName: 'supplierOrderNo',
    align: 'center',
    customComponent: function SupplierOrderNumbers(row) {
      const holder = {};
      if (!row?.orderItems?.[0]) return <div key={row.id} />;
      row.orderItems.forEach((item) => {
        if (item.supplierOrderNo) {
          if (!holder[item.supplierOrderNo]) {
            holder[item.supplierOrderNo] = item.supplierId;
          }
        } else {
          holder['null'] = item.supplierId;
        }
      });
      const supplierOrderNumbers = Object.keys(holder).map((item, index) => {
        let ret;
        if (!internalSuppliers.includes(holder[item])) {
          if (item === 'null') {
            ret = 'No Supplier Order #';
          } else {
            const link = supplierLinkUtils.trackingLink(
              suppliers,
              { supplierOrderNo: item, supplierId: holder[item] },
              row,
            );
            if (!link) {
              ret = 'Item not yet supported';
            } else {
              ret = (
                <a
                  key={`${row.id}-${index}`}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item}
                </a>
              );
            }
          }
        } else {
          ret = `${item !== 'null' ? item : 'Internal'} Item`;
        }
        return ret;
      });
      return (
        <div key={row.id}>
          {supplierOrderNumbers.map((a) => (
            <>
              {a}
              <br />
            </>
          ))}
        </div>
      );
    },
  },

  {
    keyName: 'channel',
    title: 'Store & Channel',
    sortBy: true,
    align: 'center',
    customComponent: function StoreChannels(row) {
      return (
        <Grid
          container
          item
          xs={12}
          justifyContent="space-around"
          style={{ alignItems: 'center' }}
        >
          <Grid item xs={4}>
            <Typography style={{ textTransform: 'uppercase' }}>
              {row.store}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    keyName: 'price',
    title: 'Order Total',
    align: 'center',
    customComponent: function OrderNumberDisplay(row) {
      const { orderItems, price, channelId, orderNumber } = row;
      const centsPrice = convertDollarsToCents(price);

      const totalItemCost = orderItems?.reduce((acc, curr) => acc + curr.supplierSubTotal, 0);
      let supplierItemShipping = 0;
      const totalItemShipping = orderItems?.reduce((acc, curr) => {
        const { allSuppliersArray, supplierId } = curr;
        const suppIndex = allSuppliersArray?.[0]?.sid.indexOf(supplierId) ?? -1;
        // const foundSupplier = allSuppliersArray.find((s) => s.sid.includes(supplierId));

        if (suppIndex > -1) {
          if (allSuppliersArray[0].estimatedShipping[suppIndex]) {
            supplierItemShipping += allSuppliersArray[0].estimatedShipping[suppIndex];
          } else if (allSuppliersArray[0].shippingCost[suppIndex]) {
            supplierItemShipping += allSuppliersArray[0].shippingCost[suppIndex];
          }
        }
        return acc + curr.supplierShipping;
      }, 0);
      const shippingCost = row.supplierShippingCost || 0;
      const totalShipping =
        shippingCost
          ? shippingCost
          : totalItemShipping
            ? totalItemShipping
            : supplierItemShipping
              ? supplierItemShipping
              : 0;
      const totalCost = totalItemCost + totalShipping;
      /**/
      let totalShipPrice = 0;
      let totalPrice = orderItems?.reduce((acc, item) => {
        totalShipPrice += item?.shippingPrice || 0;
        return acc + item.itemPrice;
      }, 0);
      const feeFactor =
        channelId === 1
          ? 0.12
          : channelId === 2
            ? 0.11
            : channelId === 3
              ? 0.13
              : 0.12;
      /**/
      const totalFees = row.channelFee
        ? row.channelFee
        : orderItems?.every((e) => e.itemFee)
          ? orderItems.reduce((acc, item) => acc + item.itemFee, 0)
          : Math.floor(totalPrice * feeFactor);
      totalPrice += totalShipPrice;

      const profit = totalCost ? (centsPrice - totalFees) - (totalCost) : 0;
      const backgroundColor = (() => {
        const colorsAndTints: any[] = [
          { tint: 'main', color: 'red' },
          { tint: 'main', color: 'orange' },
          { tint: 'main', color: 'green' },
        ];
        return orderProfitColors(profit, [0, 5], colorsAndTints);
      })();

      return (
        <>
          <Grid
            container
            item
            xs={12}
            key={row.id}
            style={{ minWidth: '170px', justifyContent: 'center' }}
          >
            {' '}
            <Grid item xs={12}>
              {formatCentsToDollarsIntl(centsPrice || 0)}
            </Grid>
            <br />
            <Grid item xs={5}>
              <Chip
                style={{
                  backgroundColor: backgroundColor,
                  color: 'white',
                  width: '100%',
                  height: '100%',
                }}
                label={`${((profit / centsPrice) * 100).toFixed(1)}%`}
              />
            </Grid>
          </Grid>
        </>
      );
    },
  },
  {
    keyName: 'quantity',
    title: 'QTY',
    sortBy: true,
    customComponent: function Quantity(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          item
          xs={1}
          direction="column"
          style={{
            maxWidth: '600px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => {
            const colorClass = item.quantity > 1 ? classes.red : classes.black;
            return (
              <Grid item container key={item.id} alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <div
                      className={formatters.classNames([
                        classes.quantity,
                        colorClass,
                      ])}
                      style={{ width: 30, height: 30 }}
                    >
                      <p style={{ fontSize: '18px' }}>{item?.quantity}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    keyName: 'items',
    title: 'Items',
    sortBy: true,
    // align: 'center',
    customComponent: function OrderItemsDisplay(row) {
      if (!row?.orderItems?.[0]) return;
      return (
        <Grid
          container
          item
          xs={10}
          spacing={1}
          direction="column"
          style={{
            width: '450px',
            justifyContent: 'flex-end',
          }}
        >
          {row.orderItems?.map((item) => {
            const singleItem = (itm) => (
            <>
            <Grid item xs={12}>
              <span style={{ lineHeight: 0.5 }}>SKU: </span>
              <span className={classes.bold}>{itm.sku}</span>
              {' | '}
              <span style={{ lineHeight: 0.5 }}>MPN: </span>
              <span className={classes.bold}>{itm.manufacturerNo}</span>
            </Grid>
            <Grid item xs={12}>
              <span style={{ lineHeight: 0.5 }}>Location: </span>
              {itm.location ? (
                <span className={classes.bold}>{itm.location}</span>
              ) : (
                <span className={classes.bold}> N/A </span>
              )}
            </Grid>
            </>);
            const varList = item?.productGroupVars?.map((kit) => kit?.[0]).filter((a) => a);
            const msList = item?.productGroupMansup?.map((loc) => loc?.[0]).filter((a) => a);
            const varItem = (varList.map((vari) => {
              const foundLoc = msList?.find((loc) => loc.variationId === vari.id && loc.supplierId === item.supplierId);
              return (singleItem({...vari, location: foundLoc?.location}))}));
            return (
              <Grid item container key={item.id} alignItems="center">
                <Grid
                  item
                  container
                  xs={4}
                  direction="column"
                  alignItems="center"
                  justifyContent="flex-end"
                  wrap="nowrap"
                >
                  <Grid item>
                    <>
                      <img
                        height={50}
                        width={50}
                        src={getItemImageUrl(item)}
                        alt="Product"
                      />
                    </>
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={8}>
                  <Grid
                    item xs={12}
                    style={{
                      textOverflow: 'ellipsis',
                      display: 'block',
                      maxWidth: '300px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      lineHeight: 1,
                      fontWeight: 'bold',
                      fontSize: 'small',
                    }}
                    title={item?.channelData?.title}
                  >
                    {item?.channelData?.title}
                  </Grid>
                  {varList.length ? varItem : singleItem(item)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  },
  {
    title: 'Actions',
    keyName: 'actions',
    customComponent: function ActionButtons(row) {
      const history = useHistory();
      const { id } = row;
      return (
        <>
          <Grid
            container
            item
            xs={12}
            direction="row"
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 5,
              paddingLeft: 15,
            }}
          >
            <Grid item xs={3} direction="column">
              <ViewOrderDrawerButton id={row.id} row={row} />
            </Grid>
            <Grid item xs={3} direction="column">
              <InternalButton row={row} />
            </Grid>
            <Grid
              item
              container
              xs={3}
              direction="column"
              style={{ marginRight: '10px' }}
            >
              <Tooltip title="Edit Order">
                <span>
                  <IconButton
                    style={{ fontSize: '10px' }}
                    disabled={[COMPLETED, PENDING].includes(row.orderStatus)}
                    onClick={() => history.push(`/orders/view/${id}?edit=true`)}
                  >
                    <EditTwoToneIcon color="primary" />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      );
    },
  },
];
