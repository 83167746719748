import BaseApi from '../../BaseApi';

import { BrandForEdit } from 'types/brandTypes';
import type { Search } from 'types/searchTypes';

interface SearchResponse {
  rows: BrandForEdit[];
  count: number;
}

class CompanyApi extends BaseApi {
  constructor() {
    super();
  }

  getById(id: number) {
    return this.getBase(`/api/v1/companies/${id}`);
  }

  getTransactions(companyId: number) {
    return this.getBase(`/api/v1/companies/${companyId}/transactions`);
  }

  createTransaction(companyId: number, data: {
    paymentType: string;
    amount: number;
    paymentMethod: string;
    transactionDate: string;
  }) {
    return this.postBase(`/api/v1/companies/${companyId}/transactions`, data);
  }

  update(id: number, data: { name?: string, integrationData?: any }) {
    return this.putBase(`/api/v1/companies/${id}`, data);
  }

  cancelTransaction(companyId: number, transactionId: number) {
    return this.deleteBase(`/api/v1/companies/${companyId}/transactions/${transactionId}`);
  }

  // put(id: number, payload: BrandForEdit) {
  //   return this.putBase<BrandForEdit>(
  //     `/api/v1/brands/${id}`,
  //     payload,
  //   );
  // }

  // post(payload: BrandForEdit) {
  //   return this.postBase<BrandForEdit>(
  //     `/api/v1/brands`,
  //     payload,
  //   );
  // }

  getAll() {
    return this.getBase<any>(`/api/v1/companies`);
  }
  // getById(id: number) {
  //   return this.getBase<BrandForEdit>(`/api/v1/brands/${id}`);
  // }

  // search(payload: Partial<Search>) {
  //   return this.getBase<SearchResponse>(`/api/v1/brands/search`, payload);
  // }
}

export default CompanyApi;
