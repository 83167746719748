import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Collapse } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RecoilRoot } from 'recoil';
import Theme from './Components/Theme';
import Routes from './routes';
import { KlausProvider, Klaus } from './Components/Klaus';
import { defaultSteps } from './Components/Klaus/defaultSteps';
import { SplashScreenProvider } from './context/SplashScreenContext';
import { SplashScreenWrapper } from './Components/Onboarding/SplashScreenWrapper';
import './styles/globals.css';

function App() {
  return (
    <Theme>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RecoilRoot>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3} TransitionComponent={Collapse}>
              <SplashScreenProvider>
                <KlausProvider initialSteps={defaultSteps}>
                  <SplashScreenWrapper>
                    <Routes />
                    <Klaus
                      onComplete={() => {
                        console.log('Walkthrough completed!');
                        // You can add additional completion actions here
                      }}
                    />
                  </SplashScreenWrapper>
                </KlausProvider>
              </SplashScreenProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </RecoilRoot>
      </LocalizationProvider>
    </Theme>
  );
}

export default App;
