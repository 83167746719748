import Grid from '@mui/material/Grid';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Link,
  Typography,
} from '@mui/material';
import FontAwesome from '@src/Components/common/FontAwesome';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  SHOPIFY,
  SHOPIFY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import dayjs from 'dayjs';
import { TIME_ZONES } from '@oneAppCore/constants/dates';

export const columns = [
  {
    id: 1,
    title: 'Listing SKU',
    key: 'channelLink',
    customComponent: function channelLink(row) {
      let href = '';
      if (row?.channelName === AMAZON) {
        href = AMAZON_ASIN_LINK(row?.channelProductId);
      } else if (row?.channelName === WALMART) {
        href = WALMART_PRODUCT_LINK(row?.channelProductId);
      } else if (row?.channelName === EBAY) {
        href = EBAY_PRODUCT_LINK(row?.channelProductId);
      } else if (row?.channelId === channelIds[SHOPIFY]) {
        href = SHOPIFY_PRODUCT_LINK(row?.storeChannel?.metaData?.domain, row?.channelProductId);
      }

      return (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <img
            width={35}
            height={35}
            style={{ borderRadius: 10 }}
            src={
              row.icon !== null
                ? row.icon
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
          {href !== '' && (
            <Link href={href} target="_blank">
              <FontAwesome name="external-link-alt" type="fa" form="fa" />
            </Link>
          )}
        </Grid>
      );
    },
  },
  {
    id: 2,
    title: 'MPN',
    key: 'manufacturerNo',
  },
  {
    id: 3,
    title: 'Listing SKU',
    key: 'listingSku',
  },
  {
    id: 4,
    title: 'Listing Price',
    key: 'price',
  },
  {
    id: 5,
    title: 'Changes',
    key: 'changes',
    customComponent: function changes(row) {
      const changes = row.changesArray || [];
      const length = changes.length;
      const ignoreColumns = ['changeId', 'newDisplayPrice'];
      let count = 0;
      if (length > 0) {
        changes.forEach((c) => {
          const { newValues } = c;
          const colLen = Object.keys(newValues).filter((col) => !ignoreColumns.includes(col)).length;
          if (colLen > 0) {
            count += colLen;
          } else {
            count += 1;
          }
        })
      }
      const styleObj: any = {
        maxHeight: '220px !important',
        overflowY: count > 2 ? 'scroll !important' : 'hidden !important',
        marginTop: 10
      };
      const headerStyleObj: any = {
        position: 'sticky',
        top: 0,
      };
      return (
        <Grid container item >
          <Typography variant='h6'>Changes</Typography>
          {/* <TableContainer> */}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerStyleObj}>Made (By)</TableCell>
                <TableCell style={headerStyleObj}>Change (Status)</TableCell>
                <TableCell style={headerStyleObj}>From (To)</TableCell>
              </TableRow>
            </TableHead>
            {changes?.map((change, cdx) => {
              const {
                createdAt, createdName,
                oldValues, newValues,
                status,
              } = change;
              const dataColumns = Object.keys(newValues).sort().filter((col) => !ignoreColumns.includes(col));
              return (
                dataColumns.map((col, idx) => (
                  <TableRow key={`row-${cdx}-${col}`}>
                    <TableCell>{dayjs(createdAt).tz(TIME_ZONES.MOUNTAIN).format('YYYY-MM-DD')}<br />({createdName})</TableCell>
                    <TableCell>{col.toUpperCase()}<br />({status})</TableCell>
                    <TableCell>
                      {formatCentsToDollars(newValues[col])}<br />({formatCentsToDollars(oldValues[col])})
                    </TableCell>
                  </TableRow>
                ))
              )
            })}
          </Table>
          {/* </TableContainer> */}
        </Grid>
      )
    },
  },
  {
    id: 5,
    title: 'Suppliers',
    key: 'suppliers',
    customComponent: function suppliers(row) {
      const { supplierArray: suppliers } = row;

      return (
        <TableContainer style={{ marginTop: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Supplier</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Ship Act. / Est. </TableCell>
              </TableRow>
            </TableHead>
            {suppliers &&
              suppliers?.[0] &&
              suppliers.map((supplier, index) => (
                <TableRow key={index}>
                  <TableCell>{supplier.name}</TableCell>
                  <TableCell>{formatCentsToDollars(supplier.cost)}</TableCell>
                  <TableCell>
                    {formatCentsToDollars(supplier.shippingCost)}
                    {' / '}
                    {formatCentsToDollars(supplier.estimatedShipping)}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </TableContainer>
      );
    },
  },
];
