import { useState, useEffect, useMemo, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { omit, isEmpty } from 'lodash';
import {
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import useCompanies from '@src/hooks/swr/useCompanies';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { TextField, Select } from '@src/Components/common';
import { Supplier } from '@oneAppCore/types/supplierTypes';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import FileUpload from '@src/Components/common/buttons/FileUpload';

import { formFields } from '../../SupplierInfo/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  botmarg: {
    marginBottom: '10px',
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

const omitFields = [
  'id',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'confirmPassword',
  'roles',
];

function CreateSuppInfo({
  supplier,
  edit,
  setSupplier = (...params) => { console.log('sets', params); },
  resetSupplier = (...params) => { console.log('resets', params); },
}: {
  supplier?: Supplier;
  edit?: Boolean;
  setSupplier: any;
  resetSupplier: any;
}) {
  const classes = useStyles();
  const history = useHistory();

  const initialFormState = {
    name: '',
    internalSupplier: false,
    usedSupplier: false,
    isThirdParty: false,
    image: null,
  };

  const [form, setForm] = useState<Supplier>(supplier || initialFormState);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  const update = (key: string, value: string | boolean) => {
    setForm({
      ...form,
      [key]: value,
    });
    setSupplier({
      ...form,
      [key]: value,
    });
  };

  const updateBulk = (input: any) => {
    setForm({
      ...form,
      ...input,
    });
  };

  const onSubmit = (id: number | string) => {
    setForm({});
    resetSupplier();
    history.push(`/suppliers/list`);
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const { id } = await Api.post(`/api/v1/suppliers/create`, form);
      enqueueSnackbar(`Created Supplier!`, {
        variant: 'success',
      });
      await onSubmit(id);
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    }
  };

  const updateImage = (url: string) => {
    const updatedForm = omit(
      {
        ...form,
        image: url,
      },
      [
        'id',
        'createdBy',
        'updatedBy',
        'deletedBy',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'confirmPassword',
        'roles',
      ],
    );

    localStorage.setItem(`supplierImage`, JSON.stringify(updatedForm));

    setForm((prevForm) => ({
      ...prevForm,
      image: url,
    }));

    enqueueSnackbar('Updated Profile Photo!', { variant: 'success' });
  };

  console.log(form, 'supp form');

  return (
    <form onSubmit={submit}>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item container xs={12} alignItems="flex-end">
          <Grid item xs={1} style={{ marginRight: 30 }}>
            <img
              height={100}
              width={100}
              src={
                form?.image
                  ? form?.image
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: 15 }}>
            <FileUpload
              id="supplierUpload"
              onUpload={(urls: string[]) => {
                if (urls.length > 0) {
                  updateImage(urls[0]);
                }
              }}
              buttonText="Upload Image"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            padding: 10,
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
          xs={12}
        >
          {formFields.filter((field) => field.create || field.required).map((field, fieldIndex) => {
            if (field.key === 'isThirdParty') {
              const element = (<Grid key={field.key} xs={12} md={3}>
                <FormControlLabel
                  label={field.label}
                  control={
                    <Switch
                      checked={form?.[field.key] || false}
                      onChange={(e) => update(field.key, e.target.checked)}
                    />
                  }
                />
              </Grid>
              );
              return (element);
            } else if (field.type === 'boolean') {
              return (
                <Grid key={field.key} xs={12} md={3}>
                  <FormControlLabel
                    label={field.label}
                    control={
                      <Switch
                        checked={form?.[field.key] || false}
                        onChange={(e) => update(field.key, e.target.checked)}
                      />
                    }
                  />
                </Grid>
              );
            } else if (field.type === 'string') {
              return (
                <Grid key={field.key} xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={form?.[field.key]}
                    style={{ width: '50%' }}
                    onChange={(e) => update(field.key, e.target.value)}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
        {/* {edit && (
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        )} */}
      </Grid>
    </form>
  );
}

export default CreateSuppInfo;
