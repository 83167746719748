import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Typography, Tooltip, MenuItem, Select } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
  }),
);

const infoRows = [
  {
    keyName: 'dls30',
    title: 'Aging',
    type: 'number',
    column: 1,
  },
  {
    keyName: 'dlsAvg',
    title: 'Avg. Days Aged',
    type: 'number',
    column: 1,
  },
  {
    keyName: 'isTotal',
    title: 'In Stock Total',
    type: 'number',
    column: 1,
  },
  {
    keyName: 'oosTotal',
    title: 'OOS Total',
    type: 'number',
    column: 2,
  },
  {
    keyName: 'lowStock',
    title: 'Low Stock',
    type: 'number',
    column: 2,
  },
];

function InvInfo(props: any) {
  const classes = useStyles();
  const [days, setDays] = useState(30);

  const handleChange = (e) => {
    setDays(e.target.value);
  };

  return (
    <SearchViewMiniReport>
      <Tooltip placement="top" title="Inventory Information Breakdown">
        <Grid container xs={12}>
          <Grid item container xs={6} direction="column">
            {infoRows
              .filter(({ column }) => column === 1)
              .map(({ title, keyName, type }) => {
                let value = props?.[keyName];
                if (keyName === 'dls30') {
                  const dynamicKey = `dls${days}`;
                  const dynamicValue = props?.[dynamicKey] || 0;
                  return (
                    <Grid
                      item
                      container
                      spacing={1}
                      sx={{ alignItems: 'center' }}
                      key={`${keyName}-totals`}
                    >
                      <Grid item>
                        {/* <Typography variant="body1"> */}
                        <Select
                          variant="standard"
                          value={days}
                          onChange={handleChange}
                        >
                          <MenuItem value={30}>Aged 30 Days</MenuItem>
                          <MenuItem value={60}>Aged 60 Days</MenuItem>
                          <MenuItem value={90}>Aged 90 Days</MenuItem>
                          <MenuItem value={120}>Aged 120+ Days</MenuItem>
                        </Select>
                        {/* // </Typography> */}
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {Number(dynamicValue).toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid item container spacing={1} key={`${keyName}-totals`}>
                    <Grid item>
                      <Typography variant="body1">{title}:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {Number(value || 0).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item container xs={6} direction="column">
            {infoRows
              .filter(({ column }) => column === 2)
              .map(({ title, keyName, type }) => {
                let value = props?.[keyName];
                return (
                  <Grid item container spacing={1} key={`${keyName}-totals`}>
                    <Grid item>
                      <Typography variant="body1">{title}:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {Number(value || 0).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default InvInfo;
