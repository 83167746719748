import { useState, useEffect, useMemo, FormEvent } from 'react';
import { omit, isEmpty } from 'lodash';
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import useCompanies from '@src/hooks/swr/useCompanies';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { TextField, Select } from '@src/Components/common';
import { Supplier } from '@oneAppCore/types/supplierTypes';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import FileUpload from '@src/Components/common/buttons/FileUpload';

import { formFields } from './constants';
// import { validate } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  botmarg: {
    marginBottom: '10px',
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

const omitFields = [
  'id',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'confirmPassword',
  'roles',
];

function SupplierInfo({ supplier, edit }: { supplier?: any; edit?: Boolean }) {
  const classes = useStyles();

  const [form, setForm] = useState<Supplier>(supplier);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    // Update form state when the supplier prop changes
    if (supplier) {
      setForm(supplier);
    }
  }, [supplier]);

  const update = (key: string, value: string | boolean) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // const error = validate(form);
    // setErrors(error);
    // if(!isEmpty(error)) {
    //   return;
    // }
    try {
      const payload = omit(form, omitFields);
      if (form.id) {
        await Api.put(`/api/v1/suppliers/${form.id}`, payload);
      } else {
        await Api.post(`/api/v1/suppliers`, payload);
      }
      enqueueSnackbar('Saved!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const updateImage = async (url: string) => {
    const id = form.id;

    const updatedForm = omit(
      {
        ...form,
        image: url,
      },
      [
        'id',
        'createdBy',
        'updatedBy',
        'deletedBy',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'confirmPassword',
        'roles',
      ],
    );
    try {
      await Api.put(`/api/v1/suppliers/${id}`, updatedForm);
      setModal(false);
      enqueueSnackbar('Updated Profile Photo!', { variant: 'success' });

      setForm((prevForm) => ({
        ...prevForm,
        image: url,
      }));
    } catch (e) {
      setModal(false);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  console.log(formFields, 'supp form');

  return (
    <form onSubmit={submit}>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item container xs={12} alignItems="flex-end">
          <Grid item xs={1} style={{ marginRight: 30 }}>
            <img
              height={80}
              width={80}
              // style={{ borderRadius: '50%' }}
              src={
                form?.image
                  ? form?.image
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: 15 }}>
            <FileUpload
              id="supplierUpload"
              onUpload={(urls: string[]) => {
                if (urls.length > 0) {
                  updateImage(urls[0]);
                }
              }}
              buttonText="Upload Image"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          style={{
            padding: 10,
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
          xs={12}
        >
          {formFields.map((field, fieldIndex) => {
            console.log(field.key);
            if (field.key === 'name') {
              return (
                <Grid key={field.key} xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    variant="outlined"
                    label={field.label}
                    value={form?.name}
                    style={{ width: '50%' }}
                    onChange={(e) => update(field.key, e.target.value)}
                  />
                </Grid>
              );
            } else if (field.key === 'internalSupplier') {
              return (
                <Grid key={field.key} xs={12} md={3}>
                  <FormControlLabel
                    label="Internal Supplier"
                    control={
                      <Switch
                        checked={form?.internalSupplier}
                        onChange={(e) =>
                          update('internalSupplier', e.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
              );
            } else if (field.key === 'isThirdParty') {
              return (
                <Grid key={field.key} xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form?.isThirdParty}
                        onChange={(e) =>
                          update('isThirdParty', e.target.checked)
                        }
                      />
                    }
                    label="Internal Stock"
                  />
                </Grid>
              );
            } else if (field.key === 'usedSupplier') {
              return (
                <Grid key={field.key} xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form?.usedSupplier}
                        onChange={(e) =>
                          update('usedSupplier', e.target.checked)
                        }
                      />
                    }
                    label="Used Supplier"
                  />
                </Grid>
              );
            } else if (field.key === 'addressLineOne') {
              <Grid key={field.key} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  variant="outlined"
                  label={field.label}
                  // value={form?.name}
                  style={{ width: '50%' }}
                  onChange={(e) => update(field.key, e.target.value)}
                />
              </Grid>;
            } else if (field.key === 'addressLine2') {
              <Grid key={field.key} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  variant="outlined"
                  label={field.label}
                  // value={form?.name}
                  style={{ width: '50%' }}
                  onChange={(e) => update(field.key, e.target.value)}
                />
              </Grid>;
            }
          })}
        </Grid>
        {edit && (
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default SupplierInfo;
