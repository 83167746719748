import { useEffect, useState } from 'react';
import TabbedPage from '@oneAppCore/one-app/src/Components/CustomPages/TabbedPage';
import ImportData from './ImportData';
import TableSelect from './TableSelect';
import { Options } from './types';
import { Container, Paper, Box, Stepper, Typography, Step, StepLabel, Button } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import CSVUploader from './CSVUploader';
import ColumnMapper from './ColumnMapper';
import PreviewTable from './PreviewTable';
import { CSVData } from './CSVUploader/types';
import { tableNames } from '@oneAppCore/constants/db';
import { tableList, customImports } from '@oneAppCore/constants/import';
import { useSnackbar } from 'notistack';
import ImportApi from '@oneAppCore/services/apis/Imports';


const customImportOpts: Options = {
  tableList: [
    'Fitment_Search_import',
    'Product_Variation_Mansupp'
  ],
};

interface ColumnMapping {
  csvColumn: string;
  dbColumn: string;
}

interface TableColumnsResponse {
  data: string[];
}


const steps = ['Select Table', 'Upload CSV', 'Map Columns', 'Preview & Import'];

const ImportPage = () => {
  const { enqueueSnackbar } = useSnackbar(); 
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTable, setSelectedTable] = useState<string | null>(null);
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<CSVData[]>([]);
  const [columnMappings, setColumnMappings] = useState<ColumnMapping[]>([])
  // const [mapperColumns, setMapperColumns] = useState();
  const [provider, setProvider] = useState('capone');
  const [fileColumns, setFileColumns] = useState([]);
  const [mapperColumns, setMapperColumns] = useState<string[]>([]);
  const [parsedData, setParsedData] = useState([]);
  const [uploadType, setUploadType] = useState<string>('table');

  // const columnData = ImportApi.getTableColumns(selectedTable);
  // const columnData = await ImportApi.getTableColumns(selectedTable);

  const getColumnNames = async (tableName: string): Promise<string[]> => {
    try {
      const response = (await ImportApi.getTableColumns(tableName)) as TableColumnsResponse;
      if (response?.data) {
        return response.data;
      }
      console.warn('No column data found.');
      return [];
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchColumns = async () => {
      const columnNames = await getColumnNames(selectedTable);
      setMapperColumns(columnNames); 
    };
  
    if (selectedTable) {
      fetchColumns();
    }
  }, [selectedTable]);
  



  const checkForSpecifcTableAndColumn = (
    parsedRow = [],
    col: string = 'debit',
    tableName: string = tableNames.creditCardTransactions
  ) => {
    const hasColumn = parsedRow.includes(col);
    const isTable = selectedTable === tableName;
    return hasColumn && isTable;
  }
  
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleCSVLoaded = (headers: string[], data: CSVData[]) => {
    setCsvHeaders(headers);
    setCsvData(data);
    setColumnMappings(headers.map((header) => ({
      csvColumn: header,
      dbColumn: '',
    })))
    setActiveStep(2);
  }

  const handleMappingChange = (csvColumn: string, dbColumn: string | null) => {
    setColumnMappings((prev) => 
      prev.map((mapping) => 
      mapping.csvColumn === csvColumn ? {...mapping, dbColumn} : mapping)
    )
  }

  const handleTableSelect = (tableName: string) => {
    setSelectedTable(tableName);
    setActiveStep(1);
  };

  const handleCSVSubmit = async (parsedData) => {
      try {
        setParsedData([...parsedData.data]);
        let columnsArray = [];
        let columnsObject = {};
        let fileColumns = [];
        // ! this is the custom logic for the vehicle_fitment table using make, model, and years rather than the db table columns
        if (uploadType.includes('custom')) {
          const columnData: any = { data: customImports[selectedTable] };
          columnData?.data?.forEach((colName, index) => {
            columnsObject[colName.toLowerCase()] = index;
            columnsArray.push({
              databaseColumn: colName,
              mappedColumn: '',
            });
          });
        } else {
          const columnData: any = await ImportApi.getTableColumns(selectedTable);
          const filterColArray: any[] = tableList[selectedTable] || [];
          columnData?.data
            .filter(
              (colName) => !filterColArray.concat(['skip']).includes(colName),
            )
            .map((colName, index) => {
              columnsObject[colName.toLowerCase()] = index;
              columnsArray.push({
                databaseColumn: colName,
                mappedColumn: '',
              });
            });
        }
        Object.keys(parsedData?.data[0]).map((colName) => {
          fileColumns.push(colName);
          const colIndex = columnsObject[colName.toLowerCase()];
          if (typeof colIndex === 'number') {
            columnsArray[colIndex].mappedColumn = colName;
          }
        });
        if (checkForSpecifcTableAndColumn(Object.keys(parsedData?.data[0]))) {
          setProvider('amex');
        }
  
        setMapperColumns(columnsArray);
        setFileColumns(fileColumns);
        handleNext();
      } catch (e) {
        enqueueSnackbar(`Error: ${e?.message || e}`, { variant: 'error' });
      }
    };  

      const handleSubmit = async () => {
        try {
          if (parsedData) {
            let payload: any[] = [];
            if (Array.isArray(parsedData)) {
              parsedData.forEach((parsedDatum) => {
                let mappedObject = {};
                mapperColumns.forEach((elem: any) => {
                  mappedObject[elem.databaseColumn] = parsedDatum[elem.mappedColumn || elem.mappings];
                });
                payload.push(mappedObject);
              });
              await ImportApi.upload(selectedTable,
                uploadType, {
                data: payload,
              });
              
              // Reset all state variables to their initial values
              setSelectedTable('');
              setCsvHeaders([]);
              setFileColumns([]);
              setMapperColumns([]);
              setParsedData([]);
              setColumnMappings([]);
              setUploadType('table');
              setActiveStep(0);
              
              enqueueSnackbar('Data Submitted Successfully', { variant: 'success' });
            }
          }
        } catch (error) {
          enqueueSnackbar(`Error: ${error?.message || error}`, { variant: 'error' });
          console.error('Error submitting data:', error);
        }
      };
  // const tabs: any[] = [
  //   {title: 'Import', child: <ImportData title={'Import'} options={customImportOpts} key={`import-1`} />},
  //   {title: 'Advanced', child: <ImportData title={'Import'} key={`import-0`} />},
  // ];
  const tabs: any[] = [
    {title: 'Import', child: <TableSelect title={'Import'} options={customImportOpts} selectedTable={selectedTable} onTableSelect={handleTableSelect} key={`import-1`} />},
    {title: 'Advanced', child: <TableSelect title={'Import'} key={`import-0`} selectedTable={selectedTable} onTableSelect={handleTableSelect} />},
  ]
  return (
    <Container maxWidth='xl'>
    <Paper sx={{p: 4, mb: 4}}>
      <Box display='flex' alignItems='center' gap={2} mb={4}>
        <FileUpload fontSize='large' />
        <Typography variant='h4'>
          CSV Import Tool
        </Typography>
      </Box>
      <Stepper activeStep={activeStep} sx={{mb: 4}}>
        {steps.map((label) =>(
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{mt: 4}}>
        {activeStep === 0 && (
          <TabbedPage tabs={tabs} />
        )}
        {activeStep === 1 && (
          <CSVUploader
  onCsvLoaded={(headers, data) => {
    const parsedData = { headers, data };
    handleCSVSubmit(parsedData); 
  }}
/>
        )}
        {activeStep === 2 && (
          <ColumnMapper csvColumns={csvHeaders} fileColumns={fileColumns} mappings={mapperColumns} onMappingChange={handleMappingChange} selectedTable={selectedTable} tableCols={mapperColumns} />
        )}
        {activeStep === 3 && (
          <PreviewTable mappings={mapperColumns} data={parsedData} />
        )}
        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 4, gap: 2}}>
          {activeStep > 0 && (
            <Button onClick={handleBack}>
              Back
            </Button>
          )}
          {activeStep < steps.length - 1 && (
            <Button
              variant='contained'
              onClick={handleNext}
            >
              Next
            </Button>
          )}
          {activeStep === 3 && (
            <Button variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  </Container>
  );
};

export default ImportPage;
