import React from "react";
import { Box, Typography } from "@mui/material";
import ClientBillingAPI from "@oneAppCore/services/apis/ClientBilling/ClientBillingAPI";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import dayjs from 'dayjs';
import { dateFormat } from '@oneAppCore/constants/dates';

interface Transaction {
    id: string;
    date: string;
    description: string;
    amount: number;
    type: 'deposit' | 'withdrawal';
    paymentMethod: {
        type: 'credit-card' | 'bank-transfer';
        details?: string;
    };
    status: 'completed' | 'pending' | 'failed';
}

interface ComponentTransaction {
    id: string;
    transactionDate: string;
    paymentType: string;
    amount: number;
    type: 'deposit' | 'withdrawal';
    paymentMethod: {
        type: 'credit-card' | 'bank-transfer';
        details?: string;
    };
    status: 'completed' | 'pending' | 'failed';
}

const columns: GridColDef<ComponentTransaction>[] = [
    {
        field: 'transactionDate',
        headerName: 'Date',
        flex: 1,
        valueFormatter: (params) => {
            return dayjs(params).format(dateFormat);
        }
    },
    {
        field: 'paymentType',
        headerName: 'Description',
        flex: 2
    },
    {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => {
            const amount = params.value;
            const formatted = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(amount);
            return (
                <div style={{ color: amount < 0 ? '#d32f2f' : '#2e7d32' }}>
                    {formatted}
                </div>
            );
        }
    },
    {
        field: 'paymentMethod',
        headerName: 'Payment Method',
        flex: 1.5,
        renderCell: (params) => {
            const method = params.row.paymentMethod;
            let displayText = method.type === 'credit-card' ? 'Credit Card' : 'Bank Transfer';
            if (method.details) {
                displayText += ` (${method.details})`;
            }
            return displayText;
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
            const status = params.value as string;
            const statusColors = {
                completed: '#e6f4ea',
                pending: '#fff8e1',
                failed: '#fce8e8'
            };
            const colors = {
                completed: 'darkgreen',
                pending: '#eed202',
                failed: '#d32f2f'
            }
            
            return (
                <Box sx={{
                    backgroundColor: statusColors[status.toLowerCase()],
                    color: colors[status.toLowerCase()],
                    borderRadius: '16px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    minWidth: '90px',
                    textAlign: 'center',
                    maxHeight: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {status}
                </Box>
            );
        }
    }
];

const Transactions: React.FC = () => {
    const [transactions, setTransactions] = React.useState<ComponentTransaction[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);


    React.useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const api = new ClientBillingAPI();
                console.log('Fetching transactions...');
                const response = await api.getRows();
                
                if (!response.transactions || !Array.isArray(response.transactions)) {
                    throw new Error('Invalid response format from API');
                }

                // Transform API response to match component's transaction type
                const transformedTransactions: ComponentTransaction[] = response.transactions.map(apiTx => {
                    const amount = Number(apiTx.amount);
                    const isDeposit = apiTx.paymentType === 'DEPOSIT';
                    
                    return {
                        id: apiTx.id.toString(),
                        transactionDate: dayjs(apiTx.transactionDate).format('YYYY-MM-DD HH:mm:ss'),
                        paymentType: isDeposit ? 'Deposit' : apiTx.paymentType,
                        amount: isDeposit ? amount : -amount,
                        type: isDeposit ? 'deposit' : 'withdrawal',
                        paymentMethod: {
                            type: apiTx.paymentMethod.toLowerCase().includes('credit') ? 'credit-card' : 'bank-transfer',
                            details: apiTx.paymentMethod.toLowerCase().includes('credit') && apiTx.paymentDetails ? 
                                `****${apiTx.paymentDetails}` : undefined
                        },
                        status: 'completed'
                    };
                });
                setTransactions(transformedTransactions);
                setError(null);
            } catch (err) {
                setError('Failed to load transactions');
                console.error('Error fetching transactions:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);


    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                Transaction History
            </Typography>
            {error ? (
                <Box sx={{ p: 2, color: 'error.main' }}>
                    {error}
                </Box>
            ) : (
                <DataGridPremium
                    rows={transactions}
                    columns={columns}
                    loading={loading}
                    disableRowSelectionOnClick
                    autoPageSize
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderBottom: 1,
                            borderColor: 'divider',
                            py: 1.5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'action.hover',
                            borderBottom: 2,
                            borderColor: 'divider'
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: 2,
                            borderColor: 'divider'
                        },
                        '& .MuiChip-root': {
                            height: '24px',
                            fontSize: '0.75rem'
                        }
                    }}
                />
            )}
        </Box>
    );
};

Transactions.displayName = 'Transactions';

export default Transactions;