import type { Column } from '@src/Components/common/containers/SearchView/types';
import { Grid, Tooltip, IconButton } from '@mui/material';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { useHistory } from 'react-router-dom';

export const columns: Column[] = [
  {
    title: 'Company Name',
    keyName: 'name',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Created By',
    keyName: 'createdBy',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Updated By',
    keyName: 'updatedBy',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Actions',
    keyName: 'actions',
    sortBy: false,
    align: 'center',
    customComponent: function ViewCompany(row) {
      const history = useHistory();
      const {id} = row;
      return (
        <Grid key={row.id}>
          <Tooltip title={`View Company`}>
            <span>
              <IconButton onClick={() => history.push(`/admin/companies/edit/${id}`)}>
                <VisibilityTwoToneIcon color="primary" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      );
    }
  }
];