import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Grid,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { listingTable } from './constants';
import type { Listings, ListingProps, ListingConstants } from './types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { apiFuncs as Api } from '@oneAppCore/services/apis/Api';
import { DRAFT } from '@oneAppCore/constants/listings';
import { convertDollarsToCents } from '@src/utils/currency';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  formatCentsToDollars,
  formatCentsToDollarsIntl,
} from '@oneAppCore/utils/currency';
import ManufacturerSuppliersApi from '@oneAppCore/services/apis/ManufacturerSuppliers';
import { getStartLetters } from '@oneAppCore/utils/formatters';
import { primary } from '@src/Components/Theme/themes/constants';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  SHOPIFY,
  SHOPIFY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import TooltipTable from '@src/Components/common/TooltipTable';
import { Column } from '@src/Components/common/containers/SearchView/types';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    zIndex: 2,
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

const ListingTable = ({
  listingData,
  variationId,
  mutate,
  manufacturerNo,
  supplierData,
  discountData,
}: ListingProps) => {
  const [enableEdit, setEnableEdit] = useState<number | null>(null);
  const [stores, setStores] = useState([]);
  const [saving, setSaving] = useState(false);

  const { id }: any = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [editListing, setEditListing] = useState({
    id: 0,
    listingSku: '',
    price: 0,
    minPrice: 0,
    maxPrice: 0,
  });

  const classes = useStyles();

  useEffect(() => {
    const getStoreChannels = async () =>
      await Api.get(`/api/v1/store-channels/search`).then((res) => {
        const { rows } = res;
        setStores(rows);
      });
    getStoreChannels();
  }, []);

  const handleChange = (key, e) => {
    setEditListing((list) => {
      const updatedListing = {
        ...list,
        [key.keyName]: e.target.value,
      };
      return updatedListing;
    });
  };

  const invokeListingUpdate = async (id, fitIndex) => {
    setSaving(true);
    if (editListing.listingSku === null) {
      enqueueSnackbar('Please fill out all fields!', {
        variant: 'error',
      });
    } else {
      // const storeChannelId = ;
      const listingSku = editListing.listingSku;
      const originalPrice = listingData[fitIndex].price;
      const originalMin = listingData[fitIndex].minPrice;
      const originalMax = listingData[fitIndex].maxPrice;
      const newMin = editListing.minPrice;
      const newMax = editListing.maxPrice;
      const newPrice = editListing.price;
      const maxPrice =
        originalMax !== newMax ? convertDollarsToCents(newMax) : newMax;
      const minPrice =
        originalMin !== newMin ? convertDollarsToCents(newMin) : newMin;
      const price =
        originalPrice !== newPrice ? convertDollarsToCents(newPrice) : newPrice;

      const payload = {
        // storeChannelId,
        listingSku,
        status: DRAFT,
        price,
        minPrice,
        maxPrice,
      };

      try {
        await Api.put(`/api/v1/store-listings/${editListing.id}`, payload);
        enqueueSnackbar('Listing Updated!', {
          variant: 'success',
        });
        mutate();
        setEditListing(editListing);
      } catch (e) {
        enqueueSnackbar(`${e.message || e}`, {
          variant: 'error',
        });
      } finally {
        setEnableEdit(null);
        setSaving(false);
        setEditListing({
          id: 0,
          listingSku: '',
          price: 0,
          minPrice: 0,
          maxPrice: 0,
        });
      }
    }
  };

  const enableEditing = (fitIndex: number) => {
    setEditListing({
      id: listingData[fitIndex].id,
      listingSku: listingData[fitIndex].listingSku,
      price: listingData[fitIndex].price,
      minPrice: listingData[fitIndex].minPrice,
      maxPrice: listingData[fitIndex].maxPrice,
    });
    setEnableEdit(fitIndex);
  };

  const lowestSupp = supplierData
    ? supplierData?.reduce((lowest, supplier) => {
        const combinedCost = supplier.cost + supplier.shippingCost;
        if (!lowest || (combinedCost && combinedCost < lowest.combinedCost)) {
          return { supplier, combinedCost };
        }
        return lowest;
      }, null)
    : null;

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" aria-label="fitment">
        <TableHead>
          <TableRow className={classes.headerColor}>
            {listingTable?.map((listing: ListingConstants, i) => (
              <TableCell
                className={classes.fontColor}
                sx={{
                  backgroundColor: '#72717a',
                  color: '#fff',
                }}
                key={'listing_' + listing.keyName + i}
              >
                {listing.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {listingData &&
          listingData?.map((listing: Listings, fitIndex) => {
            const isEditing = enableEdit === fitIndex;
            return (
              <TableRow key={listing.id}>
                {listingTable?.map((key: ListingConstants, index) => {
                  if (isEditing && key.keyName === 'store') {
                    return (
                      <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                        {/* <Select
                        value={editListing.store}
                        onChange={(e) =>
                          handleChange({ keyName: 'store' }, e)
                        }
                      >
                        <MenuItem value={'0'}></MenuItem>
                        {stores.map((s) => (
                          <MenuItem value={s.id} key={s.storeId}>
                            {s.storeName}
                          </MenuItem>
                        ))}
                      </Select> */}
                        <Typography>{listing.store}</Typography>
                      </TableCell>
                    );
                  }
                  // else if (key.keyName === 'listingSku') {
                  //   <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                  //     <Typography>{listing.listingSku}</Typography>
                  //   </TableCell>;
                  // }
                  else if (key.keyName === 'channel') {
                    let href = '';
                    if (listing.channelId === channelIds[AMAZON]) {
                      href = AMAZON_ASIN_LINK(listing?.channelProductId);
                    } else if (listing.channelId === channelIds[WALMART]) {
                      href = WALMART_PRODUCT_LINK(listing?.channelProductId);
                    } else if (listing.channelId === channelIds[EBAY]) {
                      href = EBAY_PRODUCT_LINK(listing?.channelProductId);
                    } else if (listing?.channelId === channelIds[SHOPIFY]) {
                      href = SHOPIFY_PRODUCT_LINK(listing?.metaData?.domain, listing?.channelProductId);
                    }
                    const handleChannelClick = (href) => {
                      window.open(href, '_blank');
                    };
                    return (
                      <TableCell key={`listing_${key.keyName}_${listing.id}`}>
                        <Grid
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <img
                            width={35}
                            height={35}
                            style={{ borderRadius: 10 }}
                            src={listing?.icon}
                            onClick={() => href && handleChannelClick(href)}
                          />
                          <Link href={href} target="_blank">
                            <Tooltip
                              title={`View Listing on ${listing.channel}`}
                            >
                              <FontAwesome
                                size="small"
                                name="external-link-alt"
                                type="fa"
                                form="fa"
                              />
                            </Tooltip>
                          </Link>
                        </Grid>
                      </TableCell>
                    );
                  } else if (key.keyName === 'edit') {
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        <FontAwesome
                          name={isEditing ? 'check' : 'edit'}
                          color={isEditing ? '#50C87' : primary.main}
                          type="fas"
                          form="fa"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            isEditing
                              ? invokeListingUpdate(listing.id, fitIndex)
                              : enableEditing(fitIndex);
                          }}
                        />
                      </TableCell>
                    );
                  } else if (key.keyName === 'cost') {
                    const tooltipColumns: Column[] = [
                      {
                        title: 'Supplier',
                        type: 'string',
                        keyName: 'supplier',
                      },
                      {
                        // title: 'Total',
                        title: 'Total (Est.)',
                        type: 'string',
                        keyName: 'totalCost',
                        customComponent: function (row) {
                          const {
                            // oldCost,
                            cost,
                            estimatedShipping,
                            shippingCost,
                          } = row || {
                            estimatedShipping: 0,
                            shippingCost: 0,
                            cost: 0,
                          };
                          const total = cost + shippingCost;
                          const estTotal = cost + estimatedShipping;
                          return (
                            <Grid container item>
                              <Grid item xs={12}>
                                {formatCentsToDollarsIntl(total)}
                              </Grid>
                              <Grid item xs={12}>
                                ({formatCentsToDollarsIntl(estTotal)})
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                      {
                        title: 'Cost (Prev)',
                        type: 'money',
                        keyName: 'cost',
                        customComponent: function (row) {
                          const { oldCost, cost } = row || {
                            oldCost: 0,
                            cost: 0,
                          };
                          return (
                            <Grid container item>
                              <Grid item xs={12}>
                                {formatCentsToDollarsIntl(cost)}
                              </Grid>
                              <Grid item xs={12}>
                                ({formatCentsToDollarsIntl(oldCost)})
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                      {
                        title: 'Ship (Est.)',
                        type: 'money',
                        keyName: 'shippingCost',
                        customComponent: function (row) {
                          const { estimatedShipping, shippingCost } = row || {
                            estimatedShipping: 0,
                            shippingCost: 0,
                          };
                          return (
                            <Grid container item>
                              <Grid item xs={12}>
                                {formatCentsToDollarsIntl(shippingCost)}
                              </Grid>
                              <Grid item xs={12}>
                                ({formatCentsToDollarsIntl(estimatedShipping)})
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                    ];
                    const tooltipRows: any = (supplierData || []).map(
                      (supplier) => {
                        return {
                          ...supplier,
                          supplier:
                            supplier.metaData?.shortName ||
                            getStartLetters(supplier?.name, { caps: true }) ||
                            supplier.supplier
                              ?.match(/\b(\w)/g)
                              ?.join('')
                              ?.toUpperCase(),
                        };
                      },
                    );
                    const tooltipTable = (
                      <TooltipTable columns={tooltipColumns} rows={tooltipRows}>
                        <Grid item>
                          <Typography style={{ fontSize: '12px' }}>
                            {formatCentsToDollars(lowestSupp?.combinedCost)}
                          </Typography>
                        </Grid>
                      </TooltipTable>
                    );
                    return (
                      <TableCell key={'listing_' + key.keyName + listing.id}>
                        {tooltipTable}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'listing_' + key.keyName + listing.id}
                      >
                        {isEditing && key.editable !== false ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            // defaultValue={listing[key.keyName] ?? ''}
                            defaultValue={
                              key.keyName === 'price'
                                ? (listing.price / 100).toFixed(2)
                                : key.keyName === 'minPrice'
                                ? (listing.minPrice / 100).toFixed(2)
                                : key.keyName === 'maxPrice'
                                ? (listing.maxPrice / 100).toFixed(2)
                                : listing[key.keyName] ?? ''
                            }
                            onChange={(e) => handleChange(key, e)}
                          />
                        ) : key.customComponent ? (
                          key.customComponent(listing)
                        ) : (
                          listing[key.keyName]
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
      </Table>
    </TableContainer>
  );
};

export default ListingTable;
