import { columns, exportOptions, selectTypes } from './constants';
import SearchGrid from '@src/Components/common/containers/SearchGrid';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
import ProductApi from '@oneAppCore/services/apis/Products';
import useChannelCategories from '@src/hooks/swr/useChannelCategories';
import { Filters, AllowedSelectTypes } from '@src/Components/common/containers/SearchGrid/types';
import { useEffect, useState } from 'react';

function OrdersSearch() {
  const { data: channelCategories } = useChannelCategories();
  const [filters, setFilters] = useState<Filters[]>([]);

  const initialFilters: Filters[] = [
    {
      type: 'date range',
      name: 'Created At',
      keyName: 'createdAt',
    },
    {
      type: 'string',
      name: 'Sku',
      keyName: 'sku',
    },
    {
      type: 'string',
      name: 'MPN',
      keyName: 'manufacturerNo',
    },
    {
      type: 'string',
      name: 'Brand',
      keyName: 'brand',
    },
    {
      type: 'string',
      name: 'Category',
      keyName: 'categories',
    },
    {
      type: 'selector',
      name: 'Status',
      keyName: 'status',
      properties: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Draft', value: 'draft' },
        { label: 'Pending', value: 'pending' },
        { label: 'Error', value: 'error' }
      ],
      options: {
        placeholder: 'Select...'
      }
    },
    {
      type: 'selector',
      name: 'Store',
      keyName: 'store',
      properties: [
        { label: 'Shopify', value: 'shopify' }
      ],
      options: {
        placeholder: 'Select...'
      }
    }
  ];

  const combinedFilters = initialFilters.map((filter) => {
    const updatedFilter = filters.find((f) => f.keyName === filter.keyName);
    return updatedFilter ? updatedFilter : filter;
  });

  useEffect(() => {
    const mpnFromLocalStorage = localStorage.getItem('selectedVarMpn') || '';
    if (mpnFromLocalStorage) {
      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.keyName === 'manufacturerNo') {
            return {
              ...filter,
              defaultValue: mpnFromLocalStorage,
            };
          }
          return filter;
        });
        return updatedFilters;
      });
    }
  }, []);

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchGrid
        url="/api/v1/products/search"
        api={ProductApi}
        select
        selectTypes={selectTypes}
        exportOptions={exportOptions}
        columns={columns(channelCategories)}
        editableColumns={['name', 'channelCategoryId']}
        filters={combinedFilters}
      />
    </Grid>
  );
}

export default OrdersSearch;
