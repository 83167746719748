import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useMemo,
} from 'react';
import useMeStores from '@src/hooks/swr/useMeStores';
import { useParams } from 'react-router-dom';
import { columns, filters } from './constants';
import StoreListingsApi from '@oneAppCore/services/apis/StoreListings';
import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import useStoreListings from './hooks/useStoreListingsRepriceData';
import AscIcon from './DirectionIcons/AscIcon';
import DescIcon from './DirectionIcons/DescIcon';
import { Backdrop } from '@src/Components/common';

import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions';

import {
  Theme,
} from '@mui/material/styles';
import {
  makeStyles,
  withStyles,
  createStyles,
} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@src/Components/common/ListView/DataList/DataTable/TablePagination';
// import MiniReports from './MiniReports';
import { useSnackbar } from 'notistack';
import { Form, Params } from './types';

import {
  ACTIVE,
  APPROVED,
  COMPLETED,
  PENDING,
} from '@oneAppCore/constants/listings';
import {
  CircularProgress,
  Badge,
  Button,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import useMe from '@src/hooks/swr/useMe';
import { NestedMenuItem } from 'mui-nested-menu';
import DateSelect from '@src/Components/common/Selects/DateSelect';
import { useDebouncedCallback } from 'use-debounce';
import { adminCheck } from '@src/utils/adminCheck';
import { Collapse } from '@mui/material';
import { omit } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: 10,
  },
  table: {
    minWidth: 650,
  },
  cellContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filter: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },
  column: {
    padding: theme.spacing(1, 0),
    border: 0,
  },

  headerColor: {
    backgroundColor: '#f3f2f7',
    border: 0,
  },
  tablePaper: {
    width: '425px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f3f2f7',
    },
    body: {
      fontSize: 10,
    },
  }),
)(TableCell);

const omittedProps = ['id', 'changeType']

function ListingReprice({
  changedArray,
  mutate,
  row,
}: {
  changedArray: any[];
  mutate: any;
  row: any;
}) {
  const { data: stores = [] } = useMeStores();
  const classes = useStyles();
  const [listings, setListings] = useState();
  const [searchFilters, setSearchFilters] = useState({});
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [defaultSearchFilters, setDefaultSearchFilters] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pullChanged, setPullChanged] = useState(false);
  const [pullCostHistory, setpullCostHistory] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [changedRows, setChangedRows] = useState([]);
  const [costHistory, setCostHistory] = useState([]);
  const [hiddenRows, setHiddenRows] = useState<Record<string, boolean>>({});
  const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({});
  const { enqueueSnackbar } = useSnackbar();
  const [isAdmin, setIsAdmin] = useState(false);
  const { data: user } = useMe();
  const id = useParams<Params>();
  useEffect(() => {
    const getCostHistory = async () => {
      const chData = await StoreListingsApi.getListingCostHistory();
      setCostHistory(chData.rows);
    };
    getCostHistory();
  }, [pullCostHistory]);
  const {
    data: slData,
    mutate: slMutate,
  } = useStoreListings.useStoreListingsRepriceData({
    limit: searchOptions.limit,
    page: currentPage,
    channelId: searchOptions.channel,
    ...searchFilters,
    orderBy: searchOptions.orderBy,
    searchFilter: searchOptions.searchFilter,
  });
  const didMount = useRef(false);
  useLayoutEffect(() => {
    if (didMount.current) {
      didMount.current = true;
    }
  });

  const getListingChanges = async () => {
    const { rows } = await StoreListingsApi.getListingChanges({
      page: 1,
    });
    const rowArray = [
      ...rows.map((datum) => {
        const { storeListingId: id, id: changeId, newValues, status } = datum;
        return {
          ...omit(newValues, [...omittedProps, 'changeId', 'status']),
          id,
          changeId,
          status,
        };
      }),
    ];
    setChangedRows(rowArray);
  };

  useEffect(() => {
    const userRoles = user.roles;
    const admin = adminCheck(userRoles);
    setIsAdmin(admin);
    getListingChanges();
  }, [pullChanged]);

  useEffect(() => {
    const getListings = async () => {
      if (didMount.current) {
        await slMutate();
      }
    };
    getListings();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const changeCount = async () => {
      if (
        (slData?.count || slData?.count === 0) &&
        Math.ceil(slData?.count / rowsPerPage) !== totalPages
      ) {
        setTotalPages(Math.ceil(slData?.count / rowsPerPage));
      }
    };
    changeCount();
  }, [slData?.count]);

  const alterChangedRows = (inputId, input, update = false) => {
    try {
      const indexArray = changedRows
        .map((item, index) => ({ id: item.id, index }))
        .filter((item) => item.id === inputId);
      const newChangedRows = [...changedRows];
      if (!indexArray.length) {
        // ? no row exists, make new row
        const newRow = { id: inputId, ...input };
        newChangedRows.push(newRow);
      } else if (update) {
        // ? row exists, update row
        const oldRow = changedRows[indexArray[0].index];
        const newRow = { ...oldRow, ...input };
        newChangedRows.splice(indexArray[0].index, 1, newRow);
      } else {
        //? ?????????!?
        const inputKeys = Object.keys(input);
        const row = newChangedRows[indexArray[0].index];
        if (row) {
          const changedRow = omit(row, inputKeys);
          const rowKeys = Object.keys(changedRow);
          if (rowKeys.length === 1) {
            newChangedRows.splice(indexArray[0].index, 1);
          } else {
            newChangedRows.splice(indexArray[0].index, 1, changedRow);
          }
        }
      }
      setChangedRows(newChangedRows);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const approveChanges = async (changedRow: any) => {
    const { listingIds } = changedRow;
    const approvePayload = {
      status: PENDING,
      approved: true,
      listingIds,
    };
    try {
      await StoreListingsApi.updateSLChange(changedRow.changeId, approvePayload);
      enqueueSnackbar('Listing change has been approved!', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.messagge, { variant: 'error' });
    } finally {
      slMutate();
      setPullChanged(!pullChanged);
    }
  };

  const rejectChanges = async (changedRow: any) => {
    // const listingPayload = {
    //   status: ACTIVE,
    // };
    const { listingIds } = changedRow;

    const changePayload = {
      status: COMPLETED,
      approved: false,
      listingIds,
    };
    try {
      await StoreListingsApi.updateSLChange(changedRow.changeId, changePayload);
      // await StoreListingsApi.put(changedRow.id, listingPayload);
      enqueueSnackbar('Listing change has been rejected', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      slMutate();
      setPullChanged(!pullChanged);
    }
  };
  const saveSuggestion = async (changedRow: any, listingIds) => {
    const createPayload = {
      storeListingId: changedRow.id,
      listingIds,
      type: 'reprice',
      newValues: { ...omit(changedRow, [...omittedProps]) },
    };
    try {
      console.log(createPayload);
      const result = await StoreListingsApi.createSLChange(createPayload);
      enqueueSnackbar('Submitted for approval!', { variant: 'info' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      slMutate();
      setPullChanged(!pullChanged);
    }
  };

  const saveChanges = async (changedRow: any) => {
    const { listingIds, changeType } = changedRow;
    const storeListingId = Array.isArray(changedRow.id)
      ? changedRow.id[0]
      : changedRow.id;
    const createPayload = {
      storeListingId,
      type: 'reprice',
      newValues: { ...omit(changedRow, [...omittedProps, 'listingIds']) },
      listingIds,
    };
    try {
      setLoadingRows(prev => ({ ...prev, [storeListingId]: true }));
      let action = 'Submitted';
      const createResult = await StoreListingsApi.createSLChange(createPayload); // TODO maybe want to output the result later
      if (isAdmin) {
        const approvePayload = {
          status: APPROVED,
          approved: true,
          listingIds,
        };
        const approveResult = await StoreListingsApi.updateSLChange(changedRow.changeId, approvePayload);
        action = 'Approved';
      }
      setHiddenRows(prev => ({ ...prev, [storeListingId]: true }));
      enqueueSnackbar(`Change has been ${action}!`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setLoadingRows(prev => ({ ...prev, [storeListingId]: false }));
      slMutate();
      setPullChanged(!pullChanged);
    }
  };

  const loading = slData ? false : true;

  const handleBestClick = (e) => {
    e.preventDefault();
  };
  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (perPage: number) => {
    setRowsPerPage(perPage);
    setCurrentPage(1);
  };

  const updateSearchFilter = useDebouncedCallback((filter: string) => {
    setSearchOptions((previous) => ({
      ...previous,
      searchFilter: filter,
    }));
  }, 1000);

  const updateStore = useDebouncedCallback(
    (filterOptions: Record<string, any>) => {
      setSearchOptions((previous) => ({
        ...previous,
        filters: filterOptions,
      }));
    },
    1500,
  );

  const itemRows = slData?.rows;

  let newArray = [];

  let uniqueObj = {};

  for (let i in itemRows) {
    const objTitle = itemRows[i]['storeProductId'];
    uniqueObj[objTitle] = itemRows[i];
  }

  for (let i in uniqueObj) {
    newArray.push(uniqueObj[i]);
  }

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const updateFilters = (key: string[], value: any[]) => {
    // const filterOptions = {
    const firstOpts = {
      ...searchFilters,
      // [key]: value,
    };
    let secondOpts: any = {};
    key.forEach((k, i) => {
      secondOpts[k] = value[i];
    });
    const filterOptions = Object.assign(firstOpts, secondOpts);
    if (!value) delete filterOptions[key[0]];
    setSearchFilters(filterOptions);
    updateStore(filterOptions);
  };
  const activeFilters = useMemo(
    () =>
      Object.entries(searchOptions.filters)
        .filter(([key]) => !defaultSearchFilters.includes(key))
        .filter(([key, val]) => val).length,
    [searchOptions],
  );

  const searchBar = (
    <>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        style={{ marginBottom: 10, marginTop: 15 }}
      >
        {Boolean(filters(stores).length) && (
          <Badge
            badgeContent={activeFilters}
            color="primary"
            style={{ float: 'right' }}
          >
            <Button variant="contained" color="primary" onClick={handleClick}>
              <FilterIcon />
            </Button>
          </Badge>
        )}
        <Grid item xs={6} md={2}>
          <TextField
            title="Search by name, SKU, or channel product ID."
            onChange={(e) =>
              updateSearchFilter(
                e.currentTarget.value.replaceAll('&', encodeURIComponent('&')),
              )
            }
            placeholder="Search..."
            variant="outlined"
            style={{ marginLeft: 15, width: '100%' }}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginRight: 15 }}
          onClick={() => {
            /* mutateSearch() */
          }}
        >
          <SearchIcon />
        </Button>
        <Menu
          id="filters-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          disableAutoFocusItem
        // getContentAnchorEl={() => null}
        >
          {filters(stores).map((filter) => (
            <NestedMenuItem
              key={filter.keyName}
              label={filter.name}
              parentMenuOpen={Boolean(anchorEl)}
              selected={
                Boolean(searchFilters?.[filter.keyName]) ||
                Boolean(searchFilters?.[`${filter.keyName}.startDate`]) ||
                Boolean(searchFilters?.[`${filter.keyName}.endDate`])
              }
              onKeyDown={(e) => e.stopPropagation()}
              onClickCapture={stopImmediatePropagation}
              value={''}
            >
              <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                {filter.type === 'string' ? (
                  <TextField
                    value={searchFilters?.[filter.keyName] || ''}
                    onChange={(e) =>
                      updateFilters([filter.keyName], [e.currentTarget.value])
                    }
                    disabled={filter.disabled || false}
                    label={filter.name}
                    variant="outlined"
                  />
                ) : filter.type === 'date range' ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <DateSelect
                        label="Start Date"
                        value={
                          searchFilters?.[`${filter.keyName}.startDate`] || null
                        }
                        onChange={(val) =>
                          updateFilters([`${filter.keyName}.startDate`], [val])
                        }
                        animateYearScrolling
                        fullWidth
                        startOfDay
                        disabled={filter.disabled || false}
                      />
                    </Grid>
                    <Grid item>
                      <DateSelect
                        label="End Date"
                        value={
                          searchFilters?.[`${filter.keyName}.endDate`] || null
                        }
                        onChange={(val) =>
                          updateFilters([`${filter.keyName}.endDate`], [val])
                        }
                        animateYearScrolling
                        fullWidth
                        endOfDay
                        disabled={filter.disabled || false}
                      />
                    </Grid>
                  </Grid>
                ) : filter.type === 'date range select' ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <InputLabel htmlFor={`date-select-${filter.keyName}`}>
                        Range Preset
                      </InputLabel>
                      <Select
                        disabled={filter.disabled || false}
                        labelId="selectorProperty"
                        id={`date-select-${filter.keyName}`}
                        variant="outlined"
                        value={searchFilters?.[filter.keyName] || null}
                        onChange={(e) => {
                          const val = `${e.target.value}`;
                          const [start, end] = val.split('--');
                          updateFilters(
                            [
                              `${filter.keyName}.startDate`,
                              `${filter.keyName}.endDate`,
                              filter.keyName,
                            ],
                            [start, end, val],
                          );
                        }}
                      >
                        <MenuItem value="--">Select Range</MenuItem>
                        {filter.properties.map((property, i) => {
                          return (
                            <MenuItem
                              disabled={property.disabled}
                              value={`${property.value
                                .map((thing: any) => thing)
                                .join('--')}`}
                              key={i}
                            >
                              {property.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item>
                      <DateSelect
                        label="Start Date"
                        value={
                          searchFilters?.[`${filter.keyName}.startDate`] || null
                        }
                        onChange={(val) =>
                          updateFilters([`${filter.keyName}.startDate`], [val])
                        }
                        animateYearScrolling
                        fullWidth
                        startOfDay
                        disabled={filter.disabled || false}
                      />
                    </Grid>
                    <Grid item>
                      <DateSelect
                        label="End Date"
                        value={
                          searchFilters?.[`${filter.keyName}.endDate`] || null
                        }
                        onChange={(val) =>
                          updateFilters([`${filter.keyName}.endDate`], [val])
                        }
                        animateYearScrolling
                        fullWidth
                        endOfDay
                        disabled={filter.disabled || false}
                      />
                    </Grid>
                  </Grid>
                ) : filter.type == 'date' ? (
                  <DateSelect
                    label="Date"
                    value={searchFilters?.[filter.keyName] || null}
                    onChange={(val) => updateFilters([filter.keyName], [val])}
                    animateYearScrolling
                    fullWidth
                    disabled={filter.disabled || false}
                  />
                ) : filter.type == 'selector' ? (
                  <Grid container spacing={1}>
                    <Select
                      disabled={filter.disabled || false}
                      labelId="selectorProperty"
                      value={searchFilters?.[filter.keyName] || null}
                      onChange={(e) =>
                        updateFilters([filter.keyName], [e.target.value])
                      }
                    >
                      {!filter.options?.hideDefaultNoneOption && (
                        <MenuItem value="">None</MenuItem>
                      )}
                      {filter.properties.map((property, i) => {
                        return (
                          <MenuItem value={property.value} key={i}>
                            {property.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                ) : filter.type == 'array' ? (
                  <Grid container spacing={1}>
                    <Select
                      disabled={filter.disabled || false}
                      labelId="arrayProperty"
                      value={searchFilters?.[filter.keyName]?.[1] || null}
                      onChange={(e) =>
                        updateFilters(
                          [filter.keyName],
                          e.target.value
                            ? [filter.operator, e.target.value]
                            : null,
                        )
                      }
                    >
                      <MenuItem value="">None</MenuItem>
                      {filter.properties.map((property, i) => {
                        return (
                          <MenuItem value={property.value} key={i}>
                            {property.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                ) : (
                  <></>
                )}
              </MenuItem>
            </NestedMenuItem>
          ))}
        </Menu>
        {/* </Grid> */}
      </Grid>
      {/* </Grid> */}
    </>
  );

  return (
    <>
      <TablePagination
        currentPage={currentPage}
        setCurrentPage={handleChangePage}
        totalPages={totalPages}
        showChannelOptions={true}
      />
      <Paper className={classes.root}>
        <Grid container item xs={12}>
          {searchBar}
        </Grid>
        {slData ? null : (
          <Grid
            container
            xs={12}
            style={{
              marginTop: 20,
              marginBottom: 30,
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!slData ? null : (
          <Grid
            container
            item
            xs={12}
            style={{ overflowX: 'initial', justifyContent: 'center' }}
          >
            <Table stickyHeader>
              <TableHead style={{ whiteSpace: 'nowrap' }}>
                <TableRow>
                  {columns(classes).map((column) => {
                    if (column.sortBy) {
                      return (
                        <StyledTableCell
                          align={column.align || 'center'}
                          key={column.keyName}
                          className={classes.column}
                        >
                          <div className={classes.cellContentWrapper}>
                            <div>{column.title}</div>
                            <div className={classes.filter}>
                              <AscIcon keyName={column.keyName} />
                              <DescIcon keyName={column.keyName} />
                            </div>
                          </div>
                        </StyledTableCell>
                      );
                    }
                    return (
                      <StyledTableCell
                        align={column.align}
                        key={column.keyName}
                        className={classes.column}
                        style={{
                          maxWidth:
                            column.keyName === 'name' ? '120px' : '100%',
                        }}
                      >
                        {column.title}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {slData?.rows?.map((listing, i) => {
                  const storeListingId = Array.isArray(listing.id) ? listing.id[0] : listing.id;
                  return (
                    <TableRow 
                      key={`listing-reprice-${i}`}
                      sx={{ 
                        '& > *': { 
                          borderBottom: !hiddenRows[storeListingId] ? '1px solid rgba(224, 224, 224, 1)' : 'none'
                        }
                      }}
                    >
                      {columns(classes).map((column) =>
                        column.customComponent ? (
                          <TableCell key={`column-${column.keyName}-${i}`}>
                            <Collapse in={!hiddenRows[storeListingId]} timeout={500}>
                              {column.customComponent(listing, {
                                changedRows,
                                handleBestClick,
                                alterChangedRows,
                                saveChanges,
                                saveSuggestion,
                                rejectChanges,
                                approveChanges,
                                history: costHistory,
                                loadingRows,
                              })}
                            </Collapse>
                          </TableCell>
                        ) : (
                          <TableCell key={`column-${column.keyName}-${i}`}>
                            <Collapse in={!hiddenRows[storeListingId]} timeout={500}>
                              <p style={{ textAlign: 'center' }}>
                                {listing[column.keyName]}
                              </p>
                            </Collapse>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Paper>
      <TablePagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
}

export default ListingReprice;
