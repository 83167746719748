import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useQuery from '@src/hooks/util/useQuery';

import { omit, pick } from 'lodash';
import OrdersApi from '@oneAppCore/services/apis/Orders';

import { Button, ButtonGroup, Grid } from '@mui/material';
import ParentOrder from './ParentOrder';
import ActionCard from './ActionCard';
import NotesCard from './NotesCard';
import { COMPLETED, PLACED, TRACKED } from '@oneAppCore/constants/orders';

const placedFields = ['supplierOrderNo', 'supplierTrackingNo', 'supplierTrackingCarrier'];
const trackedFields = ['supplierTrackingNo', 'supplierTrackingCarrier'];

function EditOrderPage() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const url = useLocation();
  const { edit } = useQuery();

  const { id } = useParams<{ id?: string }>();

  const [isViewMode, setIsViewMode] = useState<boolean>(true);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [order, setOrder] = useState<any>({});
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    if (location.pathname.toLowerCase().includes('edit') || edit) {
      setIsViewMode(false);
    }
  }, [])

  const updateForm = (key: string, value: any) => {
    setForm({
      ...form,
      [key]: value,
    });
    setHasChanges(true);
  };

  const save = () => {
    const orderId = Number(id);
    const loadOrder = async () => {
      const orderCall = await OrdersApi.getOrder(orderId);
      setOrder(orderCall);
    };
    try {
      // save to DB here
      const { orderStatus } = order;
      const changes = [];
      const newObj: any = {};
      Object.keys(omit(form, ['orderItems'])).forEach((k) => { changes.push(k) });
      const itemKeys = Object.keys(form?.orderItems?.[0] || {});
      // ! this isn't getting the keys from the orderItems, needs
      Object.keys(itemKeys).forEach((k) => {
        changes.push(k);
      });
      const isPlaced = changes.filter(element => placedFields.includes(element)).length;
      const isTracked = changes.filter(element => trackedFields.includes(element)).length;
      if (isPlaced && (orderStatus !== PLACED && orderStatus !== TRACKED && orderStatus !== COMPLETED)) {
        newObj.orderStatus = PLACED;
      }
      if (isTracked && orderStatus !== TRACKED && orderStatus !== COMPLETED) {
        newObj.orderStatus = TRACKED;
      }
      const saveOrder = async () => {
        try {
          await OrdersApi.update(orderId, { ...newObj, ...form });
          enqueueSnackbar('Edited Order', { variant: 'success' });
        } catch (e) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      }
      saveOrder();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      loadOrder();

      setForm({});
      clear();
    }
  };
  const clear = () => {
    setHasChanges(false);
    setIsViewMode(true);
  };

  useEffect(() => {
    const orderId = Number(id);

    if (orderId) {
      const loadOrder = async () => {
        const orderCall = await OrdersApi.getOrder(orderId);
        setOrder(orderCall);
      };
      loadOrder();
    }
  }, [id]);

  return (
    <div>
      <Grid container xs={12} >
        <Grid item xs={10}>
          <ParentOrder isViewMode={isViewMode} row={order.order} updateForm={updateForm} />
        </Grid>
        <Grid item xs={2} justifyContent="space-between" >
          <Grid item style={{ marginBottom: 10 }}>
            <ActionCard clear={clear} save={save} isViewMode={isViewMode} setIsViewMode={setIsViewMode} hasChanges={hasChanges} />
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <NotesCard />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EditOrderPage;
