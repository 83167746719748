import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AuroraBackground } from '@src/Components/common/AuroraBackground';
import { useSnackbar } from 'notistack';
import useMe from '@src/hooks/swr/useMe';
import { useHistory } from 'react-router-dom';
import companyApi from '@oneAppCore/services/apis/Companies';
import { delay } from '@oneAppCore/utils/helpers';

const salesChannels = [
  {
    name: 'Amazon',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg'
  },
  {
    name: 'eBay',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg'
  },
  {
    name: 'Walmart',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Walmart_logo.svg'
  },
  {
    name: 'Etsy',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg'
  },
  {
    name: 'WooCommerce',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/WooCommerce_logo.svg'
  },
  {
    name: 'Shopify',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/0/0e/Shopify_logo_2018.svg'
  }
];

const businessModels = [
  {
    value: 'brand',
    label: 'Brand',
    description: 'I sell my own branded products',
    icon: '/icons/brand.svg'
  },
  {
    value: 'reseller',
    label: 'Reseller',
    description: 'I resell products from other brands',
    icon: '/icons/reseller.svg'
  },
  {
    value: 'retail',
    label: 'Retail Store',
    description: 'I have a physical retail store',
    icon: '/icons/shop.svg'
  }
];

export const SplashScreen = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, mutate: mutateUser } = useMe();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [selectedBusinessModels, setSelectedBusinessModels] = useState<string[]>([]);
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Reseller form state
  const [resellerDetails, setResellerDetails] = useState({
    websites: [{ name: '', url: '', hasApi: false, apiLink: '' }]
  });

  // Shopify store details
  const [shopifyDetails, setShopifyDetails] = useState({
    storeName: '',
    storeUrl: ''
  });

  // Address form state
  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US'
  });

  // Wait for user data to be loaded
  useEffect(() => {
    const checkUser = async () => {
      try {
        if (!user) {
          await mutateUser();
        }
      } catch (error) {
        console.error('Error loading user data:', error);
        // If we can't load user data, redirect to login
        window.location.href = '/login';
      }
    };
    checkUser();
  }, [user, mutateUser]);

  const handleChannelChange = (channel: string) => {
    setSelectedChannels((prev) =>
      prev.includes(channel)
        ? prev.filter((c) => c !== channel)
        : [...prev, channel]
    );
  };

  const handleBusinessModelChange = (value: string) => {
    setSelectedBusinessModels((prev) =>
      prev.includes(value)
        ? prev.filter((model) => model !== value)
        : [...prev, value]
    );
  };

  const handleNext = () => {
    if (activeStep === getSteps().length - 1) {
      if (isRedirecting) return;
      handleFinish();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const getSteps = () => {
    const baseSteps = ['Welcome', 'Business Model', 'Sales Channels'];

    // Add Reseller Details step if reseller is selected
    if (selectedBusinessModels.includes('reseller')) {
      baseSteps.splice(2, 0, 'Reseller Details');
    }

    // Add Shopify Connection step if Shopify is selected
    if (selectedChannels.includes('Shopify')) {
      baseSteps.splice(baseSteps.length, 0, 'Connect Shopify');
    }

    // Add Address as the final step
    baseSteps.push('Address');
    return baseSteps;
  };

  const getStepContent = (step: number) => {
    const steps = getSteps();
    const currentStep = steps[step];

    switch (currentStep) {
      case 'Welcome':
        return (
          <Box
            component="img"
            src="https://oneappprodcentral.blob.core.windows.net/oneapp-images/oneapp-logo1719426423419.PNG"
            alt="OneApp Logo"
            sx={{
              width: '200px',
              height: '200px',
              mb: 4,
              display: 'block',
              margin: '0 auto',
              objectFit: 'contain'
            }}
          />
        );
      case 'Business Model':
        return (
          <>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                mb: 3,
                textAlign: 'center'
              }}
            >
              What type of business do you run?
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
                mb: 4
              }}
            >
              Select all that apply to your business
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
              <FormGroup>
                {businessModels.map((model) => (
                  <FormControlLabel
                    key={model.value}
                    control={
                      <Checkbox
                        checked={selectedBusinessModels.includes(model.value)}
                        onChange={() => handleBusinessModelChange(model.value)}
                        sx={{
                          color: 'rgba(0, 0, 0, 0.6)',
                          '&.Mui-checked': {
                            color: '#7C4DFF',
                          },
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                        <Box
                          component="img"
                          src={model.icon}
                          alt={`${model.label} icon`}
                          sx={{
                            width: '32px',
                            height: '32px',
                            mr: 2,
                            filter: 'grayscale(1) opacity(0.7)',
                            transition: 'all 0.3s ease',
                            ...(selectedBusinessModels.includes(model.value) && {
                              filter: 'grayscale(0) opacity(1)',
                            })
                          }}
                        />
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 500,
                              color: 'rgba(0, 0, 0, 0.87)',
                              mb: 0.5
                            }}
                          >
                            {model.label}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'rgba(0, 0, 0, 0.6)',
                              fontSize: '0.875rem'
                            }}
                          >
                            {model.description}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    sx={{
                      margin: '8px 0',
                      padding: '8px 16px',
                      width: '100%',
                      borderRadius: '8px',
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(124, 77, 255, 0.04)',
                        borderColor: '#7C4DFF',
                        '& img': {
                          filter: 'grayscale(0) opacity(1)',
                        }
                      },
                      ...(selectedBusinessModels.includes(model.value) && {
                        backgroundColor: 'rgba(124, 77, 255, 0.08)',
                        borderColor: '#7C4DFF',
                      }),
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </>
        );
      case 'Reseller Details':
        return (
          <>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                mb: 3,
                textAlign: 'center'
              }}
            >
              Tell us about where your business resells from
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
                mb: 4
              }}
            >
              This information helps us optimize your integration options
            </Typography>
            <Box sx={{
              width: '100%',
              maxWidth: '600px',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 4
            }}>
              {resellerDetails.websites.map((website, index) => (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    p: 3,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    position: 'relative'
                  }}
                >
                  {index > 0 && (
                    <IconButton
                      onClick={() => removeWebsite(index)}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'rgba(0, 0, 0, 0.4)'
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <TextField
                      fullWidth
                      label="Website Name"
                      value={website.name}
                      onChange={(e) => updateWebsite(index, 'name', e.target.value)}
                      placeholder="e.g., Aliexpress, Spocket, Printful"
                    />
                    <TextField
                      fullWidth
                      label="Website Link (Optional)"
                      value={website.url}
                      onChange={(e) => updateWebsite(index, 'url', e.target.value)}
                      placeholder="https://example.com"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={website.hasApi}
                          onChange={(e) => updateWebsite(index, 'hasApi', e.target.checked)}
                          sx={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            '&.Mui-checked': {
                              color: '#7C4DFF',
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontSize: '1rem'
                          }}
                        >
                          Do you have access to their API?
                        </Typography>
                      }
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                        padding: '12px 16px',
                        width: '100%',
                        borderRadius: '8px',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          backgroundColor: 'rgba(124, 77, 255, 0.04)',
                          borderColor: '#7C4DFF'
                        }
                      }}
                    />
                    {website.hasApi && (
                      <TextField
                        fullWidth
                        label="API Link (Optional)"
                        value={website.apiLink}
                        onChange={(e) => updateWebsite(index, 'apiLink', e.target.value)}
                        placeholder="https://api.example.com"
                        helperText="Provide the API documentation link if available"
                      />
                    )}
                  </Box>
                </Paper>
              ))}

              <Button
                onClick={addWebsite}
                variant="outlined"
                sx={{
                  mt: 2,
                  color: '#7C4DFF',
                  borderColor: '#7C4DFF',
                  '&:hover': {
                    borderColor: '#7C4DFF',
                    backgroundColor: 'rgba(124, 77, 255, 0.04)',
                  },
                }}
              >
                Add Another Website
              </Button>
            </Box>
          </>
        );
      case 'Connect Shopify':
        return (
          <>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                mb: 3,
                textAlign: 'center'
              }}
            >
              Connect your Shopify store
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
                mb: 4
              }}
            >
              Let&apos;s connect your Shopify store to sync your products
            </Typography>
            <Box sx={{
              width: '100%',
              maxWidth: '500px',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}>
              <TextField
                fullWidth
                label="Store Name"
                value={shopifyDetails.storeName}
                onChange={(e) => setShopifyDetails(prev => ({ ...prev, storeName: e.target.value }))}
                placeholder="e.g., my-awesome-store"
                helperText="Enter your Shopify store name (without .myshopify.com)"
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#95BF47',
                  color: 'white',
                  py: 2,
                  '&:hover': {
                    backgroundColor: '#7EA339',
                  },
                }}
                onClick={() => {
                  // This will be replaced with actual Shopify OAuth flow
                  const shopifyUrl = `https://${shopifyDetails.storeName}.myshopify.com/admin/oauth/authorize`;
                  window.location.href = shopifyUrl;
                }}
              >
                Connect with Shopify
              </Button>
              <Typography
                variant="body2"
                sx={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  textAlign: 'center',
                  mt: 2
                }}
              >
                You&apos;ll be redirected to Shopify to authorize the connection
              </Typography>
            </Box>
          </>
        );
      case 'Sales Channels':
        return (
          <>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                mb: 3,
                textAlign: 'center'
              }}
            >
              Where do you currently sell?
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
                mb: 4
              }}
            >
              Select all the marketplaces where you sell your products
            </Typography>
            <Box
              sx={{
                width: '100%',
                maxWidth: '800px',
                margin: '2rem auto',
              }}
            >
              <Grid container spacing={4} justifyContent="center" sx={{ px: 2 }}>
                {salesChannels.map((channel) => (
                  <Grid item xs={12} sm={6} md={4} key={channel.name}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: '120px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        background: 'transparent',
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        position: 'relative',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 8px 16px 2px rgba(0, 0, 0, 0.1)',
                          borderColor: '#7C4DFF',
                          '& .channel-logo': {
                            transform: 'scale(1.05)',
                          }
                        },
                      }}
                      onClick={() => handleChannelChange(channel.name)}
                    >
                      <Box
                        component="img"
                        src={channel.logo}
                        alt={`${channel.name} logo`}
                        className="channel-logo"
                        sx={{
                          maxWidth: '80%',
                          maxHeight: '60px',
                          objectFit: 'contain',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                      <Checkbox
                        checked={selectedChannels.includes(channel.name)}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          color: 'rgba(0, 0, 0, 0.3)',
                          '&.Mui-checked': {
                            color: '#7C4DFF',
                          },
                        }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        );
      case 'Address':
        return (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="h3"
              component="h2"
              sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: 600,
                mb: 3,
                textAlign: 'center'
              }}
            >
              What&apos;s your business address?
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center',
                mb: 4
              }}
            >
              Please provide your business location for shipping cost estimations
            </Typography>
            <Box component="form" sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              maxWidth: '500px',
              margin: '0 auto'
            }}>
              <TextField
                fullWidth
                label="Street Address"
                value={address.street}
                onChange={(e) => setAddress({ ...address, street: e.target.value })}
                required
              />
              <TextField
                fullWidth
                label="City"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                required
              />
              <TextField
                fullWidth
                label="State"
                value={address.state}
                onChange={(e) => setAddress({ ...address, state: e.target.value })}
                required
              />
              <TextField
                fullWidth
                label="ZIP Code"
                value={address.zipCode}
                onChange={(e) => setAddress({ ...address, zipCode: e.target.value })}
                required
              />
              <TextField
                fullWidth
                label="Country"
                value={address.country}
                onChange={(e) => setAddress({ ...address, country: e.target.value })}
              />
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const addWebsite = () => {
    setResellerDetails(prev => ({
      ...prev,
      websites: [...prev.websites, { name: '', url: '', hasApi: false, apiLink: '' }]
    }));
  };

  const removeWebsite = (index: number) => {
    setResellerDetails(prev => ({
      ...prev,
      websites: prev.websites.filter((_, i) => i !== index)
    }));
  };

  const updateWebsite = (index: number, field: string, value: string | boolean) => {
    setResellerDetails(prev => ({
      ...prev,
      websites: prev.websites.map((website, i) => {
        if (i === index) {
          return { ...website, [field]: value };
        }
        return website;
      })
    }));
  };

  const handleFinish = async () => {
    if (!user) {
      enqueueSnackbar('Please wait while we load your account information...', { variant: 'info' });
      return;
    }

    try {
      setIsRedirecting(true);

      // Save all company details
      const companyId = user?.companyId;
      if (!companyId) {
        throw new Error('Company not found');
      }

      // Update the user's company information
      const updateBody = {
        // Company information
        integrationData: {
          id: 1,
          // Address information
          shipFrom: {
            street1: address.street,
            city: address.city,
            state: address.state,
            postalCode: address.zipCode,
            country: address.country || 'United States'
          },
          // Business model information
          businessModel: {
            types: selectedBusinessModels,
            resellerDetails: selectedBusinessModels.includes('reseller') ? {
              websites: resellerDetails.websites.map(site => ({
                name: site.name,
                url: site.url,
                hasApi: site.hasApi,
                apiLink: site.apiLink
              }))
            } : null
          },
          // Sales channels information
          salesChannels: {
            channels: selectedChannels,
            shopifyDetails: selectedChannels.includes('Shopify') ? {
              storeName: shopifyDetails.storeName,
              storeUrl: shopifyDetails.storeUrl
            } : null
          }
        }
      };
      await companyApi.update(companyId, updateBody);

      // Save to localStorage for client-side reference
      localStorage.setItem('selectedBusinessModels', JSON.stringify(selectedBusinessModels));
      localStorage.setItem('salesChannels', JSON.stringify(selectedChannels));
      sessionStorage.setItem('onboardingComplete', 'true');
      localStorage.setItem('onboardingComplete', 'true');

      if (selectedBusinessModels.includes('reseller')) {
        localStorage.setItem('resellerDetails', JSON.stringify(resellerDetails));
      }
      if (selectedChannels.includes('Shopify')) {
        localStorage.setItem('shopifyDetails', JSON.stringify(shopifyDetails));
      }

      enqueueSnackbar('Company information updated successfully!', {
        variant: 'success',
      });

      // Update user data after company update
      await mutateUser();
      setIsRedirecting(false);

      await delay(750, null);

      // Navigate to analytics dashboard using React Router
      window.location.href = ('/dashboards/analytics'); // this is used to overcome issue with history.push
    } catch (error) {
      console.error('Error during onboarding completion:', error);
      setIsRedirecting(false);
      enqueueSnackbar('Failed to complete onboarding', {
        variant: 'error',
      });
    }
  };

  const handleSkipOnboarding = () => {
    setIsRedirecting(false);
    sessionStorage.setItem('onboardingComplete', 'true');
    localStorage.setItem('onboardingComplete', 'true');
    window.location.href = ('/dashboards/analytics');// this is used to overcome issue with history.push
  };

  return (
    <AuroraBackground>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: '600px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.06) 100%)',
          backdropFilter: 'blur(20px)',
          borderRadius: '24px',
          padding: '3rem',
          maxWidth: '800px',
          margin: '2rem auto',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto', p: 4 }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              mb: 6,
              '& .MuiStepLabel-label': {
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '0.875rem',
                '&.Mui-active': {
                  color: '#7C4DFF',
                },
              },
              '& .MuiStepIcon-root': {
                width: '1.5rem',
                height: '1.5rem',
                color: 'rgba(0, 0, 0, 0.3)',
                '&.Mui-active': {
                  color: '#7C4DFF',
                },
                '&.Mui-completed': {
                  color: '#7C4DFF',
                },
              },
              '& .MuiStepConnector-line': {
                borderColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            {getSteps().map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '1000px',
            margin: '0 auto',
            px: 4,
            mt: 4
          }}>
            {activeStep === 0 ? (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    minWidth: '160px',
                    height: '48px',
                    background: 'linear-gradient(45deg, #7C4DFF 30%, #448AFF 90%)',
                    boxShadow: '0 3px 5px 2px rgba(124, 77, 255, .3)',
                    color: 'white',
                    fontSize: '1rem',
                    fontWeight: 500,
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7C4DFF 60%, #448AFF 90%)',
                      transform: 'translateY(-1px)',
                      boxShadow: '0 4px 8px 2px rgba(124, 77, 255, .4)',
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={handleBack}
                  sx={{
                    minWidth: '120px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                      borderColor: 'rgba(0, 0, 0, 0.4)',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    minWidth: '120px',
                    background: 'linear-gradient(45deg, #7C4DFF 30%, #448AFF 90%)',
                    boxShadow: '0 3px 5px 2px rgba(124, 77, 255, .3)',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7C4DFF 60%, #448AFF 90%)',
                    },
                  }}
                  disabled={
                    (getSteps()[activeStep] === 'Business Model' && selectedBusinessModels.length === 0) ||
                    (getSteps()[activeStep] === 'Sales Channels' && selectedChannels.length === 0) ||
                    (getSteps()[activeStep] === 'Reseller Details' &&
                      !resellerDetails.websites.some(w => w.name && w.url)) ||
                    (getSteps()[activeStep] === 'Connect Shopify' && !shopifyDetails.storeName) ||
                    (getSteps()[activeStep] === 'Address' && (!address.street || !address.city || !address.state || !address.zipCode))
                  }
                >
                  {activeStep === getSteps().length - 1 ? 'Finish' : 'Next'}
                </Button>
              </>
            )}
          </Box>
          {activeStep === 0 && (
            <Typography
              variant="body2"
              sx={{
                mt: 3,
                color: theme.palette.primary.main,
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                },
                textAlign: 'center'
              }}
              onClick={handleSkipOnboarding}
            >
              Already added company details? Skip onboarding and go to dashboard
            </Typography>
          )}
        </Box>
      </Box>
    </AuroraBackground>
  );
};

export default SplashScreen;
