import { Grid, Typography, Chip, Link, TextField } from '@mui/material';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { formatCentsToDollarsIntl, convertDollarsToCents } from '@oneAppCore/utils/currency';
import dayjs from 'dayjs';
import { dateFormat } from '@oneAppCore/constants/dates';
import { ACTIVE, DRAFT, INACTIVE, PENDING, REJECTED } from '@oneAppCore/constants/listings';
import { orderProfitColors } from '@src/utils/orders';
import type { Column } from '@src/Components/common/containers/SearchGrid/types';
import type { ColumnType } from '@src/Components/common/containers/SearchView/types';
import TooltipTable from '@src/Components/common/TooltipTable';
import {
    AMAZON,
    AMAZON_ASIN_LINK,
    WALMART,
    WALMART_PRODUCT_LINK,
    EBAY,
    EBAY_PRODUCT_LINK,
    SHOPIFY_PRODUCT_LINK,
    SHOPIFY,
    channelIds,
} from '@oneAppCore/constants/channels';
import { useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-premium';


const placeholderImageUrl = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;

const calculateTotalCost = (supplier) => {
    const { cost = 0, shippingCost = 0, estimatedShipping = 0 } = supplier;
    const shipping = estimatedShipping > 0 ? estimatedShipping : (shippingCost > 0 ? shippingCost : 0);
    return cost + shipping;
};

export const columns = (): Column[] => [
    {
        title: 'Image',
        keyName: 'thumbnail',
        sortBy: false,
        align: 'center',
        width: 80,
        customComponent: (row) => {
            let imageUrl = placeholderImageUrl;
            if (row?.productImages?.length > 0) {
                imageUrl = row.productImages[0];
            }
            if (row?.listingImages?.length > 0 && imageUrl === placeholderImageUrl) {
                const index = row.listingImages.findIndex((image) => image !== placeholderImageUrl);
                if (index > -1) {
                    imageUrl = row.listingImages[index];
                }
            }
            return (
                <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img
                        src={imageUrl}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = placeholderImageUrl;
                        }}
                        alt=""
                        width={50}
                        height={50}
                        style={{ display: 'block' }}
                    />
                </div>
            );
        },
    },
    {
        title: 'Listing',
        keyName: 'listingSku',
        sortBy: true,
        align: 'left',
        width: 300,
        customComponent: (row) => (
            <Grid container direction="column" style={{ padding: '8px 0' }}>
                <Grid item>
                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                        {row.name}
                        {row.variation ? ` - (${row.variation})` : ''}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        <strong>SKU:</strong> {row.listingSku}
                    </Typography>
                </Grid>
            </Grid>
        ),
    },
    {
        title: 'Created At',
        keyName: 'createdAt',
        sortBy: true,
        align: 'left',
        width: 110,
        customComponent: (row) => (
            <Typography variant="body2">
                {dayjs(row.createdAt).format(dateFormat)}
            </Typography>
        ),
    },
    {
        title: 'Channel',
        keyName: 'channelName',
        sortBy: true,
        align: 'center',
        width: 60,
        customComponent: (row) => {
            let href = '';
            if (row?.channelProductId) {
                if (row?.channelId === channelIds[AMAZON]) {
                    href = AMAZON_ASIN_LINK(row?.channelProductId);
                } else if (row?.channelId === channelIds[WALMART]) {
                    href = WALMART_PRODUCT_LINK(row?.channelProductId);
                } else if (row?.channelId === channelIds[EBAY]) {
                    href = EBAY_PRODUCT_LINK(row?.channelProductId);
                } else if (row?.channelId === channelIds[SHOPIFY]) {
                    href = SHOPIFY_PRODUCT_LINK(row?.storeChannel?.metaData?.domain, row?.channelProductId);
                }
            }
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: href ? 'pointer' : 'default',
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                        padding: 0,
                        margin: 0
                    }}
                    onClick={() => href && window.open(href, '_blank')}
                >
                    <img
                        width={35}
                        height={35}
                        style={{
                            borderRadius: 10,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        src={
                            row.icon != null
                                ? row.icon
                                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
                        }
                    />
                </div>
            );
        }
    },
    {
        title: 'Store',
        keyName: 'storeName',
        sortBy: true,
        align: 'center',
        width: 85,
        customComponent: (row) => (
            <Typography variant="body2">
                {row.storeName}
            </Typography>
        ),
    },
    {
        title: 'MSRP',
        keyName: 'msrp',
        sortBy: true,
        align: 'right',
        width: 85,
        customComponent: (row) => (
            row.msrp && row.msrp > 0 ? (
                <Typography variant="body2">
                    {formatCentsToDollarsIntl(row.msrp)}
                </Typography>
            ) : null
        ),
    },
    {
        title: 'MAP',
        keyName: 'map',
        sortBy: true,
        align: 'right',
        width: 85,
        customComponent: (row) => (
            row.map && row.map > 0 ? (
                <Typography variant="body2">
                    {formatCentsToDollarsIntl(row.map)}
                </Typography>
            ) : null
        ),
    },
    {
        title: 'Price',
        keyName: 'price',
        sortBy: true,
        align: 'right',
        width: 85,
        editable: true,
        type: 'number',
        customEdit: function (params: GridRenderCellParams) {
            const { row, id, field } = params;
            const apiRef = useGridApiContext();

            const handleChange = (event) => {
                const value = convertDollarsToCents(event.currentTarget.value);
                apiRef.current.setEditCellValue({ id, field, value });
            };
            return <>
                <Grid xs={12}>
                    <TextField
                        variant='standard'
                        defaultValue={formatCentsToDollarsIntl(row.price)}
                        type='string'
                        onChange={handleChange}
                    />
                </Grid>
            </>;
        },
        customComponent: (row) => (
            <Typography variant="body2">
                {formatCentsToDollarsIntl(row.price)}
            </Typography>
        ),
    },
    {
        title: 'Min. Price',
        keyName: 'minPrice',
        sortBy: true,
        align: 'right',
        width: 85,
        editable: true,
        type: 'number',
        customEdit: function (params: GridRenderCellParams) {
            const { row, id, field } = params;
            const apiRef = useGridApiContext();

            const handleChange = (event) => {
                const value = convertDollarsToCents(event.currentTarget.value);
                apiRef.current.setEditCellValue({ id, field, value });
            };
            return <>
                <Grid xs={12}>
                    <TextField
                        variant='standard'
                        defaultValue={formatCentsToDollarsIntl(row.minPrice)}
                        type='string'
                        onChange={handleChange}
                    />
                </Grid>
            </>;
        },
        customComponent: (row) => (
            <Typography variant="body2">
                {formatCentsToDollarsIntl(row.minPrice)}
            </Typography>
        ),
    },
    {
        title: 'Max. Price',
        keyName: 'maxPrice',
        sortBy: true,
        align: 'right',
        width: 85,
        editable: true,
        type: 'number',
        customEdit: function (params: GridRenderCellParams) {
            const { row, id, field } = params;
            const apiRef = useGridApiContext();

            const handleChange = (event) => {
                const value = convertDollarsToCents(event.currentTarget.value);
                apiRef.current.setEditCellValue({ id, field, value });
            };
            return <>
                <Grid xs={12}>
                    <TextField
                        variant='standard'
                        defaultValue={formatCentsToDollarsIntl(row.maxPrice)}
                        type='string'
                        onChange={handleChange}
                    />
                </Grid>
            </>;
        },
        customComponent: (row) => (
            <Typography variant="body2">
                {formatCentsToDollarsIntl(row.maxPrice)}
            </Typography>
        ),
    },
    {
        title: 'Cost',
        keyName: 'minCost',
        sortBy: true,
        align: 'right',
        width: 85,
        customComponent: (row) => {
            const tooltipColumns: Column[] = [
                {
                    title: 'Supplier',
                    type: 'string' as ColumnType,
                    keyName: 'supplier',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                },
                {
                    title: 'Cost',
                    type: 'money' as ColumnType,
                    keyName: 'cost',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                },
                {
                    title: 'Act. Ship',
                    type: 'money' as ColumnType,
                    keyName: 'shippingCost',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                },
                {
                    title: 'Est. Ship',
                    type: 'money' as ColumnType,
                    keyName: 'estimatedShipping',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                },
            ];

            const tooltipRows = row.supplierArray?.map(({ metaData, name, cost, shippingCost, estimatedShipping }) => ({
                supplier: metaData?.shortName || name.match(/\b(\w)/g)?.join('')?.toUpperCase(),
                cost,
                shippingCost,
                estimatedShipping,
            })) || [];
            const lowestTotalCost = row.supplierArray?.length > 0
                ? Math.min(...row.supplierArray.map(calculateTotalCost))
                : 0;
            return (
                <TooltipTable
                    columns={tooltipColumns}
                    rows={tooltipRows}
                >
                    <Typography variant="body2">
                        {formatCentsToDollarsIntl(lowestTotalCost)}
                    </Typography>
                </TooltipTable>
            );
        },
    },
    {
        title: 'Profit',
        keyName: 'profitPercentage',
        sortBy: true,
        align: 'right',
        width: 85,
        customComponent: (row) => {
            const { channelId, supplierArray, price } = row;
            const lowestTotalCost = supplierArray?.length > 0
                ? Math.min(...supplierArray.map(calculateTotalCost))
                : 0;
            const estimatedFee = Math.round(price * .12);
            const profit = price - lowestTotalCost - estimatedFee;
            const profitPercentage = lowestTotalCost > 0
                ? Math.round((profit / lowestTotalCost) * 100)
                : 0;

            const backgroundColor = orderProfitColors(profitPercentage);

            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '4px' }}>
                    <Typography variant="body2">
                        {formatCentsToDollarsIntl(profit)}
                    </Typography>
                    <Chip
                        size="small"
                        style={{
                            backgroundColor,
                            color: 'white',
                            fontSize: '0.75rem',
                            height: '20px'
                        }}
                        label={`${profitPercentage}%`}
                    />
                </div>
            );
        },
    },
    {
        title: 'Sales Count',
        keyName: 'salesCount',
        sortBy: true,
        align: 'center',
        width: 85,
        customComponent: (row) => (
            <Typography variant="body2">
                {row.salesCount}
            </Typography>
        ),
    },
    {
        title: 'Inventory',
        keyName: 'quantity',
        sortBy: true,
        align: 'center',
        width: 85,
        customComponent: (row) => {
            const tooltipColumns = [
                {
                    title: 'Supplier',
                    type: 'string' as ColumnType,
                    keyName: 'supplier',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                },
                {
                    title: 'Qty',
                    type: 'number' as ColumnType,
                    keyName: 'quantity',
                    customTitle: (selectedFilterValues: any) => <Typography>{selectedFilterValues}</Typography>
                }
            ];

            const tooltipRows = row.supplierArray?.map(({ metaData, name, quantity }) => ({
                supplier: metaData?.shortName || name.match(/\b(\w)/g)?.join('')?.toUpperCase(),
                quantity
            })) || [];

            return (
                <TooltipTable
                    columns={tooltipColumns}
                    rows={tooltipRows}
                >
                    <Typography variant="body2">
                        {row.quantity}
                    </Typography>
                </TooltipTable>
            );
        },
    },
    {
        title: 'Created By',
        keyName: 'createdByName',
        sortBy: true,
        align: 'left',
        width: 120,
        customComponent: (row) => (
            <Typography variant="body2">
                {row.createdByName}
            </Typography>
        ),
    },
    {
        title: 'Status',
        keyName: 'status',
        sortBy: true,
        align: 'center',
        width: 85,
        customComponent: (row) => {
            const getStatusColor = (status) => {
                switch (status) {
                    case ACTIVE:
                        return 'success';
                    case INACTIVE:
                        return 'error';
                    case DRAFT:
                        return 'default';
                    case PENDING:
                        return 'warning';
                    case REJECTED:
                        return 'error';
                    default:
                        return 'default';
                }
            };
            return (
                <Chip
                    label={row.status}
                    size="small"
                    color={getStatusColor(row.status)}
                />
            );
        },
    },
];
