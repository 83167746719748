import dayjs from 'dayjs';

import useMeStores from '@src/hooks/swr/useMeStores';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import useUsers from '@src/hooks/swr/useUsers';
import { statusList } from '@oneAppCore/constants/orders';
import { Grid } from '@mui/material';
import SearchView from '@src/Components/common/containers/SearchView';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getColor } from '@src/utils/colors';
import { useState } from 'react';

import { columns } from './constants';
import MiniReports from './MiniReports';
import OrderViewDrawer from '../OrderDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  quantity: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 20,
    width: 20,
    backgroundColor: '#ffffff',
  },
  title: {
    textOverflow: 'ellipsis',
    display: 'block',
    maxWidth: '300px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    lineHeight: 1,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  orderNumber: {
    padding: 10,
    borderRadius: 10,
    width: 100,
    height: 10,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  bold: {
    fontWeight: 'bold',
    lineHeight: 0.5,
  },
  red: {
    color: getColor('dark_red', 'dark'),
    borderColor: getColor('dark_red', 'dark'),
    backgroundColor: getColor('light_red', 'light'),
    borderWidth: '1.5px',
  },
  black: {
    color: getColor('black', 'main'),
    borderColor: getColor('black', 'main'),
  },
}));

function OrdersSearch() {
  const classes = useStyles();
  const { data: stores = [] } = useMeStores();
  const { data: suppliers = [] } = useSuppliers();
  const { data: users = [] } = useUsers();

  // Add pagination state
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Configure SWR for better caching
  const swrConfig = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 300000, // 5 minutes
    dedupingInterval: 60000, // 1 minute
  };

  const sortedUsers = users.length
    ? users?.sort((a, b) => {
      const nameA = a.firstName.toUpperCase();
      const nameB = b.firstName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    : [];
  const userOptions = sortedUsers?.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  const storeOptions = stores.map((store) => ({
    label: store.name,
    value: store.id,
  }));

  const supplierOptions = suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }));

  const statusOptions = statusList.map((status) => ({
    label: status,
    value: status,
  }));

  const dateRangeOptions = [
    {
      label: 'Today',
      value: [
        dayjs().format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: 'Yesterday',
      value: [
        dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '7 Days',
      value: [
        dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '14 Days',
      value: [
        dayjs().subtract(14, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '30 Days',
      value: [
        dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '60 Days',
      value: [
        dayjs().subtract(60, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      label: '90 Days',
      value: [
        dayjs().subtract(90, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
    {
      label: '120 Days',
      value: [
        dayjs().subtract(120, 'days').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
      ],
    },
  ];

  const defaultStartDate = dayjs().startOf('day').subtract(30, 'days').format('YYYY-MM-DD');
  const defaultEndDate = dayjs().add(1, 'days').format('YYYY-MM-DD');
  const defaultDateFilter = `${defaultStartDate}--${defaultEndDate}`;

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="api/v1/orders/search"
        ViewDrawerComponent={OrderViewDrawer}
        constantFilters={{
          miniReport: 'show',
          purchasedAt: defaultDateFilter,
        }}
        filters={[
          {
            type: 'date range select',
            name: 'Purchased At',
            keyName: 'purchasedAt',
            properties: dateRangeOptions.map(({ label, value }) => ({
              label,
              value: value.filter((a) => a).join('--'),
            })),
          },
          {
            type: 'string',
            name: 'Order Number',
            keyName: 'orderNumber',
          },
          {
            type: 'selector',
            name: 'Status',
            keyName: 'status',
            options: { hideDefaultNoneOption: true },
            properties: statusOptions,
          },
          {
            type: 'selector',
            name: 'Store',
            keyName: 'storeId',
            properties: storeOptions,
          },
          {
            type: 'string',
            name: 'Shipping Name',
            keyName: 'shippingName',
          },
          {
            type: 'equality',
            name: 'Profit',
            keyName: 'profit',
          },
          {
            type: 'selector',
            name: 'Supplier',
            keyName: 'supplierId',
            properties: supplierOptions,
          },
          {
            type: 'string',
            name: 'Supplier Order #',
            keyName: 'supplierOrderNumber',
          },
          {
            type: 'string',
            name: 'Supplier Tracking #',
            keyName: 'supplierTrackingNumber',
          },
          {
            type: 'selector',
            name: 'Placed By',
            keyName: 'placedBy',
            properties: userOptions,
          },
        ]}
        swrOptions={swrConfig}
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;
          if (bottom && hasMore) {
            setPage(p => p + 1);
          }
        }}
        columns={columns(suppliers, classes)}
        MiniReportComponent={MiniReports}
        showChannelOptions
      />
    </Grid>
  );
}

export default OrdersSearch;
