// import React from "react";
// import { Box, Typography, Button, Grid } from "@mui/material";
// import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
// import dayjs from 'dayjs';
// import { dateFormat } from '@oneAppCore/constants/dates';
// import CompanyApi from '@oneAppCore/services/apis/Companies';
// import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
// import TransactionDialog from './TransactionDialog';

// interface CompanyTransactionsProps {
//     companyId: string;
// }

// interface ComponentTransaction {
//     id: string;
//     transactionDate: string;
//     paymentType: string;
//     amount: number;
//     paymentMethod: {
//         type: 'credit-card' | 'bank-transfer';
//         details?: string;
//     };
//     status: 'completed' | 'pending' | 'failed';
// }

// interface ApiTransaction {
//     id: number;
//     date: string;
//     description: string;
//     amount: number;
//     paymentMethod: string;
//     status: string;
// }

// const columns: GridColDef<ComponentTransaction>[] = [
//     {
//         field: 'transactionDate',
//         headerName: 'Date',
//         flex: 1,
//         valueFormatter: (params: { value: string }) => {
//             return dayjs(params.value).format(dateFormat);
//         }
//     },
//     {
//         field: 'paymentType',
//         headerName: 'Description',
//         flex: 2
//     },
//     {
//         field: 'amount',
//         headerName: 'Amount',
//         flex: 1,
//         type: 'number',
//         align: 'left',
//         headerAlign: 'left',
//         renderCell: (params) => {
//             const amount = params.value;
//             const formatted = new Intl.NumberFormat('en-US', {
//                 style: 'currency',
//                 currency: 'USD'
//             }).format(amount);
//             return (
//                 <div style={{ color: amount < 0 ? '#d32f2f' : '#2e7d32' }}>
//                     {formatted}
//                 </div>
//             );
//         }
//     },
//     {
//         field: 'paymentMethod',
//         headerName: 'Payment Method',
//         flex: 1.5,
//         renderCell: (params) => {
//             const method = params.row.paymentMethod;
//             let displayText = method.type === 'credit-card' ? 'Credit Card' : 'Bank Transfer';
//             if (method.details) {
//                 displayText += ` (${method.details})`;
//             }
//             return displayText;
//         }
//     },
//     {
//         field: 'status',
//         headerName: 'Status',
//         flex: 1,
//         renderCell: (params) => {
//             const status = params.value as string;
//             const statusColors = {
//                 completed: '#e6f4ea',
//                 pending: '#fff8e1',
//                 failed: '#fce8e8'
//             };
//             const colors = {
//                 completed: 'darkgreen',
//                 pending: '#eed202',
//                 failed: '#d32f2f'
//             }
            
//             return (
//                 <Box sx={{
//                     backgroundColor: statusColors[status.toLowerCase()],
//                     color: colors[status.toLowerCase()],
//                     borderRadius: '16px',
//                     padding: '4px 12px',
//                     fontSize: '0.875rem',
//                     fontWeight: 500,
//                     minWidth: '90px',
//                     textAlign: 'center',
//                     maxHeight: '30px',
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center'
//                 }}>
//                     {status}
//                 </Box>
//             );
//         }
//     }
// ];

// const CompanyTransactions: React.FC<CompanyTransactionsProps> = ({ companyId }) => {
//     const [transactions, setTransactions] = React.useState<ComponentTransaction[]>([]);
//     const [loading, setLoading] = React.useState(true);
//     const [error, setError] = React.useState<string | null>(null);
//     const [transactionDialogOpen, setTransactionDialogOpen] = React.useState(false);

//     const fetchTransactions = async () => {
//         try {
//             setLoading(true);
//             const response = await CompanyApi.getTransactions(parseInt(companyId, 10));
            
//             // Transform API response to match component's transaction type
//             const transformedTransactions: ComponentTransaction[] = (response as ApiTransaction[])?.map(apiTx => ({
//                 id: apiTx.id.toString(),
//                 transactionDate: apiTx.date,
//                 paymentType: apiTx.description,
//                 amount: apiTx.amount / 100, // Convert from cents to dollars
//                 paymentMethod: {
//                     type: apiTx.paymentMethod,
//                 },
//                 status: apiTx.status
//             }));

//             setTransactions(transformedTransactions);
//             setError(null);
//         } catch (err) {
//             console.error('Error fetching transactions:', err);
//             setError('Failed to load transactions');
//         } finally {
//             setLoading(false);
//         }
//     };

//     React.useEffect(() => {
//         fetchTransactions();
//     }, [companyId]);

//     return (
//         <>
//             <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
//                 <Grid item xs>
//                     <Typography variant="h5">Transaction History</Typography>
//                 </Grid>
//                 <Grid item>
//                     <Button
//                         variant="contained"
//                         color="primary"
//                         startIcon={<AddTwoToneIcon />}
//                         onClick={() => setTransactionDialogOpen(true)}
//                     >
//                         New Transaction
//                     </Button>
//                 </Grid>
//             </Grid>

//             {error && (
//                 <Typography color="error" sx={{ mb: 2 }}>
//                     {error}
//                 </Typography>
//             )}

//             <Box sx={{ height: 400, width: '100%' }}>
//                 <DataGridPremium
//                     rows={transactions}
//                     columns={columns}
//                     // disableSelectionOnClick
//                     pagination
//                     loading={loading}
//                     autoHeight
//                 />
//             </Box>

//             <TransactionDialog
//                 open={transactionDialogOpen}
//                 onClose={() => setTransactionDialogOpen(false)}
//                 companyId={companyId}
//                 onTransactionCreated={fetchTransactions}
//             />
//         </>
//     );
// };

// CompanyTransactions.displayName = 'CompanyTransactions';

// export default CompanyTransactions;

import React from "react";
import { Box, Typography } from "@mui/material";
import ClientBillingAPI from "@oneAppCore/services/apis/ClientBilling/ClientBillingAPI";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import dayjs from 'dayjs';
import { dateFormat } from '@oneAppCore/constants/dates';

interface Transaction {
    id: string;
    date: string;
    description: string;
    amount: number;
    type: 'deposit' | 'withdrawal';
    paymentMethod: {
        type: 'credit-card' | 'bank-transfer';
        details?: string;
    };
    status: 'completed' | 'pending' | 'failed';
}

interface ComponentTransaction {
    id: string;
    transactionDate: string;
    paymentType: string;
    amount: number;
    type: 'deposit' | 'withdrawal';
    paymentMethod: {
        type: 'credit-card' | 'bank-transfer';
        details?: string;
    };
    status: 'completed' | 'pending' | 'failed';
}

interface CompanyTransactionsProps {
    companyId: string;
}

const columns: GridColDef<ComponentTransaction>[] = [
    {
        field: 'transactionDate',
        headerName: 'Date',
        flex: 1,
        valueFormatter: (params: { value: string }) => {
            return dayjs(params.value).format(dateFormat);
        }
    },
    {
        field: 'paymentType',
        headerName: 'Description',
        flex: 2
    },
    {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => {
            const amount = params.value;
            const formatted = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            }).format(amount);
            return (
                <div style={{ color: amount < 0 ? '#d32f2f' : '#2e7d32' }}>
                    {formatted}
                </div>
            );
        }
    },
    {
        field: 'paymentMethod',
        headerName: 'Payment Method',
        flex: 1.5,
        renderCell: (params) => {
            const method = params.row.paymentMethod;
            let displayText = method.type === 'credit-card' ? 'Credit Card' : 'Bank Transfer';
            if (method.details) {
                displayText += ` (${method.details})`;
            }
            return displayText;
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
            const status = params.value as string;
            const statusColors = {
                completed: '#e6f4ea',
                pending: '#fff8e1',
                failed: '#fce8e8'
            };
            const colors = {
                completed: 'darkgreen',
                pending: '#eed202',
                failed: '#d32f2f'
            }
            
            return (
                <Box sx={{
                    backgroundColor: statusColors[status.toLowerCase()],
                    color: colors[status.toLowerCase()],
                    borderRadius: '16px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    minWidth: '90px',
                    textAlign: 'center',
                    maxHeight: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {status}
                </Box>
            );
        }
    }
];

const Transactions: React.FC<CompanyTransactionsProps> = ({companyId}) => {
    const [transactions, setTransactions] = React.useState<ComponentTransaction[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const api = new ClientBillingAPI();
                console.log('Fetching transactions...');
                const response = await api.getRows();
                console.log(response, 'Response');
                
                if (!response.transactions || !Array.isArray(response.transactions)) {
                    throw new Error('Invalid response format from API');
                }

                // Transform API response to match component's transaction type
                const transformedTransactions: ComponentTransaction[] = response.transactions.map(apiTx => {
                    const amount = Number(apiTx.amount);
                    const isDeposit = apiTx.paymentType === 'DEPOSIT';
                    
                    return {
                        id: apiTx.id.toString(),
                        transactionDate: apiTx.transactionDate ? new Date(apiTx.transactionDate).toISOString() : new Date().toISOString(),
                        paymentType: isDeposit ? 'Deposit' : apiTx.paymentType,
                        amount: isDeposit ? amount : -amount,
                        type: isDeposit ? 'deposit' : 'withdrawal',
                        paymentMethod: {
                            type: apiTx.paymentMethod.toLowerCase().includes('credit') ? 'credit-card' : 'bank-transfer',
                            details: apiTx.paymentMethod.toLowerCase().includes('credit') && apiTx.paymentDetails ? 
                                `****${apiTx.paymentDetails}` : undefined
                        },
                        status: 'completed'
                    };
                });
                setTransactions(transformedTransactions);
                setError(null);
            } catch (err) {
                setError('Failed to load transactions');
                console.error('Error fetching transactions:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <Typography variant="h6" sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                Transaction History
            </Typography>
            {error ? (
                <Box sx={{ p: 2, color: 'error.main' }}>
                    {error}
                </Box>
            ) : (
                <DataGridPremium
                    rows={transactions}
                    columns={columns}
                    loading={loading}
                    disableRowSelectionOnClick
                    autoPageSize
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-cell': {
                            borderBottom: 1,
                            borderColor: 'divider',
                            py: 1.5,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'action.hover',
                            borderBottom: 2,
                            borderColor: 'divider'
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: 2,
                            borderColor: 'divider'
                        },
                        '& .MuiChip-root': {
                            height: '24px',
                            fontSize: '0.75rem'
                        }
                    }}
                />
            )}
        </Box>
    );
};

Transactions.displayName = 'Transactions';

export default Transactions;