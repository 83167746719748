import BaseApi from '../BaseApi';

import {
  storeListingUpdateBody,
  StoreListingPayload,
  StoreListingChangePayload,
} from './types';

class StoreListingApi extends BaseApi {
  constructor() {
    super();
  }

  createListing(payload) {
    return this.postBase<{ status: string }>(`/api/v1/store-listings`, payload);
  }

  getById(id: number) {
    return this.getBase<any>(`api/v1/store-listings/search/${id}`);
  }

  /* getAllListings = async (params?: any) => {
    return this.getBase<any>(`api/v1/store-listings/search`, params);
  }; */

  getRepriceListings = async (params?: any) => {
    return this.getBase<any>(`api/v1/store-listings/reprice`, params);
  };

  getListingChanges = async (params?: any) => {
    return this.getBase<any>(`api/v1/store-listings/changes`, params);
  };
  getListingCostHistory = async (params?: any) => {
    return this.getBase<any>(`api/v1/store-listings/cost-history`, params);
  };

  createSLChange = async (payload: Partial<StoreListingChangePayload>) => {
    return this.postBase<StoreListingChangePayload>(
      'api/v1/store-listings/changes',
      payload,
    );
  };

  updateSLChange = async (id, payload: Partial<StoreListingChangePayload>) => {
    return this.putBase<StoreListingChangePayload>(
      `api/v1/store-listings/changes/${id}`,
      payload,
    );
  };
  updateSLChangeBulk = async (payload: Partial<StoreListingChangePayload & { changeIds: number[] }>) => {
    return this.postBase<any>(
      `api/v1/store-listings/changes/bulk`,
      payload,
    );
  };
  put(id: number, payload: Partial<storeListingUpdateBody>) {
    return this.putBase<StoreListingPayload>(
      `/api/v1/store-listings/${id}`,
      payload,
    );
  };
  refreshChannelListings = async () => {
    return this.postBase<any>(
      `api/v1/store-listings/push`
    );
  };
  async bulkChangesCreate(data: { changes: Array<{ storeListingId: number, type: string, newValues: any }> }) {
    return this.postBase('/api/v1/store-listings/create-bulk-changes', data);
  }
}

export default StoreListingApi;
