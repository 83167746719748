import React from 'react';
import { SplashScreen } from './SplashScreen';
import useMe from '@src/hooks/swr/useMe';
import { useSplashScreen } from '@src/context/SplashScreenContext';
import { useLocation } from 'react-router-dom';

export const SplashScreenWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: user } = useMe();
  const { showSplashScreen, onboardingComplete } = useSplashScreen();
  const location = useLocation();
  const isRegistering = location.pathname === '/register';

  // Show splash screen in these cases:
  // 1. When the user has just registered (showSplashScreen is true) AND they are logged in
  // 2. When a logged-in user hasn't completed onboarding
  const hasSeenForm = sessionStorage.getItem('hasSeenForm') === 'true';
  
  // We only want to show the splash screen if:
  // 1. We have a logged-in user
  // 2. Either:
  //    a. They just registered (showSplashScreen is true)
  //    b. They haven't completed onboarding yet
  if (user && ((showSplashScreen && !hasSeenForm) || !onboardingComplete)) {
    sessionStorage.setItem('hasSeenForm', 'true');
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default SplashScreenWrapper;
