import { useState } from "react";
import { useSnackbar } from 'notistack';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    InputAdornment
} from "@mui/material";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe as useStripeJs } from '@stripe/react-stripe-js';
import ClientBillingAPI from "@oneAppCore/services/apis/ClientBilling";
import type { TransactionPayload } from "@oneAppCore/services/apis/ClientBilling/types";
import useStripeConfig from "@src/hooks/swr/useStripe";

interface Props {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    amount?: string;
    setAmount?: (amount: string) => void;
}

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#aab7c4'
            },
            padding: '10px 12px',
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    },
    hidePostalCode: false
};

const CheckoutForm = ({ amount, onClose, onSuccess, setAmount }) => {
    const stripe = useStripeJs();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const [processing, setProcessing] = useState(false);

    const handleClose = () => {
        setAmount('');  // Reset the amount
        onClose();  // Call the original onClose handler
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setProcessing(true);
    
        if (!stripe || !elements) {
            return;
        }
    
        try {
            // Create payment intent
            const paymentIntentResponse = await ClientBillingAPI.createPaymentIntent({
                amount: parseFloat(amount)
            });
    
            const { clientSecret } = paymentIntentResponse;
    
            const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: window.location.origin,
                    payment_method_data: {
                        billing_details: {
                            address: {
                                country: 'US'  // Default to US, or you can make this configurable
                            }
                        }
                    }
                },
                redirect: 'if_required'
            });
    
            if (stripeError) {
                enqueueSnackbar(stripeError.message, { 
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' }
                });
                return;
            }
    
            if (paymentIntent?.status === 'succeeded') {
                const transactionPayload: TransactionPayload = {
                    amount: parseFloat(amount),
                    paymentType: 'DEPOSIT',
                    paymentMethod: 'CREDIT_CARD',
                    transactionDate: new Date(),
                };
    
                await ClientBillingAPI.post(transactionPayload);
    
                enqueueSnackbar('Payment successful!', { 
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' }
                });
                
                if (onSuccess) {
                    onSuccess();
                }
                onClose();
            }
        } catch (error) {
            enqueueSnackbar('An error occurred while processing your payment.', { 
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            });
            console.error('Payment error:', error);
        }
        setProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
                <PaymentElement 
                    options={{
                        layout: {
                            type: 'tabs',
                            defaultCollapsed: false,
                        },
                        fields: {
                            billingDetails: {
                                address: {
                                    country: 'never'
                                }
                            }
                        },
                        wallets: {
                            googlePay: 'auto',
                            applePay: 'auto',
                        }
                    }}
                />
            </Box>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!stripe || processing}
                >
                    {processing ? 'Processing...' : `Pay $${amount}`}
                </Button>
            </DialogActions>
        </form>
    );
};

function AddFunds({open, onClose, onSuccess}: Props) {
    const { data: stripeConfig, error } = useStripeConfig();
    const [amount, setAmount] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');

    // Reset states when dialog is closed
    const handleClose = () => {
        setAmount('');
        setClientSecret('');
        onClose();
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setAmount(value);
        }
    };



    const handleProceed = async () => {
        if (!amount || parseFloat(amount) <= 0) {
            return;
        }

        try {
            const response = await ClientBillingAPI.createPaymentIntent({
                amount: parseFloat(amount)
            });
            setClientSecret(response.clientSecret);
        } catch (error) {
            console.error('Error creating payment intent:', error);
        }
    };

    const stripePromise = stripeConfig?.publishableKey ? loadStripe(stripeConfig.publishableKey) : null;

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
            <DialogTitle>Add Funds to Your Account</DialogTitle>
            <DialogContent>
                {error ? (
                    <Typography color="error">Failed to load Stripe configuration</Typography>
                ) : !stripeConfig ? (
                    <Typography>Loading...</Typography>
                ) : !clientSecret ? (
                    <Box sx={{mt: 2}}>
                        <TextField
                            fullWidth
                            label="Amount"
                            type="text"
                            value={amount}
                            onChange={handleAmountChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            sx={{mb: 2}}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleProceed}
                            disabled={!amount || parseFloat(amount) <= 0}
                        >
                            Proceed to Payment
                        </Button>
                    </Box>
                ) : stripePromise ? (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm
                            amount={amount}
                            onClose={handleClose}
                            onSuccess={onSuccess}
                            setAmount={setAmount}
                        />
                    </Elements>
                ) : null}
            </DialogContent>
        </Dialog>
    );
}

export default AddFunds;