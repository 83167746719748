import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import {
  Grid,
  Typography,
  Button,
  Switch,
  Tooltip,
  IconButton,
} from '@mui/material';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

import dayjs from 'dayjs';

import type { Column } from '@src/Components/common/containers/SearchView/types';
import { INVENTORY, SUP_COUNT, SUP_IR_STOCK, SUP_OOS, SUP_STOCK } from '@oneAppCore/constants/analytics';
import { ANALYTIC_LST, ANALYTIC_SUP_OOS, ANALYTIC_SUP_TOT } from '@oneAppCore/constants/listings';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const columns: Column[] = [
  {
    title: 'Name',
    keyName: 'name',
    sortBy: true,
  },
  // {
  //   title: 'Internal Supplier',
  //   keyName: 'internalSupplier',
  //   sortBy: true,
  // },
  {
    title: 'Supplier Type',
    keyName: 'internalSupplier',
    sortBy: false,
    customComponent: function InternalSupplier(row) {
      const inOrEx = row.internalSupplier ? 'Warehouse' : 'Dropship';
      const appOrEx = row.isThirdParty ? '3rd Party' : 'In House';
      return (
        <>
          {/* <Switch
          checked={row.internalSupplier}
          inputProps={{ 'aria-label': 'controlled' }}
          /> 
        */}
          <p>{inOrEx}</p>
          {row.internalSupplier === true ? <p>{`${appOrEx} Stock`}</p> : null}
        </>
      );
    },
  },
  {
    title: 'Inventory (OOS)',
    keyName: 'inventory',
    sortBy: true,
    align: 'center',
    customComponent: function Inventory(row) {
      return (
        <Grid>
          <Typography>
            {Number(row?.[`${INVENTORY}-${SUP_COUNT}`] || 0).toLocaleString()}&nbsp;
            ({Number(row?.[`${INVENTORY}-${SUP_OOS}`] || 0).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Products (Missing)',
    keyName: 'products',
    sortBy: true,
    align: 'center',
    customComponent: function Products(row) {
      const irStock = row?.[`${INVENTORY}-${SUP_IR_STOCK}`] || 0;
      const ourStock = row?.[`${INVENTORY}-${SUP_STOCK}`] || 0;
      let misStock = irStock - ourStock;
      if (misStock < 0 || isNaN(misStock)) misStock = 0;
      return (
        <Grid>
          <Typography>
            {Number(ourStock).toLocaleString()}&nbsp;
            ({Number(misStock).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Listings (Inactive)',
    keyName: 'totalListings',
    sortBy: true,
    align: 'center',
    customComponent: function listings(row) {
      return (
        <Grid>
          <Typography>
            {Number(row?.[`${ANALYTIC_LST}-${ANALYTIC_SUP_TOT}`] || 0).toLocaleString()}&nbsp;
            ({Number(row?.[`${ANALYTIC_LST}-${ANALYTIC_SUP_OOS}`] || 0).toLocaleString()})
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Orders (Revenue)',
    keyName: 'orders',
    type: 'number',
    align: 'center',
    customComponent: function (row) {
      return (<Grid item xs={12} alignContent={'center'}>
        <Typography>
          {Number(row.orders).toLocaleString()}&nbsp;
          ({formatCentsToDollarsIntl(row.orderRev)})
        </Typography>
      </Grid>);
    },
    // sortBy: true,
  },
  {
    title: 'PO (Cost)',
    keyName: 'purchaseOrders',
    type: 'number',
    align: 'center',
    customComponent: function (row) {
      return (<Grid item xs={12} alignContent={'center'}>
        <Typography>
          {Number(row.purchaseOrders).toLocaleString()}&nbsp;
          ({formatCentsToDollarsIntl(row.poCost)})
        </Typography>
      </Grid>);
    },
    // sortBy: true,
  },
  // {
  //   title: 'Created At',
  //   keyName: 'createdAt',
  //   sortBy: true,
  //   customComponent: function CreatedAt(row) {
  //     console.log(row, 'supp row');
  //     return (
  //       <Grid container xs={12} style={{ justifyContent: 'center' }}>
  //         <Typography>
  //           {dayjs(row.createdAt)
  //             .tz(dayjs.tz.guess())
  //             .format('MM/DD/YYYY hh:mma z')}
  //         </Typography>
  //       </Grid>
  //     );
  //   },
  // },
  // {
  //   title: 'Supplier Meta Data',
  //   keyName: 'metaData',
  //   sortBy: false,
  //   customComponent: function SupplierMetaData(row) {
  //     const metaData = row.metaData ? row.metaData : '';
  //     return (
  //       <>
  //         <p>{typeof metaData/* {metadata.map(ba => ba).join(', ')} */}</p>
  //       </>
  //     );
  //   },
  // },
  {
    title: 'View',
    keyName: 'view',
    sortBy: false,
    align: 'center',
    customComponent: function ViewSupplier(row) {
      const { id } = row;
      const history = useHistory();

      const ViewSupplier = () => {
        const query = queryUtils.add('id', row.id);
        history.replace({
          search: query,
        });
      };
      return (
        <Grid key={row.id}>
          <Tooltip title={`View Supplier Details`}>
            <span>
              <IconButton onClick={() => history.push(`/suppliers/view/${id}`)}>
                <VisibilityTwoToneIcon color="primary" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      );
    },
  },
];
