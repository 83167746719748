import { useState } from 'react';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  GridSize,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ParentOrderProps } from './types';
import ParentTextFields from './ParentOrderTextFields';
import { startCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  secondColumns,
  orderItemsColumns,
  firstColumns,
  textColumns,
  buyerInfoColumns,
} from './constants';
import { primary } from '@src/Components/Theme/themes/constants';
import { convertDollarsToCents, formatCentsToDollars, formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

const useStyles = makeStyles({
  header: {
    marginBottom: 15,
  },
  card: {
    width: '100%',
    padding: 10,
    marginBottom: 30,
  },
  cardAction: {
    justifyContent: 'center',
  },
  button: {
    textTransform: 'none',
  },
  textArea: {
    marginTop: 10,
  },
  fontColor: {
    color: '#fff',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  divider: {
    background: primary.main,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    marginRight: 2,
  },
});


function ParentOrder({
  isViewMode,
  row,
  form,
  updateForm,
}: ParentOrderProps) {
  console.log('row', row);
  const [item, setItem] = useState(0);
  const classes = useStyles();
  const orderItems = row?.orderItems ? row?.orderItems : null;
  const history = useHistory();
  const renderOrderFields = (cols, width: GridSize = 12) => {
    const returnEdit = (col) => {
      const textEdit = (<TextField
        fullWidth
        variant="outlined"
        key={col.id}
        label={col.title}
        defaultValue={row[col.key]}
        onChange={(e) => {
          updateForm(col.key, e.target.value);
        }}
      />);
      const currencyEdit = (<TextField
        fullWidth
        // type='number'
        variant="outlined"
        key={col.id}
        label={col.title}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        defaultValue={formatCentsToDollars(row[col.key], { removeMoneySign: true })}
        onChange={(e) => {
          const val = convertDollarsToCents(e.target.value);
          updateForm(col.key, val);
        }}
      />);
      const dropDownEdit = (<>
        <Grid item container xs={8} sm={6}>
          <InputLabel id={`${col.key}-edit-dropdown`}>
            <Typography key={col.id}>
              <strong>{col.title}:</strong>
            </Typography>
          </InputLabel>
        </Grid>
        <Select
          labelId={`${col.key}-edit-dropdown`}
          defaultValue={row[col.key]}
        >
          {col.options?.map((opt, odx) => <MenuItem key={`${col.key}-option-${odx + 1}`} value={opt.value} >{opt.label}</MenuItem>)}
        </Select>
      </>);
      return (
        <Grid
          key={col.key}
          alignItems="center"
          justifyContent="center"
          container
          item
          xs={6}
        >
          <Grid item container xs={12} style={{ margin: 15 }}>
            {col.type === 'dropdown' ? dropDownEdit :
              col.type === 'currency' ? currencyEdit :
                textEdit
            }
          </Grid>
        </Grid>
      )
    };
    const returnView = (col) => {
      const textContents = (
        <>
          <Grid item container xs={4} sm={6}>
            <Typography>{row[col.key]}</Typography>
          </Grid>
        </>
      );
      let contents = (textContents);
      if (col.customComponent) {
        contents = (
          <>
            <col.customComponent item={row} />
          </>
        );
      }
      return (
        <Grid
          key={col.key}
          item
          container
          spacing={1}
          justifyContent="space-between"
          className={classes.textArea}
          xs={width}
        >
          <Grid
            alignItems="center"
            justifyContent="flex-start"
            container
            item
            xs={12}
          >
            <Grid item container xs={8} sm={6}>
              <Typography key={col.id}>
                <strong>{col.title}:</strong>
              </Typography>
            </Grid>
            {contents}
          </Grid>
        </Grid>
      );
    };
    return cols.map((col) => {
      return !isViewMode && col.editable ? returnEdit(col) : returnView(col);
    });
  }

  const renderItemFields = (cols, item) => {
    const returnEdit = (col) => {
      return (
        <Grid
          key={`${item.id}-${col.key}`}
          alignItems="center"
          justifyContent="center"
          container
          item
        >
          <Grid item container xs={12} style={{ margin: 15 }}>
            {col.type === 'dropdown' ? <></> :
              col.type === 'currency' ? <TextField
                fullWidth
                variant="outlined"
                key={col.id}
                label={col.title}
                defaultValue={formatCentsToDollars(item[col.key], { removeMoneySign: true })}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e) => {
                  const { id } = item;
                  console.log('onchangeitem', item);
                  const orderItems = form?.orderItems || [];
                  console.log('orderitemsonchange', form?.orderItems);
                  const foundItem = orderItems.map((itm, idx) => ({ itm, idx })).find(({ itm }) => itm.id === id);
                  console.log('foundItem', foundItem);
                  if (foundItem?.idx >= 0) {
                  } else {
                    orderItems.push({
                      id,
                      [col.key]: e.target.value,
                      supplierId: item.supplierId,
                      supplierTrackingCarrier: item.supplierTrackingCarrier
                    });
                  }
                  updateForm('orderItems', orderItems);
                }}
              /> :
                <TextField
                  fullWidth
                  variant="outlined"
                  key={col.id}
                  label={col.title}
                  defaultValue={item[col.key]}
                  onChange={(e) => {
                    const { id } = item;
                    console.log('onchangeitem', item);
                    const orderItems = form?.orderItems || [];
                    console.log('orderitemsonchange', form?.orderItems);
                    const foundItem = orderItems.map((itm, idx) => ({ itm, idx })).find(({ itm }) => itm.id === id);
                    console.log('foundItem', foundItem);
                    if (foundItem?.idx >= 0) {
                    } else {
                      orderItems.push({
                        id,
                        [col.key]: e.target.value,
                        supplierId: item.supplierId,
                        supplierTrackingCarrier: item.supplierTrackingCarrier
                      });
                    }
                    updateForm('orderItems', orderItems);
                  }}
                />
            }
          </Grid>
        </Grid>
      )
    }
    const returnView = (col) => {
      const textContents = (
        <>
          <Grid item container xs={4} sm={6}>
            <Typography>{item[col.key]}</Typography>
          </Grid>
        </>
      );
      let contents = (textContents);
      if (col.customComponent) {
        contents = (
          <>
            <col.customComponent item={item} order={row} />
          </>
        );
      }
      return (
        <Grid
          key={col.key}
          container
          spacing={1}
          justifyContent="space-between"
          className={classes.textArea}
        >
          <Grid
            alignItems="center"
            justifyContent="flex-start"
            container
            item
            xs={12}
          >
            <Grid item container xs={8} sm={6}>
              <Typography key={col.id}>
                <strong>{col.title}:</strong>
              </Typography>
            </Grid>
            {contents}
          </Grid>
        </Grid>
      );
    }
    return cols.map((col) => {
      return !isViewMode && col.editable ? returnEdit(col) : returnView(col);
    });
  }

  return (
    <>
      {orderItems === null ? (
        <>
        </>
      ) : (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          wrap="wrap"
        >
          <Card className={classes.card}>
            <CardContent style={{ padding: 30 }}>
              <Grid
                className={classes.header}
                alignItems="center"
                justifyContent="space-between"
                container
                xs={12}
              >
                <Typography variant="h5">
                  <Box fontWeight="fontWeightBold">{isViewMode ? 'Order ' : 'Edit '} {row.channelOrderId}</Box>
                </Typography>
              </Grid>
              <Grid xs={12} container>
                <Grid item container xs={6} sm={4}>
                  {renderOrderFields(firstColumns)}
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  justifyContent="flex-start"
                  style={{ marginLeft: 20 }}
                >
                  {renderOrderFields(secondColumns)}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>

              {buyerInfoColumns.map((name) => {
                return (
                  <Grid
                    key={name.key}
                    container
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Grid
                      alignItems="center"
                      justifyContent="flex-start"
                      container
                      item
                      xs={12}
                    >
                      <Grid item container xs={2}>
                        <Typography key={name.id}>
                          <strong>{name.title}</strong>
                        </Typography>
                      </Grid>
                      <Grid item container xs={4}>
                        <Typography>{row[name.key]}</Typography>
                        {/* <Typography>{orderItems[name.key]}</Typography> */}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                xs={12}
              >
                {renderOrderFields(textColumns.filter((x) => x.list === 'left'), 6)}
                {renderOrderFields(textColumns.filter((x) => x.list === 'right'), 6)}
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent style={{ padding: 30 }}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
              >
                {orderItems?.map((item) => {
                  const content = renderItemFields(orderItemsColumns, item)
                  return <Grid
                    key={item.id}
                    alignItems="center"
                    justifyContent="flex-start"
                    container
                    item
                    xs={6}
                  >
                    {content}
                  </Grid>;
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}

export default ParentOrder;
