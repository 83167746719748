import { WalkthroughStep } from './types';

export const defaultSteps: WalkthroughStep[] = [
  {
    id: 'welcome',
    title: 'Welcome to OneApp! 👋',
    description: 'Let\'s take a quick tour of your new multi-channel e-commerce management platform. Klaus will guide you through the essential features.',
    position: 'bottom',
  },
  {
    id: 'channels',
    title: 'Connect Your Stores',
    description: 'Start by connecting your e-commerce platforms. We support Shopify, Amazon, WooCommerce, eBay, and Walmart.',
    element: '#channels-section',
    position: 'right',
    action: {
      label: 'Connect Store',
    },
  },
  {
    id: 'inventory',
    title: 'Inventory Management',
    description: 'View and manage your inventory across all channels in one place. Sync stock levels automatically to prevent overselling.',
    element: '#inventory-section',
    position: 'left',
  },
  {
    id: 'orders',
    title: 'Order Management',
    description: 'Track and fulfill orders from all your sales channels in a unified dashboard.',
    element: '#orders-section',
    position: 'right',
  },
  {
    id: 'listings',
    title: 'Product Listings',
    description: 'Create and manage product listings across multiple channels effortlessly.',
    element: '#listings-section',
    position: 'left',
  },
  {
    id: 'analytics',
    title: 'Analytics & Reports',
    description: 'Get insights into your sales performance across all channels.',
    element: '#analytics-section',
    position: 'top',
  },
  {
    id: 'settings',
    title: 'Settings & Preferences',
    description: 'Configure your account settings, notification preferences, and automation rules.',
    element: '#settings-section',
    position: 'left',
  },
  {
    id: 'complete',
    title: 'You\'re All Set! 🎉',
    description: 'You\'ve completed the tour! Remember, you can always access help documentation or contact support if you need assistance.',
    position: 'bottom',
  },
];
