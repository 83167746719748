import {
  Tooltip,
  IconButton,
  Chip,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Card,
  Divider,
} from '@mui/material';
// import ProductApi from '@oneAppCore/services/apis/Products';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-premium';

import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import type {
  Column,
  ExportOption,
  ChannelOptions,
} from '@src/Components/common/containers/SearchGrid/types';
import dayjs from 'dayjs';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useHistory } from 'react-router-dom';
import colors from '@oneAppCore/constants/colors';
import ChannelCategorySelect from '@src/Components/common/Selects/ChannelCategorySelect';
import {
  BOOTSTRAP,
  GREENS,
  LIGHTBLACKS,
  PURPLES,
  THEME_PRIMARY,
} from '@oneAppCore/constants/colors';
import {
  DRAFT,
  ACTIVE,
  INACTIVE,
  PENDING,
  ERROR,
} from '@oneAppCore/constants/listings';
import TooltipTable from '@src/Components/common/TooltipTable';
import StyledTooltip from '@src/Components/common/TooltipTable/StyledTooltip';
import { pick } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  tablePaper: {
    maxHeight: '30vh',
    width: '425px',
    borderRadius: '10px',
    justifyContent: 'flex-start',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 2px ${theme.palette.black.darkest}`,
  },
  tooltip: {
    maxWidth: '430px',
    borderRadius: '10px',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    background: 'transparent',
  },
  tableCell: {
    fontWeight: 'bold',
  },
  listingColumn: {
    padding: 3,
  },
}));

export const columns = (channelCategories): Column[] => {
  const classes = useStyles();
  return [
    {
      title: 'Image',
      keyName: 'thumbnail',
      sortBy: false,
      align: 'center',
      type: 'string',
      width: 20,
      minWidth: 15,
      customComponent: function ImageRender(row) {
        const { productImages: pi, variationImages: vi } = row;
        const filteredPi = pi?.filter((i) => i) || [];
        const filteredVi = vi?.filter((i) => i) || [];
        const images = filteredPi?.length
          ? filteredPi
          : filteredVi?.length
            ? filteredVi
            : [];
        return (
          <img
            src={
              images?.[0]
                ? images?.[0]
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
            }}
            alt={`product image for ${row.name ? row.name : `product ${row.id}`
              }`}
            width={55}
            height={55}
            style={{ borderRadius: 5 }}
          />
        );
      },
    },
    {
      title: 'Product Name',
      keyName: 'name',
      sortBy: true,
      align: 'center',
      type: 'string',
      editable: true,
      width: 200,
      minWidth: 15,
      customComponent: function Name(row) {
        return (
          <Grid
            container
            style={{ whiteSpace: 'break-spaces', lineHeight: 'normal' }}
          >
            <Typography>{row.name}</Typography>
          </Grid>
        );
      },
    },
    {
      title: 'Product Score',
      keyName: 'productScore',
      sortBy: false,
      align: 'center',
      type: 'number',
      width: 30,
      minWidth: 15,
      customComponent: function ProductScore(row) {
        const {
          name,
          productImages: pi,
          variationImages: vi,
          category,
          categories,
          description,
          bulletPoints,
          keywords,
          productImageCount,
          channelCategoryId,
        } = row;
        const titleScore = name?.length * 0.25;
        const descriptionWordCount = description
          ?.split(/\s+/)
          .filter((word) => word?.length > 0)?.length;
        const descriptionScore = descriptionWordCount * 0.15;
        const bpScore = bulletPoints?.length * 2;
        const imageScore = productImageCount * 5;
        const seoScore =
          (titleScore >= 30 ? 30 : titleScore) +
          (descriptionScore >= 30 ? 30 : descriptionScore) +
          (bpScore >= 10 ? 10 : bpScore ? bpScore : 0) +
          (keywords ? 10 : 0) +
          (channelCategoryId ? 5 : 0) +
          (imageScore >= 25 ? 25 : imageScore);
        /**/
        const tooltipTitle = (
          <Card
            style={{
              padding: 20,
              width: '200px',
              border: 'grey 1px solid',
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item container xs={8}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Title:
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color:
                      titleScore <= 10
                        ? 'crimson'
                        : name?.length >= 80
                          ? 'green'
                          : 'darkorange',
                  }}
                >
                  {name?.length >= 80 ? '20/20' : `${titleScore}/20`}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item container xs={8}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Description:
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color:
                      descriptionWordCount >= 200
                        ? 'green'
                        : descriptionScore <= 15
                          ? 'crimson'
                          : 'darkorange',
                  }}
                >
                  {descriptionWordCount >= 200
                    ? '30/30'
                    : `${descriptionScore.toFixed(1)}/30`}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item xs={8} container>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Bullet Points:
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                container
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color:
                      bpScore >= 10
                        ? 'green'
                        : bpScore < 5
                          ? 'crimson'
                          : 'darkorange',
                  }}
                >
                  {bpScore >= 10 ? '10/10' : `${bpScore ? bpScore : 0}/10`}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item xs={8} container>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Keywords:
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                container
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {keywords ? (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: 'green',
                    }}
                  >
                    10/10
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: 'crimson',
                    }}
                  >
                    0/10
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item container xs={8}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Images:
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color:
                      imageScore >= 25
                        ? 'green'
                        : imageScore > 10 && imageScore < 25
                          ? 'darkorange'
                          : 'crimson',
                  }}
                >
                  {imageScore >= 25 ? '25/25' : `${imageScore}/25`}
                </span>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5, marginBottom: 5 }}>
              <Divider />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{
                padding: 5,
              }}
            >
              <Grid item container xs={8}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'grey',
                  }}
                >
                  Categories:
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {channelCategoryId ? (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: 'green',
                    }}
                  >
                    5/5
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: 'crimson',
                    }}
                  >
                    0/5
                  </span>
                )}
              </Grid>
            </Grid>
          </Card>
        );
        const tooltip = (
          <StyledTooltip
            classes={{ tooltip: classes.tooltip }}
            title={tooltipTitle}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Chip
                  style={{
                    color:
                      seoScore <= 49
                        ? 'crimson'
                        : seoScore >= 90
                          ? 'green'
                          : 'darkorange',

                    fontWeight: 'bold',
                    border: `1px solid ${seoScore <= 49
                      ? 'crimson'
                      : seoScore >= 90
                        ? 'green'
                        : 'darkorange'
                      }`,
                    backgroundColor:
                      seoScore <= 49
                        ? '#FBE9E7'
                        : seoScore >= 90
                          ? '#B9F6CA'
                          : '#f7e0cb',
                  }}
                  label={`${seoScore.toFixed(2)}`}
                />
              </Grid>
            </Grid>
          </StyledTooltip>
        );
        return <>{tooltip}</>;
      },
    },
    {
      title: 'Listings',
      keyName: 'listing',
      sortBy: true,
      align: 'center',
      type: 'string',
      width: 100,
      customComponent: function listings(row) {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.iconContainer}>
                  {row?.listingSkus &&
                    row?.listingSkus?.[0] &&
                    row?.listingSkus?.map((listing, lIndex) => {
                      return (
                        <Tooltip
                          key={lIndex}
                          classes={{ tooltip: classes.tooltip }}
                          title={
                            <TableContainer className={classes.tablePaper}>
                              <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Listing Sku</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {listing?.listingDetails &&
                                    listing?.listingDetails?.[0] &&
                                    listing?.listingDetails?.map(
                                      (channelListing, cIndex) => {
                                        const { status } = channelListing;
                                        const background =
                                          status === DRAFT
                                            ? BOOTSTRAP.v0.warning.color
                                            : status === INACTIVE
                                              ? BOOTSTRAP.v0.dark.color
                                              : status === PENDING
                                                ? BOOTSTRAP.v0.light.color
                                                : status === ACTIVE
                                                  ? BOOTSTRAP.v0.success.color
                                                  : status === ERROR
                                                    ? BOOTSTRAP.v0.danger.color
                                                    : BOOTSTRAP.v0.light.color;
                                        return (
                                          <TableRow key={cIndex}>
                                            <TableCell
                                              classes={{
                                                root: classes.tableCell,
                                              }}
                                            >
                                              {channelListing.listingSku}
                                            </TableCell>
                                            <TableCell>
                                              <p
                                                style={{
                                                  background,
                                                  fontFamily:
                                                    'Montserrat, sans-serif',
                                                  fontWeight: 400,
                                                  fontSize: '14px',
                                                  lineHeight: '18px',
                                                  padding: '1px 9px',
                                                  color: 'white',
                                                  borderRadius: '17px',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                {status}
                                              </p>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      },
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          }
                        >
                          <Grid item>
                            <Typography className={classes.listingColumn}>
                              <Avatar
                                key={lIndex}
                                alt={`icon-${lIndex}`}
                                src={listing.icon}
                              />
                            </Typography>
                          </Grid>
                        </Tooltip>
                      );
                    })}
                </div>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      title: 'Cost',
      keyName: 'cost',
      sortBy: true,
      align: 'center',
      type: 'money',
      width: 100,
      customComponent: function Cost(row) {
        const { cost = 0, estimatedShipping = 0, shippingCost = 0 } = row;
        // Use estimatedShipping if greater than 0, otherwise use shippingCost
        const shippingAmount = Number(estimatedShipping) > 0 ? Number(estimatedShipping) : Number(shippingCost);
        const totalCost = Number(cost) + shippingAmount;
        
        const tooltipRows = [
          { title: 'Supplier', value: row.supplier || 'N/A' },
          { title: 'Base Cost', value: `$${Number(cost).toFixed(2)}` },
          { title: 'Act. Ship', value: `$${Number(shippingCost).toFixed(2)}` },
          { title: 'Est. Ship', value: `$${Number(estimatedShipping).toFixed(2)}` },
          { title: 'Total Cost', value: `$${totalCost.toFixed(2)}` }
        ];

        const tooltipColumns: Column[] = [
          { title: '', keyName: 'title', width: 100 },
          { title: '', keyName: 'value', width: 80 }
        ];

        return (
          <StyledTooltip
            title={
              <TooltipTable
                columns={tooltipColumns}
                rows={tooltipRows}
              >
                <Typography variant="body2">
                  Cost Breakdown
                </Typography>
              </TooltipTable>
            }
          >
            <Typography color={shippingAmount > 0 ? 'textPrimary' : 'error'}>
              ${totalCost.toFixed(2)}
            </Typography>
          </StyledTooltip>
        );
      },
    },
    {
      title: 'Category',
      keyName: 'channelCategoryId',
      sortBy: true,
      align: 'center',
      type: 'string',
      width: 200,
      editable: true,
      customEdit: function (params: GridRenderCellParams) {
        const { id } = params;
        const apiRef = useGridApiContext();

        const handleChange = (value: number, categoryName: string) => {
          apiRef.current.setEditCellValue({ id, field: 'channelCategoryId', value });
        };
        return (
          <ChannelCategorySelect
            value={params.row.channelCategoryId as number || 9}
            onChange={handleChange}
            hasSelectLabel={false}
          />
        );

      },
      customSetter: function (value, row) {
        const val = Number(value);
        const newVal = isNaN(val) ? null : val;
        const object = { ...row };
        if (newVal) {
          object.channelCategoryId = newVal;
        }
        return object;
      },
      customComponent: function category(row) {
        let value = row.categoryName;
        if (!row.categoryName) {
          if (row.categories === '') {
            value = 'No Category';
          } else {
            value = row.categories;
          }
        }
        let selectedCategory;
        if (channelCategories?.length > 0/*  && apiRef.current.getCellValue(id, 'categoryName') === null */) {
          selectedCategory = channelCategories?.find(
            (category) => category?.id === row.channelCategoryId,
          );
        }

        return <Typography>{selectedCategory ? selectedCategory?.categoryName : value}</Typography>
      },
    },
    {
      title: 'Created By (At)',
      keyName: 'createdAt',
      sortBy: true,
      align: 'center',
      type: 'date',
      width: 220,
      customComponent: function CreatedAt(row) {
        return (
          <Grid container xs={12} style={{ justifyContent: 'center' }}>
            <Typography>{row.username} </Typography>
            <Typography>
              (
              {dayjs(row.createdAt)
                .tz(dayjs.tz.guess())
                .format('MM/DD/YYYY hh:mma z')}
              )
            </Typography>
            {/* {dayjs(row.createdAt)
            .tz(dayjs.tz.guess())
            .format('MM/DD/YYYY hh:mma z')} */}
          </Grid>
        );
      },
    },
    {
      title: 'Edit',
      keyName: 'edit',
      sortBy: false,
      align: 'center',
      type: 'string',
      width: 25,
      customComponent: function EditRender(row) {
        const history = useHistory();
        const { id } = row;
        return (
          <Grid key={row.id}>
            <Tooltip title={`Edit Product`}>
              <span>
                <IconButton
                  onClick={() => history.push(`/products/view/${id}`)}
                >
                  <EditTwoToneIcon color="primary" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];
};

export const selectTypes = [
  {
    label: 'Create Product',
    value: 'createProduct'
  },
  {
    label: 'Bulk Export',
    value: 'bulkExport'
  }
];

export const exportOptions: ExportOption[] = [
  {
    label: 'New Listings',
    value: 'newListings',
    supportedChannels: [
      {
        label: 'eBay',
        value: 'ebay'
      }
    ],
    url: `/api/v1/products/export/newListings/single`,
  },
];
